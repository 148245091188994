/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap");
body.egret-navy, .egret-navy .mat-card {
  font-family: Roboto, sans-serif !important;
  -webkit-font-smoothing: subpixel-antialiased !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  -moz-osx-font-smoothing: auto;
  box-shadow: -1px 1px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.egret-navy .top-nav button.mat-menu-item.active {
  color: white;
}

.egret-navy .mat-form-field-infix {
  padding: 0px !important;
  border: none;
  font-size: 14px;
}

.egret-navy .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 5px 0 !important;
}

.egret-navy mat-form-field .mat-form-field-underline {
  height: 1px;
  border: 1px solid #eee;
}

.egret-navy mat-list-item .mat-list-text {
  padding-left: 0 !important;
}

.egret-navy .w20 {
  width: 20px !important;
}

.egret-navy .version {
  font-family: "Roboto Mono", monospace;
  font-size: 12px;
  font-weight: 500;
  color: #999;
}

.egret-navy input::-webkit-outer-spin-button,
.egret-navy input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.egret-navy input[type=number] {
  -moz-appearance: textfield;
}

.relative {
  position: relative;
}

.w140 {
  width: 140px !important;
}

.mono {
  font-family: "Roboto Mono", monospace !important;
  font-size: 12px;
  font-weight: 400;
}

.text-small {
  font-size: 12px !important;
}

.smaller-text {
  font-size: 12px !important;
}

.border-all {
  border: 1px solid #aaa;
}

.years-filter-bloc mat-button-toggle span {
  padding: 0 5px !important;
}

body.egret-navy .mat-flat-button, .egret-navy .mat-raised-button, .egret-navy .mat-fab, .egret-navy .mat-mini-fab {
  background-color: #f0f0f0;
}

.egret-navy .mat-checkbox-inner-container {
  margin-right: 2px !important;
}

.egret-navy .mat-chip.mat-accent {
  background: #ff3d57;
  margin: auto 0;
}

.egret-navy .mat-chip.mat-standard-chip {
  margin: auto 0;
}

.egret-navy .mat-icon.mat-icon-inline {
  font-size: 16px;
  height: auto;
  vertical-align: bottom;
}

.no-wrap {
  white-space: nowrap;
}

.plotly-plot {
  position: relative;
  border: 1px solid #eee;
  border-top-color: rgb(238, 238, 238);
  border-top-style: solid;
  border-top-width: 1px;
  margin: 0px -1px -1px 0;
  border-top: 0;
}

.icon-vpdm {
  float: right;
  color: #0081ff;
  font-size: 20px !important;
  margin: auto;
  padding-right: 3px;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  -webkit-font-smoothing: subpixel-antialiased !important;
  -moz-osx-font-smoothing: auto !important;
  font-weight: 300 !important;
  font-size: 1rem !important;
}

.mat-list-item-content {
  padding: 0 !important;
}

button.img-button {
  height: unset;
}

.container-dynamic {
  display: flex;
  flex: 1 1 auto;
}

.text-small-1 {
  font-size: 11px;
}

.text-small-2 {
  font-size: 8px;
}

.egret-navy .mat-tooltip {
  font-size: 16px !important;
}

.handle {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  padding: 20px;
}

.egret-navy .mat-bottom-sheet-container {
  width: 100%;
  background: transparent;
  max-width: unset;
  max-height: unset !important;
}

.egret-navy .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 27px;
}

.egret-navy .mat-button-toggle-appearance-standard .mat-button-toggle-label-content .mat-icon {
  font-size: 18px;
}

.egret-navy .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
  background: #0081ff;
}

.main-content-wrap {
  background: #dddddd;
}

.site-name img {
  vertical-align: sub;
  height: 35px;
  margin-right: 10px;
}

.site-name span {
  vertical-align: text-bottom;
}

.site-name:hover {
  color: #ffe;
}

.site-name {
  font-size: 18px;
  font-weight: 400;
  text-shadow: 1px 1px 1px #000;
  cursor: pointer;
}

.form-bloc-title {
  letter-spacing: 1px;
  font-size: 15px;
  border-left: 5px solid #eee;
  padding-left: 5px;
  color: #1069c9 !important;
  font-weight: 400 !important;
  line-height: 30px;
}

.egret-navy .color-blue {
  color: #1069c9 !important;
  font-weight: 400 !important;
}

.egret-navy .color-red {
  color: #ff3535 !important;
  font-weight: 400 !important;
}

.egret-navy .color-gray {
  color: #808080 !important;
  font-weight: 400 !important;
}

.egret-navy .style-italic {
  font-style: italic;
}

.egret-navy .top-nav button.mat-menu-item {
  color: #bdc0c2;
  width: auto;
  font-size: 15px !important;
  font-weight: 500;
}

.egret-navy .top-nav button.mat-menu-item span {
  vertical-align: middle;
}

.egret-navy .top-nav button .mat-icon {
  color: white;
  margin: 0px 0px;
  font-size: 20px;
  vertical-align: middle;
}

.smaller {
  transform: scale(0.5);
}

.error-bloc {
  padding: 10px;
  background: red;
  margin: 4px;
}

@media print {
  #printContainer > div {
    display: inline;
  }
}
.p-4 {
  padding: 4px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.full-height {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.vert-align-middle {
  vertical-align: middle !important;
}

.inline {
  display: inline-block;
  width: auto !important;
}

.cursor-pointer:hover {
  background-color: #fafafa !important;
}

.egret-navy .ngx-datatable.bg-white {
  background-color: white !important;
}

.egret-navy .mat-toolbar {
  background: #222d32 !important;
  color: #fff !important;
  height: 55px;
}

.egret-navy .mat-drawer-container {
  background-color: #eee !important;
  height: 100%;
}

.egret-navy .mat-select-value {
  color: #222d32 !important;
  font-weight: 500 !important;
  min-height: 25px;
}

.egret-navy .mat-select-trigger {
  height: 30px !important;
}

.egret-navy .mat-form-field-wrapper {
  padding-bottom: 15px !important;
}

.egret-navy mat-form-field-appearance-outline .mat-form-field-flex {
  height: 45px !important;
}

.egret-navy .mat-form-field-hide-placeholder .mat-form-field-label-wrapper {
  /*top: -20px !important;*/
}

.egret-navy .mat-form-field-should-float .mat-form-field-label-wrapper {
  top: -10px !important;
}

.egret-navy textarea.mat-input-element {
  padding: 5px;
  margin: 0px;
  color: black;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.5px;
  overflow-y: auto;
  overflow-x: clip;
  min-height: 32px;
  font-family: Roboto mono;
  font-size: 12px;
}

/*
.egret-navy textarea.mat-input-element {
  padding: 5px;
  margin: 0px;
  color: #449;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.009em;
  overflow-y: auto;
  overflow-x: clip;
  min-height: 32px;
}*/
.egret-navy .mat-input-element {
  caret-color: #0081ff;
  padding: 5px 0px !important;
}

.egret-navy .app-admin-container .rightside-content-hold {
  padding: 0px !important;
}

.egret-navy .mat-form-field-prefix, .mat-form-field-suffix {
  align-self: center;
}

.egret-navy .mat-form-field {
  letter-spacing: normal !important;
  margin-bottom: 5px;
}

.egret-navy .checkbox-group {
  display: inherit;
  padding: 10px;
  border: 1px solid #eee;
}

.egret-navy .mat-card-header {
  background: #fcfcfc;
  border-bottom: 1px solid #eee;
  padding: 5px 0;
}

.egret-navy .checkbox {
  background: #fcfcfc;
  border: 1px solid #eee;
  padding: 5px 10px;
  margin: 2px 0;
}

.egret-navy .mat-card-header .mat-card-header-text {
  margin-bottom: 0;
}

.egret-navy .mat-card-header .mat-card-header-text .mat-card-title {
  margin-bottom: 0;
}

.egret-navy .mat-card-header {
  background: #fafafac;
  margin: -8px -10px 5px -10px !important;
  padding: 8px 13px 4px 4px;
  letter-spacing: 2px;
  font-weight: 600;
  color: blueviolet;
  text-shadow: 1px 1px 3px #aaa;
}

.egret-navy .mat-option-text span.warning {
  color: #ce2020;
  font-size: 15px !important;
  font-weight: bold;
}

tooltip.tooltip, tooltip.tooltip-shadow, .tooltip, .tooltip-shadow {
  max-width: 500px !important;
  width: 500px !important;
  text-align: left;
}

.btn-xs {
  padding: 5px 5px !important;
  min-width: 40px !important;
  height: 30px;
  line-height: 13px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.btn-left-icon {
  text-align: left;
  padding-left: 15px;
}

.btn-left-icon mat-icon {
  font-size: 18px;
  position: absolute;
  left: 10px;
}

.btn-icon {
  background: none;
  border: none;
}

.egret-navy .mat-icon-button.btn-icon {
  height: 20px;
  margin: 0;
  line-height: 10px;
  padding: 0 !important;
  font-size: 23px !important;
}

.btn-tab.active {
  padding: 7px 10px 3px 10px;
  border-bottom: 4px solid #0d6efd;
}

.egret-navy .mat-button.btn-tab {
  padding: 7px 10px !important;
  margin: 0 !important;
  height: 42px;
  font-weight: 400 !important;
  color: #666;
  border-radius: 0;
}

.btn-xs-20 {
  padding: 1px 5px !important;
  height: 20px;
  min-width: 40px !important;
  line-height: 15px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.btn-xs-25 {
  padding: 5px 5px !important;
  height: 25px;
  min-width: 40px !important;
  line-height: 12px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.btn-toggle-sm {
  padding: 1px 1px !important;
  height: 25px;
  min-width: 40px !important;
  line-height: 12px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.btn-xs-25.active {
  background: #ff3535 !important;
  color: white;
  font-weight: 500 !important;
}

.egret-navy .mat-raised-button.mat-primary.active {
  background-color: rgb(250, 50, 50);
  color: white;
}

.mat-list-item p {
  font-weight: 300 !important;
}

.mat-list-item h4 {
  font-weight: 400 !important;
}

.clickable {
  cursor: pointer;
}

.mat-list-base .mat-list-item.mat-2-line, .mat-list-base .mat-list-option.mat-2-line {
  height: auto !important;
  min-height: 50px;
}

.egret-navy .mat-badge-content.mat-badge-active {
  transform: unset !important;
  font-family: "Roboto", "Helvetica", sans-serif;
}

/*
admin
 */
td.numeric {
  text-align: right;
}

.numeric {
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-weight: bold;
  font-size: 0.8rem;
}

.pre-debug {
  height: 100px;
  font-size: 10px;
  line-height: 10px;
  overflow-y: scroll;
}

.shadowed {
  text-shadow: 1px 1px 2px #555;
}

/*

 */
.html-holder {
  overflow: scroll;
  position: relative;
  height: 430px;
  width: 1100px;
}

.html-holder table {
  width: auto;
  background: #eee;
  border-spacing: 0.06em;
}

.html-holder table tr:first-child td {
  background: orange;
}

.html-holder table td.active {
  background: #FF9999 !important;
}

.html-holder table td {
  border: 1px solid #ddd;
  white-space: nowrap;
  /* font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;*/
  font-weight: 400;
  font-size: 0.8rem;
  padding: 1px 3px;
}

.html-holder table td.highlight {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  color: blue;
  background: white !important;
  cursor: pointer;
}

.html-holder table tr:hover td {
  border-bottom: 1px solid black !important;
  background: white !important;
}

.html-holder table td.mapped {
  color: blue;
  border: 1px solid rgba(0, 0, 255, 0.4);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4) inset;
  font-weight: bold;
}

table td.regular {
  background: white;
  border: 1px solid #aaa;
}

table td.calced {
  background: #EEF;
  border: 1px solid #aaa;
}

/*


 */
.form-section h3 {
  margin-top: 5px;
}

.form-section {
  border-bottom: 1px solid #eee;
}

.form-common {
  margin-top: 16px;
}

.egret-navy .mat-select-disabled .mat-select-value {
  color: #f66 !important;
}

.egret-navy .mat-form-field-disabled .mat-form-field-flex {
  opacity: 0.6;
}

.egret-navy .mat-tab-label {
  opacity: 0.8;
  font-size: 15px;
  min-width: 140px !important;
  padding: 0 14px;
}

/*
common parts
 */
markdown * {
  line-height: 1.2 !important;
}

markdown code {
  padding: unset !important;
}

markdown a {
  color: #0d6efd;
}

markdown ul {
  padding: 0;
  margin: 1px 0px 1px 13px;
}

.toolbar-top h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  margin: 0 0 0 20px;
}

.toolbar-top {
  background: white;
  padding: 5px;
  box-shadow: 1px 1px 3px #aaa;
  height: 45px;
  margin: 0px -30px 10px -10px;
  padding-right: 20px;
}

.toolbar-top .search-bar {
  border: 1px solid #999;
  position: relative;
  width: 460px;
  background: #fafafa;
  height: 100%;
}

.toolbar-top .search-bar input:focus {
  border: none !important;
}

.toolbar-top .search-bar input {
  position: absolute;
  line-height: 27px;
  width: 300px;
  right: 0px;
  border: 0;
  background: transparent;
  padding: 0 0 0 5px !important;
  margin: 3px;
}

.toolbar-top .search-bar .mat-icon {
  position: absolute;
  left: 5px;
  width: 20px;
  font-size: 25px;
}

.overlay-drop-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
}

.popup-fixed {
  background: white;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.4);
  width: 1000px;
  z-index: 1000;
  top: 10px;
  overflow: hidden;
}

.avatar-content {
  font-size: 1.1rem !important;
  font-family: Roboto !important;
  font-weight: 300 !important;
}

.warning.mat-icon {
  color: #ff3030;
  font-size: 21px !important;
  display: inline;
}

/* hide input that is binded to ngModel, using ngIf may lead to undefined control*/
.input-hidden {
  display: none;
}

ngx-avatar .avatar-content {
  font-size: 14px !important;
  line-height: 40px !important;
}

ngx-avatar .avatar-container .avatar-content {
  text-shadow: 1px 1px 2px #000;
  font-weight: 500;
}

.bloc-markdown table {
  border-collapse: collapse;
}

.bloc-markdown table td {
  border: 1px solid #eee;
  padding: 3px 5px;
}

.btn-hidden:hover {
  color: red;
  cursor: no-drop;
}

.btn-hidden {
  border-left: 1px solid #aaa;
  padding-left: 10px;
  color: #aaa;
  font-weight: 500;
}

.fluid-CHALEUR {
  color: #d53f35;
}

.fluid-FROID {
  color: blue;
}

.fluid-EAU {
  color: #0c65cd;
}

.fluid-ELEC {
  color: #039955;
}

.col-num {
  text-align: center !important;
  width: 40px;
  font-weight: 500;
}

.col-economies_total {
  text-align: right;
  font-weight: 500;
  padding-right: 5px;
  font-size: 12px;
}

.table-actions-row td {
  border-bottom: 1px solid #eee !important;
  padding-bottom: 4px;
  text-align: right;
}

/* act-log-table
 */
.act-log-table {
  border-collapse: collapse;
  width: 100%;
}

.act-log-table .head td {
  background: #dddddd;
  font-weight: 500;
}

.act-log-table .user td {
  background: #fcc;
  font-weight: 500;
  font-size: 12px;
}

.act-log-table td.col1 {
  font-weight: 500;
}

.act-log-table td {
  border: 1px solid #999;
  padding: 1px 2px;
  font-weight: 400;
}

.vert-gradient {
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(253, 250, 250) 88%, rgb(246, 233, 233) 100%, rgb(0, 212, 255) 100%) !important;
}

.egret-navy .mat-button-toggle.active {
  background-color: #f0f0f0 !important;
  color: #017ef9;
  font-weight: 500;
}