@charset "UTF-8";
/*
 *
 *   EGRET - Angular Material Design Admin Template
 *
 *
*/
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import '../../vendor/pace/pace-theme-min.css';
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spin {
  animation: spin 2s infinite linear;
}

/* 
* REQUIRED STYLES 
*/
html {
  font-size: 16px;
}

html,
body {
  width: 100%;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: Roboto, sans-serif;
}

[tabindex="-1"]:focus {
  outline: none;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

div {
  box-sizing: border-box;
}

html[dir=rtl], html[dir=ltr],
body[dir=rtl],
body[dir=ltr] {
  unicode-bidi: embed;
}

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

.mat-card {
  font-size: 0.875rem;
}

img:not(.mat-card-image) {
  max-width: 100%;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

a {
  color: inherit;
}

p {
  margin: 0 0 16px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
}

.h1,
h1 {
  font-size: 2rem;
}

.h2,
h2 {
  font-size: 1.75rem;
}

.h3,
h3 {
  font-size: 1.5rem;
}

.h4,
h4 {
  font-size: 1.25rem;
}

.h5,
h5 {
  font-size: 1rem;
}

.h6,
h6 {
  font-size: 0.875rem;
}

code {
  padding: 8px;
  background: rgba(0, 0, 0, 0.08);
}

/*---- Common -----*/
.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
}

.bg-none {
  background: transparent !important;
}

.bg-white {
  background: #ffffff !important;
}

.h-full {
  min-height: 100vh;
}

.fix {
  position: relative;
  overflow: hidden;
}

.fix-elm::after {
  display: table;
  width: 100%;
  content: "";
}

.mat-box-shadow {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.light-gray {
  background: rgba(0, 0, 0, 0.024);
}

.light-mat-gray {
  background: rgba(0, 0, 0, 0.08);
}

.mat-blue {
  background: #247ba0;
  color: #fefefe;
}

.mat-red {
  background-color: #f44336 !important;
  color: #fff !important;
}

.mat-indigo {
  background-color: #3f51b5 !important;
  color: #fff !important;
}

.mat-brown {
  background-color: #785548 !important;
  color: #fefefe;
}

.mat-teal {
  background-color: #009688 !important;
  color: #fff !important;
}

.mat-purple {
  background-color: #9c27b0 !important;
  color: hsla(0deg, 0%, 100%, 0.87) !important;
}

.fz-1 {
  font-size: 1rem !important;
}

.fz-2 {
  font-size: 2rem !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: normal !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.text-muted-white {
  color: rgba(255, 255, 255, 0.54) !important;
}

.text-gray {
  color: rgba(0, 0, 0, 0.7) !important;
}

.text-sm {
  font-size: 0.813rem;
}

.list-item-active {
  border-left: 3px solid;
}

.material-icons.icon-sm {
  font-size: 18px !important;
  line-height: 18px !important;
  height: 18px;
  width: 18px;
}

.material-icons.icon-xs {
  font-size: 13px !important;
  line-height: 13px;
  height: 13px;
  width: 13px;
}

.mat-button,
.mat-raised-button {
  font-weight: 400 !important;
}

[mat-lg-button] {
  padding: 0 32px !important;
  font-size: 18px;
  line-height: 56px !important;
}

.mat-icon-button[mat-sm-button] {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.mat-icon-button[mat-xs-button] {
  height: 20px;
  width: 20px;
  line-height: 20px;
}

.mat-icon-button[mat-xs-button] .mat-icon {
  font-size: 16px;
  line-height: 20px !important;
  height: 20px;
  width: 20px;
}

.mat-chip[mat-sm-chip] {
  padding: 4px 6px 4px 6px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  max-height: 20px;
  box-sizing: border-box;
}

.mat-icon-button.img-button img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.compact-list .mat-list-item {
  line-height: 1.1;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.compact-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.list-tasktype .tasktype-item {
  padding: 12px;
}

.list-tasktype .tasktype-item:hover {
  background: rgba(0, 0, 0, 0.08);
}

.list-tasktype .tasktype-item .tasktype-action {
  visibility: hidden;
}

.list-tasktype .tasktype-item:hover .tasktype-action {
  visibility: visible;
}

.doughnut-grid {
  border-radius: 2px;
  overflow: hidden;
}

.doughnut-grid .doughnut-grid-item {
  padding: 1rem;
}

.doughnut-grid .doughnut-grid-item p {
  margin: 0;
}

.doughnut-grid .doughnut-grid-item .chart {
  margin: 0 0 8px;
}

.logo-group {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.toolbar-avatar {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 24px;
}

.toolbar-avatar > img {
  width: 40px !important;
  border-radius: 50%;
}

.toolbar-avatar.md,
.toolbar-avatar.md > img {
  width: 48px !important;
  height: 48px;
}

.toolbar-avatar > .status-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 25px;
  right: -2px;
  border: 2px solid #ffffff;
  background: #ccc;
}

.full-width {
  width: 100% !important;
}

.dnd-item {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
}

.icon-circle {
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.6rem;
  border-radius: 50%;
}

.mat-sidenav .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.035) !important;
}

.mat-chip {
  position: relative;
  overflow: hidden;
}

.text-small {
  font-size: 0.813rem;
}

.text-small .mat-icon {
  font-size: 1rem;
  vertical-align: sub;
  margin: 0 2px;
}

.icon-chip {
  font-size: 11px !important;
  padding: 4px 8px !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon-chip .mat-icon {
  font-size: 16px;
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.mat-chip:not(.mat-basic-chip) {
  display: inline-block;
  padding: 8px 12px 8px 12px;
  border-radius: 24px;
  font-size: 13px;
  line-height: 16px;
}

.ql-container .ql-editor {
  min-height: 200px;
}

.chart {
  display: block;
  width: 100%;
}

.form-error-msg {
  color: #f44336;
  display: block;
  padding: 5px 0;
}

.accordion-handle {
  cursor: pointer;
}

.app-accordion {
  max-height: 200px;
  transition: max-height 0.3s ease;
}

.app-accordion.open {
  max-height: 1000px;
}

.app-accordion .accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.app-accordion.open .accordion-content {
  max-height: 800px;
}

.app-accordion.open .hidden-on-open {
  display: none !important;
}

.app-accordion:not(.open) .show-on-open {
  display: none !important;
}

mat-list a[mat-list-item] .mat-list-item-content,
mat-list mat-list-item .mat-list-item-content,
mat-nav-list a[mat-list-item] .mat-list-item-content,
mat-nav-list mat-list-item .mat-list-item-content,
[mat-menu-item],
.mat-option,
body .mat-select-trigger {
  font-size: 0.875rem !important;
}

.mat-ripple {
  position: relative;
}

.fileupload-drop-zone {
  text-align: center;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  padding: 48px;
  height: 120px;
}

.default-table {
  text-align: left;
}

.default-table > thead tr th {
  font-weight: 400;
  padding: 0.9rem 1.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.default-table tbody tr td {
  padding: 0.9rem 1.2rem;
}

.app-error {
  text-align: center;
  width: 320px;
  max-width: 320px;
  margin: 0 auto;
}

.app-error .error-icon {
  height: 120px;
  width: 120px;
  font-size: 120px;
  float: left;
}

.app-error .error-text {
  float: right;
  width: 200px;
  max-width: 200px;
}

.app-error .error-title {
  font-size: 5rem;
  font-weight: 900;
  margin: 0;
}

.app-error .error-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

.app-error .error-actions {
  width: 100%;
  overflow: hidden;
  min-height: 54px;
  margin-top: 100px;
}

/*---- Loader ----*/
.app-loader,
.view-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.view-loader {
  display: block;
  padding-top: 160px;
  background: rgba(255, 255, 255, 0.3);
  z-index: 9999;
}

.view-loader * {
  margin: auto;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
/*---- Third pirty adjust -----*/
/*------- quill rich text editor ----------*/
.p-0.mat-card-content .ql-container {
  border: 0 !important;
}

.p-0.mat-card-content .ql-toolbar.ql-snow {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/*--- Scroll Bar ---*/
.ps__scrollbar-y-rail {
  z-index: 999;
}

.collapsed-menu .ps__scrollbar-y-rail {
  z-index: auto;
}

/*--- Data table ---*/
.ngx-datatable.material {
  box-shadow: 0 8px 12px rgba(47, 60, 74, 0.04), 0 2px 6px rgba(47, 60, 74, 0.08) !important;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: inherit !important;
}

.datatable-body-cell {
  display: inline-flex !important;
  align-items: center;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.datatable-body-cell-label {
  width: 100%;
  display: flex;
}

/*------ Map ------*/
.agm-info-window-content {
  color: rgba(0, 0, 0, 0.87);
}

/*-------- Chart js ---------*/
.chart {
  margin-left: -10px;
}

.ps__rail-y {
  right: 0 !important;
  left: auto !important;
}

[dir=rtl] .ps__rail-y {
  right: auto !important;
  left: 0 !important;
}
[dir=rtl] .ps__thumb-y {
  right: auto !important;
  left: 1px !important;
}
[dir=rtl] .ngx-datatable .datatable-footer .datatable-pager .pager {
  float: left;
}
[dir=rtl] .user-card .user-details .mat-icon {
  margin-right: 0;
  margin-left: 0.5rem;
}

[dir=rtl] .search-bar-wide .search-close {
  top: 20px;
  right: auto !important;
  left: 15px;
}

@media (max-width: 767px) {
  .mat-card-title .mat-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .accordion-handle {
    flex-direction: column !important;
  }
  .app-error .error-icon {
    height: 100px;
    width: 100px;
    font-size: 100px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  margin-top: 0;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 14px;
}

h6,
.h6 {
  font-size: 13px;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  outline: 0;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

strong,
b {
  font-weight: 700;
}

.mat-card-title {
  font-size: 18px;
  font-weight: 500;
}

.strikethrough {
  text-decoration: line-through;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-10 {
  font-size: 10px;
}
.text-10.mat-icon {
  width: 10px !important;
  height: 10px !important;
  line-height: 10px !important;
}

.text-11 {
  font-size: 11px;
}
.text-11.mat-icon {
  width: 11px !important;
  height: 11px !important;
  line-height: 11px !important;
}

.text-12 {
  font-size: 12px;
}
.text-12.mat-icon {
  width: 12px !important;
  height: 12px !important;
  line-height: 12px !important;
}

.text-13 {
  font-size: 13px;
}
.text-13.mat-icon {
  width: 13px !important;
  height: 13px !important;
  line-height: 13px !important;
}

.text-14 {
  font-size: 14px;
}
.text-14.mat-icon {
  width: 14px !important;
  height: 14px !important;
  line-height: 14px !important;
}

.text-15 {
  font-size: 15px;
}
.text-15.mat-icon {
  width: 15px !important;
  height: 15px !important;
  line-height: 15px !important;
}

.text-16 {
  font-size: 16px;
}
.text-16.mat-icon {
  width: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
}

.text-17 {
  font-size: 17px;
}
.text-17.mat-icon {
  width: 17px !important;
  height: 17px !important;
  line-height: 17px !important;
}

.text-18 {
  font-size: 18px;
}
.text-18.mat-icon {
  width: 18px !important;
  height: 18px !important;
  line-height: 18px !important;
}

.text-19 {
  font-size: 19px;
}
.text-19.mat-icon {
  width: 19px !important;
  height: 19px !important;
  line-height: 19px !important;
}

.text-20 {
  font-size: 20px;
}
.text-20.mat-icon {
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
}

.text-21 {
  font-size: 21px;
}
.text-21.mat-icon {
  width: 21px !important;
  height: 21px !important;
  line-height: 21px !important;
}

.text-22 {
  font-size: 22px;
}
.text-22.mat-icon {
  width: 22px !important;
  height: 22px !important;
  line-height: 22px !important;
}

.text-23 {
  font-size: 23px;
}
.text-23.mat-icon {
  width: 23px !important;
  height: 23px !important;
  line-height: 23px !important;
}

.text-24 {
  font-size: 24px;
}
.text-24.mat-icon {
  width: 24px !important;
  height: 24px !important;
  line-height: 24px !important;
}

.text-25 {
  font-size: 25px;
}
.text-25.mat-icon {
  width: 25px !important;
  height: 25px !important;
  line-height: 25px !important;
}

.text-26 {
  font-size: 26px;
}
.text-26.mat-icon {
  width: 26px !important;
  height: 26px !important;
  line-height: 26px !important;
}

.text-27 {
  font-size: 27px;
}
.text-27.mat-icon {
  width: 27px !important;
  height: 27px !important;
  line-height: 27px !important;
}

.text-28 {
  font-size: 28px;
}
.text-28.mat-icon {
  width: 28px !important;
  height: 28px !important;
  line-height: 28px !important;
}

.text-29 {
  font-size: 29px;
}
.text-29.mat-icon {
  width: 29px !important;
  height: 29px !important;
  line-height: 29px !important;
}

.text-30 {
  font-size: 30px;
}
.text-30.mat-icon {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
}

.text-31 {
  font-size: 31px;
}
.text-31.mat-icon {
  width: 31px !important;
  height: 31px !important;
  line-height: 31px !important;
}

.text-32 {
  font-size: 32px;
}
.text-32.mat-icon {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}

.text-33 {
  font-size: 33px;
}
.text-33.mat-icon {
  width: 33px !important;
  height: 33px !important;
  line-height: 33px !important;
}

.text-34 {
  font-size: 34px;
}
.text-34.mat-icon {
  width: 34px !important;
  height: 34px !important;
  line-height: 34px !important;
}

.text-35 {
  font-size: 35px;
}
.text-35.mat-icon {
  width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
}

.text-36 {
  font-size: 36px;
}
.text-36.mat-icon {
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
}

.text-37 {
  font-size: 37px;
}
.text-37.mat-icon {
  width: 37px !important;
  height: 37px !important;
  line-height: 37px !important;
}

.text-38 {
  font-size: 38px;
}
.text-38.mat-icon {
  width: 38px !important;
  height: 38px !important;
  line-height: 38px !important;
}

.text-39 {
  font-size: 39px;
}
.text-39.mat-icon {
  width: 39px !important;
  height: 39px !important;
  line-height: 39px !important;
}

.text-40 {
  font-size: 40px;
}
.text-40.mat-icon {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}

.text-41 {
  font-size: 41px;
}
.text-41.mat-icon {
  width: 41px !important;
  height: 41px !important;
  line-height: 41px !important;
}

.text-42 {
  font-size: 42px;
}
.text-42.mat-icon {
  width: 42px !important;
  height: 42px !important;
  line-height: 42px !important;
}

.text-43 {
  font-size: 43px;
}
.text-43.mat-icon {
  width: 43px !important;
  height: 43px !important;
  line-height: 43px !important;
}

.text-44 {
  font-size: 44px;
}
.text-44.mat-icon {
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
}

.text-45 {
  font-size: 45px;
}
.text-45.mat-icon {
  width: 45px !important;
  height: 45px !important;
  line-height: 45px !important;
}

.text-46 {
  font-size: 46px;
}
.text-46.mat-icon {
  width: 46px !important;
  height: 46px !important;
  line-height: 46px !important;
}

.text-47 {
  font-size: 47px;
}
.text-47.mat-icon {
  width: 47px !important;
  height: 47px !important;
  line-height: 47px !important;
}

.text-48 {
  font-size: 48px;
}
.text-48.mat-icon {
  width: 48px !important;
  height: 48px !important;
  line-height: 48px !important;
}

.text-49 {
  font-size: 49px;
}
.text-49.mat-icon {
  width: 49px !important;
  height: 49px !important;
  line-height: 49px !important;
}

.text-50 {
  font-size: 50px;
}
.text-50.mat-icon {
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
}

.text-51 {
  font-size: 51px;
}
.text-51.mat-icon {
  width: 51px !important;
  height: 51px !important;
  line-height: 51px !important;
}

.text-52 {
  font-size: 52px;
}
.text-52.mat-icon {
  width: 52px !important;
  height: 52px !important;
  line-height: 52px !important;
}

.text-53 {
  font-size: 53px;
}
.text-53.mat-icon {
  width: 53px !important;
  height: 53px !important;
  line-height: 53px !important;
}

.text-54 {
  font-size: 54px;
}
.text-54.mat-icon {
  width: 54px !important;
  height: 54px !important;
  line-height: 54px !important;
}

.text-55 {
  font-size: 55px;
}
.text-55.mat-icon {
  width: 55px !important;
  height: 55px !important;
  line-height: 55px !important;
}

.text-56 {
  font-size: 56px;
}
.text-56.mat-icon {
  width: 56px !important;
  height: 56px !important;
  line-height: 56px !important;
}

.text-57 {
  font-size: 57px;
}
.text-57.mat-icon {
  width: 57px !important;
  height: 57px !important;
  line-height: 57px !important;
}

.text-58 {
  font-size: 58px;
}
.text-58.mat-icon {
  width: 58px !important;
  height: 58px !important;
  line-height: 58px !important;
}

.text-59 {
  font-size: 59px;
}
.text-59.mat-icon {
  width: 59px !important;
  height: 59px !important;
  line-height: 59px !important;
}

.text-60 {
  font-size: 60px;
}
.text-60.mat-icon {
  width: 60px !important;
  height: 60px !important;
  line-height: 60px !important;
}

.text-61 {
  font-size: 61px;
}
.text-61.mat-icon {
  width: 61px !important;
  height: 61px !important;
  line-height: 61px !important;
}

.text-62 {
  font-size: 62px;
}
.text-62.mat-icon {
  width: 62px !important;
  height: 62px !important;
  line-height: 62px !important;
}

.text-63 {
  font-size: 63px;
}
.text-63.mat-icon {
  width: 63px !important;
  height: 63px !important;
  line-height: 63px !important;
}

.text-64 {
  font-size: 64px;
}
.text-64.mat-icon {
  width: 64px !important;
  height: 64px !important;
  line-height: 64px !important;
}

.text-65 {
  font-size: 65px;
}
.text-65.mat-icon {
  width: 65px !important;
  height: 65px !important;
  line-height: 65px !important;
}

.text-66 {
  font-size: 66px;
}
.text-66.mat-icon {
  width: 66px !important;
  height: 66px !important;
  line-height: 66px !important;
}

.text-67 {
  font-size: 67px;
}
.text-67.mat-icon {
  width: 67px !important;
  height: 67px !important;
  line-height: 67px !important;
}

.text-68 {
  font-size: 68px;
}
.text-68.mat-icon {
  width: 68px !important;
  height: 68px !important;
  line-height: 68px !important;
}

.text-69 {
  font-size: 69px;
}
.text-69.mat-icon {
  width: 69px !important;
  height: 69px !important;
  line-height: 69px !important;
}

.text-70 {
  font-size: 70px;
}
.text-70.mat-icon {
  width: 70px !important;
  height: 70px !important;
  line-height: 70px !important;
}

.text-71 {
  font-size: 71px;
}
.text-71.mat-icon {
  width: 71px !important;
  height: 71px !important;
  line-height: 71px !important;
}

.text-72 {
  font-size: 72px;
}
.text-72.mat-icon {
  width: 72px !important;
  height: 72px !important;
  line-height: 72px !important;
}

.text-73 {
  font-size: 73px;
}
.text-73.mat-icon {
  width: 73px !important;
  height: 73px !important;
  line-height: 73px !important;
}

.text-74 {
  font-size: 74px;
}
.text-74.mat-icon {
  width: 74px !important;
  height: 74px !important;
  line-height: 74px !important;
}

.text-75 {
  font-size: 75px;
}
.text-75.mat-icon {
  width: 75px !important;
  height: 75px !important;
  line-height: 75px !important;
}

.text-76 {
  font-size: 76px;
}
.text-76.mat-icon {
  width: 76px !important;
  height: 76px !important;
  line-height: 76px !important;
}

.text-77 {
  font-size: 77px;
}
.text-77.mat-icon {
  width: 77px !important;
  height: 77px !important;
  line-height: 77px !important;
}

.text-78 {
  font-size: 78px;
}
.text-78.mat-icon {
  width: 78px !important;
  height: 78px !important;
  line-height: 78px !important;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-inherit {
  font-weight: inherit;
}

.line-height-1 {
  line-height: 1 !important;
}

.mat-heading-label {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.92px;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 1rem;
}

.app-admin-wrap {
  height: 100vh;
  overflow: hidden;
}

.app-admin-container {
  max-width: 100%;
  height: 100vh;
}
.app-admin-container .rightside-content-hold {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.333333rem;
  overflow-x: hidden;
  position: relative;
  min-height: 450px;
}

.sidebar-panel {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: 10001;
  width: 15rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: hidden;
  background: url("../../../images/sidebar-bg.jpg") no-repeat;
}
.sidebar-panel .sidebar-hold {
  width: 15rem;
}
.sidebar-panel .navigation-hold {
  position: absolute;
  height: calc(100% - 64px);
  width: 100%;
  margin-top: 64px;
  background: rgba(255, 255, 255, 0.95);
  left: 0;
}
.sidebar-panel ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-content-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  float: right;
  height: 100vh;
  transition: width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.main-content-wrap.ps > .ps__rail-y {
  z-index: 9999;
}

.sidebar-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-backdrop.visible {
  visibility: visible;
  opacity: 1;
}

.app-admin-container.sidebar-full:not(.compact-toggle-active) .main-content-wrap {
  width: calc(100% - 15rem);
}
.app-admin-container.sidebar-full:not(.compact-toggle-active) .sidebar-panel {
  width: 15rem;
  overflow: hidden;
}

.app-admin-container.compact-toggle-active .main-content-wrap {
  width: calc(100% - 64px);
}

.app-admin-container.sidebar-compact .sidebar-panel,
.app-admin-container.sidebar-compact .sidebar-hold {
  width: 64px;
}

.app-admin-container.sidebar-compact-big .main-content-wrap {
  width: calc(100% - 150px);
}
.app-admin-container.sidebar-compact-big .sidebar-panel {
  width: 150px;
}
.app-admin-container.sidebar-compact-big .sidebar-panel .branding {
  width: 150px;
}
.app-admin-container.sidebar-compact-big .sidebar-panel .branding .app-logo {
  margin: auto;
}
.app-admin-container.sidebar-compact-big .sidebar-panel .navigation-hold {
  overflow: visible !important;
}

.app-admin-container.sidebar-closed .sidebar-panel {
  overflow: hidden;
  left: -15rem;
}
.app-admin-container.sidebar-closed .sidebar-panel .branding {
  left: -15rem;
}
.app-admin-container.sidebar-closed .main-content-wrap {
  width: 100%;
}

.fixed-topbar .rightside-content-hold {
  overflow: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 64px;
}

.layout-intransition .branding {
  display: none !important;
}
.layout-intransition .app-user-controls,
.layout-intransition .app-user-name {
  opacity: 0 !important;
}

.app-admin-container.sidebar-full.navigation-top .sidebar-panel {
  left: 0;
}

.app-admin-container.navigation-top .container-dynamic {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.app-admin-container.navigation-top .main-content-wrap {
  float: none;
  width: 100% !important;
}
.app-admin-container.navigation-top .rightside-content-hold {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 48px;
}
.app-admin-container.navigation-top .sidebar-panel {
  overflow: hidden;
  left: -15rem;
}
.app-admin-container.navigation-top .sidebar-panel .navigation-hold {
  margin-top: 48px;
  height: calc(100% - 48px);
}

[dir=rtl].app-admin-container.sidebar-closed .sidebar-panel {
  right: -15rem;
}
[dir=rtl].app-admin-container.sidebar-closed .branding {
  left: auto !important;
  right: -15rem;
}
[dir=rtl] .main-content-wrap {
  float: left;
}
[dir=rtl] .sidebar-panel {
  right: 0;
  left: auto !important;
}

@media (max-width: 959px) {
  .app-admin-container.sidebar-full .main-content-wrap {
    width: 100% !important;
  }
}
.notification-number {
  position: absolute;
  top: 0;
  left: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}

#langToggle .mat-select-trigger {
  width: 80px;
  min-width: 80px;
}

.theme-list .mat-menu-item {
  width: 48px;
  height: 48px;
  padding: 5px;
}
.theme-list .mat-menu-item .egret-swatch {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.theme-list .mat-menu-item .active-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto !important;
  color: #ffffff;
}

.topbar-button-right {
  margin: 0 0.5rem !important;
}

.sidenav-hold .menuitem-badge {
  padding: 3px 10px;
  line-height: 12px;
  color: #ffffff !important;
  font-weight: 400;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 8px;
}
.sidenav-hold .icon-menu {
  padding: 0 24px;
  opacity: 1;
  transition: all 0.3s ease-in;
}
.sidenav-hold .icon-menu .icon-menu-item {
  display: inline-block;
}
.sidenav-hold .icon-menu .icon-menu-item button {
  min-width: auto;
  margin: 4px;
}
.sidenav-hold .icon-menu > .mat-divider {
  margin: 1rem 0;
}
.sidenav-hold .nav-item-sep {
  padding: 0;
  margin: 0 0 1rem;
}
.sidenav-hold .nav-item-sep .icon-menu-title {
  padding-left: 0px;
  margin-left: -8px;
}
.sidenav-hold .nav-item-sep span {
  padding: 16px 0 0 16px;
  display: block;
  font-size: 12px;
}
.sidenav-hold .sidenav li {
  cursor: pointer;
}
.sidenav-hold .sidenav li ul.submenu {
  max-height: 0;
  overflow: hidden;
  opacity: 1;
}
.sidenav-hold .sidenav li ul.submenu.lvl2 a, .sidenav-hold .sidenav li ul.submenu.lvl3 a {
  height: 44px;
}
.sidenav-hold .sidenav li ul.submenu.lvl2 a {
  padding: 0 16px 0 55px;
}
.sidenav-hold .sidenav li ul.submenu.lvl3 a {
  padding: 0 16px 0 64px;
}
.sidenav-hold .sidenav a {
  position: relative;
  width: 100%;
  padding: 0 16px 0 0;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  text-decoration: none;
}
.sidenav-hold .sidenav a .mat-icon.sidenav-mat-icon {
  text-align: center;
  margin-right: 3px;
  height: 48px;
  width: 48px;
  font-size: 16px;
  line-height: 48px;
  border-left: 3px solid;
  border-color: transparent;
  color: #8990a2;
}
.sidenav-hold .sidenav a .svgIcon {
  padding: 15px;
  height: 48px;
  width: 48px;
  border-left: 3px solid;
  border-color: transparent;
  color: #8990a2;
  box-sizing: border-box;
}
.sidenav-hold .sidenav a .menu-caret {
  font-size: 1rem;
  line-height: 1;
  height: 16px;
  width: 16px;
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}

.sidebar-full .sidenav-hold li ul {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-full .sidenav-hold li ul::after, .sidebar-full .sidenav-hold li ul::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 2px;
  z-index: 3;
}
.sidebar-full .sidenav-hold li ul::after {
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0.06), transparent);
  bottom: 0;
}
.sidebar-full .sidenav-hold li ul::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), transparent);
  top: 0;
}
.sidebar-full .sidenav-hold li.open, .sidebar-full .sidenav-hold li.open li.open {
  background: rgba(0, 0, 0, 0.02);
}
.sidebar-full .sidenav-hold li.open > div > ul, .sidebar-full .sidenav-hold li.open > ul {
  max-height: 1000px;
  background: rgba(0, 0, 0, 0.005);
}
.sidebar-full .sidenav-hold li.open > div > a .menu-caret, .sidebar-full .sidenav-hold li.open > a .menu-caret {
  transform: rotate(90deg);
}
.sidebar-full .sidenav-hold a {
  height: 48px;
}

.sidebar-compact .sidenav-hold .icon-menu,
.sidebar-compact-big .sidenav-hold .icon-menu {
  padding: 8px 0 0;
}
.sidebar-compact .sidenav-hold .icon-menu .nav-item-sep,
.sidebar-compact-big .sidenav-hold .icon-menu .nav-item-sep {
  display: none;
}
.sidebar-compact .sidenav-hold .nav-item-sep,
.sidebar-compact-big .sidenav-hold .nav-item-sep {
  margin: 1rem 0;
}
.sidebar-compact .sidenav-hold .nav-item-sep span,
.sidebar-compact-big .sidenav-hold .nav-item-sep span {
  display: none;
}
.sidebar-compact .sidenav-hold a,
.sidebar-compact-big .sidenav-hold a {
  padding: 0 !important;
}
.sidebar-compact .sidenav-hold li,
.sidebar-compact-big .sidenav-hold li {
  position: relative;
  z-index: 10001;
}
.sidebar-compact .sidenav-hold li .lvl1 > a > .mat-icon.menu-caret,
.sidebar-compact .sidenav-hold li .menuitem-badge,
.sidebar-compact-big .sidenav-hold li .lvl1 > a > .mat-icon.menu-caret,
.sidebar-compact-big .sidenav-hold li .menuitem-badge {
  display: none;
}
.sidebar-compact .sidenav-hold li:hover > div > ul.submenu, .sidebar-compact .sidenav-hold li:hover > ul.submenu,
.sidebar-compact-big .sidenav-hold li:hover > div > ul.submenu,
.sidebar-compact-big .sidenav-hold li:hover > ul.submenu {
  max-height: 1000px;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-compact .sidenav-hold li ul.submenu,
.sidebar-compact-big .sidenav-hold li ul.submenu {
  overflow: visible;
  position: absolute;
  left: 100%;
  top: 0;
  width: 200px;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.sidebar-compact .sidenav-hold li ul.submenu.lvl2 > li > a, .sidebar-compact .sidenav-hold li ul.submenu.lvl3 > li > a,
.sidebar-compact-big .sidenav-hold li ul.submenu.lvl2 > li > a,
.sidebar-compact-big .sidenav-hold li ul.submenu.lvl3 > li > a {
  height: 36px;
  line-height: 36px;
  padding: 0 16px !important;
}

.sidebar-compact .icon-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 64px;
}
.sidebar-compact .icon-menu .icon-menu-item button {
  margin: 0 0 8px 0;
  padding: 0 12px;
  box-shadow: none;
}
.sidebar-compact .sidenav-hold .sidenav .mat-icon:not(.menu-caret) {
  margin: 0 !important;
  width: 64px !important;
}
.sidebar-compact .sidenav-hold .item-name.lvl1 {
  display: none;
}

.sidebar-compact-big .sidenav > li > div > a {
  text-align: center;
  display: block !important;
  padding: 16px 0;
}
.sidebar-compact-big .sidenav .mat-icon:not(.menu-caret) {
  height: 36px;
  width: 150px;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
}

.layout-intransition .sidenav ul.submenu {
  opacity: 0 !important;
}

[dir=rtl].sidebar-full .sidenav-hold a {
  padding: 0 0 0 16px;
}
[dir=rtl].sidebar-compact .sidenav-hold a, [dir=rtl].sidebar-compact-big .sidenav-hold a {
  padding: 0 !important;
}
[dir=rtl].sidebar-compact .sidenav-hold li ul.submenu, [dir=rtl].sidebar-compact-big .sidenav-hold li ul.submenu {
  left: auto !important;
  right: 100%;
}
[dir=rtl].sidebar-compact .sidenav-hold li ul.submenu.lvl2 > li > a, [dir=rtl].sidebar-compact .sidenav-hold li ul.submenu.lvl3 > li > a, [dir=rtl].sidebar-compact-big .sidenav-hold li ul.submenu.lvl2 > li > a, [dir=rtl].sidebar-compact-big .sidenav-hold li ul.submenu.lvl3 > li > a {
  padding: 0 16px !important;
}
[dir=rtl] .sidenav-hold .sidenav a {
  padding: 0 0 0 16px;
}
[dir=rtl] .sidenav-hold .sidenav a .mat-icon:not(.menu-caret) {
  border-left: none;
  border-right: 3px solid;
  margin-right: 1px;
  border-color: transparent;
}
[dir=rtl] .sidenav-hold .sidenav a .menu-caret {
  transform: rotate(180deg);
}
[dir=rtl] .sidenav-hold .sidenav li ul.submenu.lvl2 > li > a {
  padding: 0 55px 0 16px;
}
[dir=rtl] .sidenav-hold .sidenav li ul.submenu.lvl3 > li > a {
  padding: 0 64px 0 16px;
}
[dir=rtl] .sidenav-hold .nav-item-sep .icon-menu-title {
  padding-right: 0 !important;
  margin: 0 -6px 0 0 !important;
}
[dir=rtl] .sidenav-hold .nav-item-sep span {
  padding: 16px 20px 0 0;
}

.main-footer {
  padding: 0 0.667rem;
}
.main-footer > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 64px;
}
.navigation-top .main-footer {
  margin: 0 -1rem -0.333rem;
}

/*
* ALTERNATIVE STYLES
*/
.breadcrumb {
  display: block;
  margin: 0;
  padding: 0;
}
.breadcrumb li {
  list-style: none;
  float: left;
}
.breadcrumb li:not(:first-child):before {
  content: "/ ";
  padding: 0 8px;
}

.breadcrumb-bar {
  position: relative;
  width: 100%;
}
.breadcrumb-bar .breadcrumb {
  padding: 0 1rem;
  overflow: hidden;
  margin-top: 0.5rem;
}
.breadcrumb-bar .breadcrumb li {
  line-height: 40px;
}
.breadcrumb-bar .breadcrumb li:not(:first-child):before {
  padding: 0 8px;
}
.breadcrumb-bar .breadcrumb li a {
  font-weight: 400;
  font-size: 1rem;
}

.breadcrumb-title {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  line-height: 40px;
}
.breadcrumb-title .bc-title {
  font-size: 14px;
  margin: 0;
  line-height: 40px;
}
.breadcrumb-title .breadcrumb {
  position: relative;
  margin-left: 24px;
}
.breadcrumb-title .breadcrumb::before {
  position: absolute;
  content: "";
  height: 24px;
  width: 2px;
  background: #6a6a6a;
  left: -12px;
  top: 8px;
}

[dir=rtl] .breadcrumb-bar .breadcrumb li,
[dir=rtl] .breadcrumb-title .breadcrumb li {
  float: right;
}
[dir=rtl] .breadcrumb-title .breadcrumb {
  margin-left: 0;
  margin-right: 24px;
}
[dir=rtl] .breadcrumb-title .breadcrumb::before {
  left: auto;
  right: -12px;
}

.navigation-top .nofication-panel {
  padding-top: 48px;
}

.notification-list {
  min-width: 240px;
}

.notification-list .notific-item a {
  outline: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notification-list .notific-item .message {
  font-size: 0.875rem !important;
}

.notification-list .notific-item .time {
  font-size: 0.75rem !important;
}

.sidebar-compact-switch {
  position: relative;
  width: 24px;
  height: 12px;
  border-radius: 10px;
  border: 1px solid #666;
  cursor: pointer;
  z-index: 999999;
  opacity: 1;
  transition: left 0.3s, opacity 0.1s;
}
.sidebar-compact-switch span {
  position: absolute;
  left: 1px;
  top: 1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #666;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-compact-switch.active {
  background: #444;
}
.sidebar-compact-switch.active span {
  left: 13px;
  background: #ffffff;
}

.branding {
  display: flex;
  align-items: center;
  position: fixed;
  padding: 0 16px;
  width: 15rem;
  height: 64px;
  line-height: 64px;
  top: 0;
  left: 0;
  z-index: 999;
  color: #444;
  background: #ffffff;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: hidden;
}
.branding .app-logo {
  width: auto;
  height: 36px;
  margin-right: 8px;
}
.branding .app-logo-text {
  width: auto;
  font-size: 20px;
  font-weight: 400;
}

.app-user {
  text-align: center;
  width: 100%;
  padding: 1rem 0;
}
.app-user .app-user-photo {
  width: 72px;
  margin: 0 auto 8px;
  height: 72px;
  box-sizing: border-box;
  padding: 4px;
  border-radius: 50%;
}
.app-user .app-user-controls .mat-icon-button {
  margin: 0 2px;
}
.app-user img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.app-user .app-user-name {
  display: block;
  font-size: 0.875rem;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.96);
  margin-left: -2px;
}

.app-admin-container.sidebar-compact .sidebar-compact-switch {
  opacity: 0;
  left: -2rem;
}
.app-admin-container.sidebar-compact .branding {
  width: 64px;
  padding: 0;
}
.app-admin-container.sidebar-compact .branding .app-logo {
  margin: auto;
}
.app-admin-container.sidebar-compact .app-user {
  padding: 0.5rem 0;
}
.app-admin-container.sidebar-compact .app-user .app-user-photo {
  width: 40px;
  height: 40px;
  margin-bottom: 0;
}
.app-admin-container.sidebar-compact .app-user-name,
.app-admin-container.sidebar-compact .app-user-controls,
.app-admin-container.sidebar-compact .app-logo-text {
  display: none !important;
}

[dir=rtl] .app-admin-container.sidebar-compact .sidebar-hold {
  overflow: hidden;
}
[dir=rtl] .branding {
  top: 0;
  left: auto !important;
  right: 0;
}
[dir=rtl] .branding .app-logo {
  margin-right: 0;
  margin-left: 8px;
}

.topbar.mat-toolbar {
  position: relative;
  box-shadow: 0 10px 30px 0 rgba(47, 60, 74, 0.08);
  z-index: 1001;
  padding: 0 16px 0 5px;
}
.topbar .mat-select-value {
  font-weight: 400;
  color: #ffffff;
}

.toggle-collapsed {
  transform: rotate(0deg);
  -webkit-transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}

.sidebar-compact .toggle-collapsed {
  transform: rotate(180deg);
}

.search-bar .top-search-form {
  position: relative;
  background: #fff;
  border-radius: 40px;
  margin-right: 1rem;
  display: block;
  max-width: 220px;
  margin-left: 20px;
  box-shadow: inset 0 0 2px 2px rgba(136, 136, 136, 0.2);
}
.search-bar .top-search-form .material-icons {
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -12px;
  color: rgba(0, 0, 0, 0.87);
}
.search-bar .top-search-form input {
  font-size: 1rem;
  padding: 0.6rem 0.75rem;
  z-index: 2;
  cursor: text;
  text-indent: 30px;
  border: none;
  background: transparent;
  width: 100%;
  outline: 0;
}

.topnav:after {
  content: "";
  display: table;
  clear: both;
}
.topnav label.menu-toggle {
  height: 48px;
  width: 48px;
  box-sizing: border-box;
  padding: 12px;
  border-radius: 50%;
}
.topnav label.menu-toggle .mat-icon {
  font-size: 24px;
}
.topnav .toggle,
.topnav [id^=drop] {
  display: none;
}
.topnav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}
.topnav ul:not(.menu) {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0), 0 4px 8px rgba(0, 0, 0, 0.28);
}
.topnav ul.menu {
  float: left;
  height: 48px;
  padding-right: 45px;
}
.topnav ul.menu > li {
  float: left;
}
.topnav ul.menu > li > div > a,
.topnav ul.menu > li > div > div {
  border-bottom: 2px solid;
  height: 48px;
  box-sizing: border-box;
  border-color: transparent;
  margin: 0 6px;
}
.topnav ul li {
  margin: 0px;
  display: inline-block;
}
.topnav a, .topnav label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 20px;
  height: 44px;
  font-size: 0.875rem;
  text-decoration: none;
  box-sizing: border-box;
}
.topnav a .mat-icon, .topnav label .mat-icon {
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  width: 20px;
  margin-right: 2px;
}
.topnav ul ul {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  /* has to be the same number as the "line-height" of "nav a" */
  top: 48px;
  transform: translateY(-100px);
  transition: all 0.3s ease-in-out;
  z-index: -1;
}
.topnav ul li:hover > div > div > ul,
.topnav ul li:hover > div > ul {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.topnav ul ul li {
  width: 170px;
  float: none;
  display: list-item;
  position: relative;
}
.topnav ul ul ul {
  top: 0;
  left: 170px;
}
.topnav ul ul ul li {
  position: relative;
  top: 0;
}
.topnav li > a:after {
  content: " +";
}
.topnav li > a:only-child:after {
  content: "";
}

/* Media Queries
--------------------------------------------- */
@media all and (max-width: 768px) {
  .topnav {
    margin: 0;
    /* Hide Dropdowns by Default */
    /* Hide menus on hover */
  }
  .topnav .toggle + a {
    display: none;
  }
  .topnav .menu {
    opacity: 0;
    visibility: hidden;
    height: auto !important;
    width: 100%;
  }
  .topnav .menu li a {
    border: none !important;
  }
  .topnav .toggle {
    display: flex;
    text-decoration: none;
    border: none;
  }
  .topnav ul {
    overflow: hidden;
    max-height: 0px;
    transform: translateY(0px) !important;
    transition: max-height 0.3s ease-in-out;
  }
  .topnav [id^=drop]:checked + ul {
    opacity: 1;
    visibility: visible;
    max-height: 2000px;
  }
  .topnav [id^=drop]:checked + ul.menu {
    max-height: 300px;
    overflow-y: scroll;
  }
  .topnav ul li {
    position: relative;
    opacity: 1;
    visibility: visible;
    width: 100%;
    z-index: 1;
  }
  .topnav ul ul .toggle,
.topnav ul ul a {
    padding: 0 40px;
  }
  .topnav ul ul ul a {
    padding: 0 80px;
  }
  .topnav ul li ul li .toggle,
.topnav ul ul a,
.topnav ul ul ul a {
    padding: 14px 20px;
  }
  .topnav ul ul {
    float: none;
    position: relative;
    top: 0;
    left: 0;
    box-shadow: none !important;
    z-index: 1;
  }
  .topnav ul li:hover > div > div > ul,
.topnav ul li:hover > div > ul {
    opacity: 0;
    visibility: hidden;
  }
  .topnav ul ul li {
    opacity: 1;
    visibility: visible;
    width: 100%;
  }
  .topnav ul ul ul {
    left: 0;
  }
  .topnav ul ul ul li {
    position: static;
  }
}
@media all and (max-width: 330px) {
  .topnav ul li {
    display: block;
    width: 94%;
  }
}
[dir=rtl] .topnav a .mat-icon, [dir=rtl] .topnav label .mat-icon {
  margin-right: 0;
  margin-left: 2px;
}

.header-topnav {
  position: fixed;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 0;
  z-index: 999;
}
.header-topnav .container {
  padding: 0;
}
.header-topnav .topbar-branding {
  float: left;
  height: 48px;
  padding: 8px;
  margin: 0 8px;
}
.header-topnav .topbar-branding img {
  height: 100%;
  width: auto;
}
.header-topnav .topnav {
  display: flex;
  align-items: center;
}
.header-topnav .header-topnav-right {
  float: right;
  height: 48px;
  display: flex;
  align-items: center;
  padding-right: 0.67rem;
}

@media (max-width: 959px) {
  .header-topnav-right {
    position: absolute;
    right: 6px;
    top: 0;
  }
}
[dir=rtl] .header-topnav .topnav {
  flex-direction: row-reverse;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto;
  letter-spacing: 0em;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 500 15px / 24px Roboto;
  letter-spacing: 0.0067em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto;
  letter-spacing: 0.0179em;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto;
  letter-spacing: 0.0179em;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto;
  letter-spacing: 0.0333em;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 400 112px / 112px Roboto;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto;
  letter-spacing: 0em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto;
  letter-spacing: 0.0179em;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto;
  letter-spacing: 0.0075em;
}

.mat-expansion-panel-header {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto;
  letter-spacing: 0.0179em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto;
  letter-spacing: 1.5px;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto;
  font-size: 12px;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 500;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto;
  letter-spacing: 0.0075em;
  margin: 0;
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto;
  letter-spacing: 0.0179em;
}

.mat-simple-snackbar {
  font-family: Roboto;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.egret-navy {
  color: rgba(0, 0, 0, 0.87);
  /* up arrow  */
  /* right arrow */
  /* bottom arrow */
  /* Left Arrow */
}
.egret-navy .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.egret-navy .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-option:hover:not(.mat-option-disabled), .egret-navy .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.egret-navy .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.egret-navy .mat-option.mat-active {
  background: rgba(44, 44, 44, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0081ff;
}
.egret-navy .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff8a48;
}
.egret-navy .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff3d57;
}
.egret-navy .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.egret-navy .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.egret-navy .mat-primary .mat-pseudo-checkbox-checked,
.egret-navy .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0081ff;
}
.egret-navy .mat-pseudo-checkbox-checked,
.egret-navy .mat-pseudo-checkbox-indeterminate,
.egret-navy .mat-accent .mat-pseudo-checkbox-checked,
.egret-navy .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff8a48;
}
.egret-navy .mat-warn .mat-pseudo-checkbox-checked,
.egret-navy .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff3d57;
}
.egret-navy .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.egret-navy .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.egret-navy .mat-app-background, .egret-navy.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.egret-navy .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.egret-navy .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-badge {
  position: relative;
}
.egret-navy .mat-badge.mat-badge {
  overflow: visible;
}
.egret-navy .mat-badge-hidden .mat-badge-content {
  display: none;
}
.egret-navy .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.egret-navy .ng-animate-disabled .mat-badge-content,
.egret-navy .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.egret-navy .mat-badge-content.mat-badge-active {
  transform: none;
}
.egret-navy .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.egret-navy .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.egret-navy .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.egret-navy .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .egret-navy .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.egret-navy .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .egret-navy .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.egret-navy .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .egret-navy .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.egret-navy .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .egret-navy .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.egret-navy .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.egret-navy .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.egret-navy .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.egret-navy .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .egret-navy .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.egret-navy .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .egret-navy .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.egret-navy .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .egret-navy .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.egret-navy .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .egret-navy .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.egret-navy .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.egret-navy .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.egret-navy .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.egret-navy .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .egret-navy .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.egret-navy .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .egret-navy .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.egret-navy .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .egret-navy .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.egret-navy .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .egret-navy .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.egret-navy .mat-badge-content {
  color: white;
  background: #0081ff;
}
.cdk-high-contrast-active .egret-navy .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.egret-navy .mat-badge-accent .mat-badge-content {
  background: #ff8a48;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff3d57;
}
.egret-navy .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-button, .egret-navy .mat-icon-button, .egret-navy .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.egret-navy .mat-button.mat-primary, .egret-navy .mat-icon-button.mat-primary, .egret-navy .mat-stroked-button.mat-primary {
  color: #0081ff;
}
.egret-navy .mat-button.mat-accent, .egret-navy .mat-icon-button.mat-accent, .egret-navy .mat-stroked-button.mat-accent {
  color: #ff8a48;
}
.egret-navy .mat-button.mat-warn, .egret-navy .mat-icon-button.mat-warn, .egret-navy .mat-stroked-button.mat-warn {
  color: #ff3d57;
}
.egret-navy .mat-button.mat-primary.mat-button-disabled, .egret-navy .mat-button.mat-accent.mat-button-disabled, .egret-navy .mat-button.mat-warn.mat-button-disabled, .egret-navy .mat-button.mat-button-disabled.mat-button-disabled, .egret-navy .mat-icon-button.mat-primary.mat-button-disabled, .egret-navy .mat-icon-button.mat-accent.mat-button-disabled, .egret-navy .mat-icon-button.mat-warn.mat-button-disabled, .egret-navy .mat-icon-button.mat-button-disabled.mat-button-disabled, .egret-navy .mat-stroked-button.mat-primary.mat-button-disabled, .egret-navy .mat-stroked-button.mat-accent.mat-button-disabled, .egret-navy .mat-stroked-button.mat-warn.mat-button-disabled, .egret-navy .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.egret-navy .mat-button.mat-primary .mat-button-focus-overlay, .egret-navy .mat-icon-button.mat-primary .mat-button-focus-overlay, .egret-navy .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #0081ff;
}
.egret-navy .mat-button.mat-accent .mat-button-focus-overlay, .egret-navy .mat-icon-button.mat-accent .mat-button-focus-overlay, .egret-navy .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff8a48;
}
.egret-navy .mat-button.mat-warn .mat-button-focus-overlay, .egret-navy .mat-icon-button.mat-warn .mat-button-focus-overlay, .egret-navy .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff3d57;
}
.egret-navy .mat-button.mat-button-disabled .mat-button-focus-overlay, .egret-navy .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .egret-navy .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.egret-navy .mat-button .mat-ripple-element, .egret-navy .mat-icon-button .mat-ripple-element, .egret-navy .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.egret-navy .mat-button-focus-overlay {
  background: black;
}
.egret-navy .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-flat-button, .egret-navy .mat-raised-button, .egret-navy .mat-fab, .egret-navy .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.egret-navy .mat-flat-button.mat-primary, .egret-navy .mat-raised-button.mat-primary, .egret-navy .mat-fab.mat-primary, .egret-navy .mat-mini-fab.mat-primary {
  color: white;
}
.egret-navy .mat-flat-button.mat-accent, .egret-navy .mat-raised-button.mat-accent, .egret-navy .mat-fab.mat-accent, .egret-navy .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-flat-button.mat-warn, .egret-navy .mat-raised-button.mat-warn, .egret-navy .mat-fab.mat-warn, .egret-navy .mat-mini-fab.mat-warn {
  color: white;
}
.egret-navy .mat-flat-button.mat-primary.mat-button-disabled, .egret-navy .mat-flat-button.mat-accent.mat-button-disabled, .egret-navy .mat-flat-button.mat-warn.mat-button-disabled, .egret-navy .mat-flat-button.mat-button-disabled.mat-button-disabled, .egret-navy .mat-raised-button.mat-primary.mat-button-disabled, .egret-navy .mat-raised-button.mat-accent.mat-button-disabled, .egret-navy .mat-raised-button.mat-warn.mat-button-disabled, .egret-navy .mat-raised-button.mat-button-disabled.mat-button-disabled, .egret-navy .mat-fab.mat-primary.mat-button-disabled, .egret-navy .mat-fab.mat-accent.mat-button-disabled, .egret-navy .mat-fab.mat-warn.mat-button-disabled, .egret-navy .mat-fab.mat-button-disabled.mat-button-disabled, .egret-navy .mat-mini-fab.mat-primary.mat-button-disabled, .egret-navy .mat-mini-fab.mat-accent.mat-button-disabled, .egret-navy .mat-mini-fab.mat-warn.mat-button-disabled, .egret-navy .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.egret-navy .mat-flat-button.mat-primary, .egret-navy .mat-raised-button.mat-primary, .egret-navy .mat-fab.mat-primary, .egret-navy .mat-mini-fab.mat-primary {
  background-color: #0081ff;
}
.egret-navy .mat-flat-button.mat-accent, .egret-navy .mat-raised-button.mat-accent, .egret-navy .mat-fab.mat-accent, .egret-navy .mat-mini-fab.mat-accent {
  background-color: #ff8a48;
}
.egret-navy .mat-flat-button.mat-warn, .egret-navy .mat-raised-button.mat-warn, .egret-navy .mat-fab.mat-warn, .egret-navy .mat-mini-fab.mat-warn {
  background-color: #ff3d57;
}
.egret-navy .mat-flat-button.mat-primary.mat-button-disabled, .egret-navy .mat-flat-button.mat-accent.mat-button-disabled, .egret-navy .mat-flat-button.mat-warn.mat-button-disabled, .egret-navy .mat-flat-button.mat-button-disabled.mat-button-disabled, .egret-navy .mat-raised-button.mat-primary.mat-button-disabled, .egret-navy .mat-raised-button.mat-accent.mat-button-disabled, .egret-navy .mat-raised-button.mat-warn.mat-button-disabled, .egret-navy .mat-raised-button.mat-button-disabled.mat-button-disabled, .egret-navy .mat-fab.mat-primary.mat-button-disabled, .egret-navy .mat-fab.mat-accent.mat-button-disabled, .egret-navy .mat-fab.mat-warn.mat-button-disabled, .egret-navy .mat-fab.mat-button-disabled.mat-button-disabled, .egret-navy .mat-mini-fab.mat-primary.mat-button-disabled, .egret-navy .mat-mini-fab.mat-accent.mat-button-disabled, .egret-navy .mat-mini-fab.mat-warn.mat-button-disabled, .egret-navy .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(44, 44, 44, 0.12);
}
.egret-navy .mat-flat-button.mat-primary .mat-ripple-element, .egret-navy .mat-raised-button.mat-primary .mat-ripple-element, .egret-navy .mat-fab.mat-primary .mat-ripple-element, .egret-navy .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-flat-button.mat-accent .mat-ripple-element, .egret-navy .mat-raised-button.mat-accent .mat-ripple-element, .egret-navy .mat-fab.mat-accent .mat-ripple-element, .egret-navy .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.egret-navy .mat-flat-button.mat-warn .mat-ripple-element, .egret-navy .mat-raised-button.mat-warn .mat-ripple-element, .egret-navy .mat-fab.mat-warn .mat-ripple-element, .egret-navy .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-stroked-button:not([class*=mat-elevation-z]), .egret-navy .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-fab:not([class*=mat-elevation-z]), .egret-navy .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .egret-navy .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .egret-navy .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.egret-navy .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.egret-navy .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.egret-navy .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.egret-navy .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.egret-navy .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.egret-navy [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.egret-navy .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.egret-navy .mat-button-toggle-checked {
  background-color: #c7c7c7;
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #e1e1e1;
}
.egret-navy .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.egret-navy .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #aeaeae;
}
.egret-navy .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.egret-navy .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.egret-navy .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-checkbox-checkmark {
  fill: #fafafa;
}
.egret-navy .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.egret-navy .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.egret-navy .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .egret-navy .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #0081ff;
}
.egret-navy .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .egret-navy .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff8a48;
}
.egret-navy .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .egret-navy .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff3d57;
}
.egret-navy .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .egret-navy .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.egret-navy .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.egret-navy .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.egret-navy .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.egret-navy .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #0081ff;
}
.egret-navy .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.egret-navy .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff8a48;
}
.egret-navy .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.egret-navy .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff3d57;
}
.egret-navy .mat-chip.mat-standard-chip {
  background-color: #e1e1e1;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.egret-navy .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.egret-navy .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.egret-navy .mat-chip.mat-standard-chip::after {
  background: black;
}
.egret-navy .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #0081ff;
  color: white;
}
.egret-navy .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-navy .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff3d57;
  color: white;
}
.egret-navy .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-navy .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-navy .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff8a48;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.egret-navy .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.egret-navy .mat-table {
  background: white;
}
.egret-navy .mat-table thead, .egret-navy .mat-table tbody, .egret-navy .mat-table tfoot,
.egret-navy mat-header-row, .egret-navy mat-row, .egret-navy mat-footer-row,
.egret-navy [mat-header-row], .egret-navy [mat-row], .egret-navy [mat-footer-row],
.egret-navy .mat-table-sticky {
  background: inherit;
}
.egret-navy mat-row, .egret-navy mat-header-row, .egret-navy mat-footer-row,
.egret-navy th.mat-header-cell, .egret-navy td.mat-cell, .egret-navy td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-cell, .egret-navy .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-datepicker-toggle,
.egret-navy .mat-datepicker-content .mat-calendar-next-button,
.egret-navy .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-calendar-table-header,
.egret-navy .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-calendar-body-cell-content,
.egret-navy .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.egret-navy .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.egret-navy .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.egret-navy .mat-calendar-body-in-range::before {
  background: rgba(0, 129, 255, 0.2);
}
.egret-navy .mat-calendar-body-comparison-identical,
.egret-navy .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-navy .mat-calendar-body-comparison-bridge-start::before,
.egret-navy [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 129, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-navy .mat-calendar-body-comparison-bridge-end::before,
.egret-navy [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 129, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-navy .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-navy .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-navy .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-navy .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-navy .mat-calendar-body-selected {
  background-color: #0081ff;
  color: white;
}
.egret-navy .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 129, 255, 0.4);
}
.egret-navy .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.egret-navy .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-navy .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 129, 255, 0.3);
}
@media (hover: hover) {
  .egret-navy .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 129, 255, 0.3);
  }
}
.egret-navy .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 138, 72, 0.2);
}
.egret-navy .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.egret-navy .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-navy .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.egret-navy .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 138, 72, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-navy .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.egret-navy .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 138, 72, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-navy .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-navy .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-navy .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-navy .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-navy .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff8a48;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 138, 72, 0.4);
}
.egret-navy .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-navy .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 138, 72, 0.3);
}
@media (hover: hover) {
  .egret-navy .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 138, 72, 0.3);
  }
}
.egret-navy .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 61, 87, 0.2);
}
.egret-navy .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.egret-navy .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-navy .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.egret-navy .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 61, 87, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-navy .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.egret-navy .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 61, 87, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-navy .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-navy .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-navy .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-navy .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-navy .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff3d57;
  color: white;
}
.egret-navy .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 61, 87, 0.4);
}
.egret-navy .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.egret-navy .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-navy .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 61, 87, 0.3);
}
@media (hover: hover) {
  .egret-navy .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 61, 87, 0.3);
  }
}
.egret-navy .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-datepicker-toggle-active {
  color: #0081ff;
}
.egret-navy .mat-datepicker-toggle-active.mat-accent {
  color: #ff8a48;
}
.egret-navy .mat-datepicker-toggle-active.mat-warn {
  color: #ff3d57;
}
.egret-navy .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .egret-navy .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .egret-navy .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(44, 44, 44, 0.04);
}
@media (hover: none) {
  .egret-navy .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.egret-navy .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-expansion-panel-header-description,
.egret-navy .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.egret-navy .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.egret-navy .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.egret-navy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.egret-navy .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.egret-navy .mat-form-field.mat-focused .mat-form-field-label {
  color: #0081ff;
}
.egret-navy .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff8a48;
}
.egret-navy .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff3d57;
}
.egret-navy .mat-focused .mat-form-field-required-marker {
  color: #ff8a48;
}
.egret-navy .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0081ff;
}
.egret-navy .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff8a48;
}
.egret-navy .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff3d57;
}
.egret-navy .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #0081ff;
}
.egret-navy .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff8a48;
}
.egret-navy .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff3d57;
}
.egret-navy .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff3d57;
}
.egret-navy .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.egret-navy .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff3d57;
}
.egret-navy .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.egret-navy .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff3d57;
}
.egret-navy .mat-error {
  color: #ff3d57;
}
.egret-navy .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.egret-navy .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.egret-navy .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.egret-navy .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.egret-navy .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.egret-navy .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.egret-navy .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.egret-navy .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.egret-navy .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0081ff;
}
.egret-navy .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff8a48;
}
.egret-navy .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff3d57;
}
.egret-navy .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff3d57;
}
.egret-navy .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.egret-navy .mat-icon.mat-primary {
  color: #0081ff;
}
.egret-navy .mat-icon.mat-accent {
  color: #ff8a48;
}
.egret-navy .mat-icon.mat-warn {
  color: #ff3d57;
}
.egret-navy .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-input-element:disabled,
.egret-navy .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-input-element {
  caret-color: #0081ff;
}
.egret-navy .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-navy .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-navy .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-navy .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-navy .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff8a48;
}
.egret-navy .mat-form-field.mat-warn .mat-input-element,
.egret-navy .mat-form-field-invalid .mat-input-element {
  caret-color: #ff3d57;
}
.egret-navy .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff3d57;
}
.egret-navy .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-list-base .mat-list-item-disabled {
  background-color: #e1e1e1;
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-list-option:hover, .egret-navy .mat-list-option:focus,
.egret-navy .mat-nav-list .mat-list-item:hover,
.egret-navy .mat-nav-list .mat-list-item:focus,
.egret-navy .mat-action-list .mat-list-item:hover,
.egret-navy .mat-action-list .mat-list-item:focus {
  background: rgba(44, 44, 44, 0.04);
}
.egret-navy .mat-list-single-selected-option, .egret-navy .mat-list-single-selected-option:hover, .egret-navy .mat-list-single-selected-option:focus {
  background: rgba(44, 44, 44, 0.12);
}
.egret-navy .mat-menu-panel {
  background: white;
}
.egret-navy .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-menu-item[disabled],
.egret-navy .mat-menu-item[disabled] .mat-menu-submenu-icon,
.egret-navy .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-menu-item .mat-icon-no-color,
.egret-navy .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-menu-item:hover:not([disabled]),
.egret-navy .mat-menu-item.cdk-program-focused:not([disabled]),
.egret-navy .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.egret-navy .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(44, 44, 44, 0.04);
}
.egret-navy .mat-paginator {
  background: white;
}
.egret-navy .mat-paginator,
.egret-navy .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-paginator-decrement,
.egret-navy .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-paginator-first,
.egret-navy .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-icon-button[disabled] .mat-paginator-decrement,
.egret-navy .mat-icon-button[disabled] .mat-paginator-increment,
.egret-navy .mat-icon-button[disabled] .mat-paginator-first,
.egret-navy .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}

.egret-navy .mat-progress-bar-background {
  fill: #bcdcfb;
}
.egret-navy .mat-progress-bar-buffer {
  background-color: #bcdcfb;
}
.egret-navy .mat-progress-bar-fill::after {
  background-color: #0081ff;
}
.egret-navy .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbdece;
}
.egret-navy .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbdece;
}
.egret-navy .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff8a48;
}
.egret-navy .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbcbd1;
}
.egret-navy .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbcbd1;
}
.egret-navy .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff3d57;
}
.egret-navy .mat-progress-spinner circle, .egret-navy .mat-spinner circle {
  stroke: #0081ff;
}
.egret-navy .mat-progress-spinner.mat-accent circle, .egret-navy .mat-spinner.mat-accent circle {
  stroke: #ff8a48;
}
.egret-navy .mat-progress-spinner.mat-warn circle, .egret-navy .mat-spinner.mat-warn circle {
  stroke: #ff3d57;
}
.egret-navy .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0081ff;
}
.egret-navy .mat-radio-button.mat-primary .mat-radio-inner-circle,
.egret-navy .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-navy .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .egret-navy .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #0081ff;
}
.egret-navy .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff8a48;
}
.egret-navy .mat-radio-button.mat-accent .mat-radio-inner-circle,
.egret-navy .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-navy .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .egret-navy .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff8a48;
}
.egret-navy .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff3d57;
}
.egret-navy .mat-radio-button.mat-warn .mat-radio-inner-circle,
.egret-navy .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-navy .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .egret-navy .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff3d57;
}
.egret-navy .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.egret-navy .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.egret-navy .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.egret-navy .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-navy .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-select-panel {
  background: white;
}
.egret-navy .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(44, 44, 44, 0.12);
}
.egret-navy .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #0081ff;
}
.egret-navy .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff8a48;
}
.egret-navy .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff3d57;
}
.egret-navy .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff3d57;
}
.egret-navy .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-drawer.mat-drawer-push {
  background-color: white;
}
.egret-navy .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.egret-navy [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.egret-navy [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.egret-navy .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff8a48;
}
.egret-navy .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 138, 72, 0.54);
}
.egret-navy .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff8a48;
}
.egret-navy .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0081ff;
}
.egret-navy .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 129, 255, 0.54);
}
.egret-navy .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #0081ff;
}
.egret-navy .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff3d57;
}
.egret-navy .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 61, 87, 0.54);
}
.egret-navy .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff3d57;
}
.egret-navy .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.egret-navy .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.egret-navy .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.egret-navy .mat-slider.mat-primary .mat-slider-track-fill,
.egret-navy .mat-slider.mat-primary .mat-slider-thumb,
.egret-navy .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #0081ff;
}
.egret-navy .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.egret-navy .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 129, 255, 0.2);
}
.egret-navy .mat-slider.mat-accent .mat-slider-track-fill,
.egret-navy .mat-slider.mat-accent .mat-slider-thumb,
.egret-navy .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff8a48;
}
.egret-navy .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 138, 72, 0.2);
}
.egret-navy .mat-slider.mat-warn .mat-slider-track-fill,
.egret-navy .mat-slider.mat-warn .mat-slider-thumb,
.egret-navy .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff3d57;
}
.egret-navy .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.egret-navy .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 61, 87, 0.2);
}
.egret-navy .mat-slider:hover .mat-slider-track-background,
.egret-navy .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-slider.mat-slider-disabled .mat-slider-track-background,
.egret-navy .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.egret-navy .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.egret-navy .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.egret-navy .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.egret-navy .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.egret-navy .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.egret-navy .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.egret-navy .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .egret-navy .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .egret-navy .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.egret-navy .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.egret-navy .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.egret-navy .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.egret-navy .mat-step-header.cdk-keyboard-focused, .egret-navy .mat-step-header.cdk-program-focused, .egret-navy .mat-step-header:hover:not([aria-disabled]), .egret-navy .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(44, 44, 44, 0.04);
}
.egret-navy .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .egret-navy .mat-step-header:hover {
    background: none;
  }
}
.egret-navy .mat-step-header .mat-step-label,
.egret-navy .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.egret-navy .mat-step-header .mat-step-icon-selected,
.egret-navy .mat-step-header .mat-step-icon-state-done,
.egret-navy .mat-step-header .mat-step-icon-state-edit {
  background-color: #0081ff;
  color: white;
}
.egret-navy .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-step-header.mat-accent .mat-step-icon-selected,
.egret-navy .mat-step-header.mat-accent .mat-step-icon-state-done,
.egret-navy .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff8a48;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.egret-navy .mat-step-header.mat-warn .mat-step-icon-selected,
.egret-navy .mat-step-header.mat-warn .mat-step-icon-state-done,
.egret-navy .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff3d57;
  color: white;
}
.egret-navy .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff3d57;
}
.egret-navy .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff3d57;
}
.egret-navy .mat-stepper-horizontal, .egret-navy .mat-stepper-vertical {
  background-color: white;
}
.egret-navy .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-horizontal-stepper-header::before,
.egret-navy .mat-horizontal-stepper-header::after,
.egret-navy .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.egret-navy .mat-sort-header-arrow {
  color: #757575;
}
.egret-navy .mat-tab-nav-bar,
.egret-navy .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-tab-group-inverted-header .mat-tab-nav-bar,
.egret-navy .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.egret-navy .mat-tab-label, .egret-navy .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-tab-label.mat-tab-disabled, .egret-navy .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.egret-navy .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.egret-navy .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.egret-navy .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 217, 255, 0.3);
}
.egret-navy .mat-tab-group.mat-primary .mat-ink-bar, .egret-navy .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0081ff;
}
.egret-navy .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .egret-navy .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .egret-navy .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .egret-navy .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.egret-navy .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 220, 200, 0.3);
}
.egret-navy .mat-tab-group.mat-accent .mat-ink-bar, .egret-navy .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff8a48;
}
.egret-navy .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .egret-navy .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .egret-navy .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .egret-navy .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 197, 205, 0.3);
}
.egret-navy .mat-tab-group.mat-warn .mat-ink-bar, .egret-navy .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff3d57;
}
.egret-navy .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .egret-navy .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .egret-navy .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .egret-navy .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.egret-navy .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 217, 255, 0.3);
}
.egret-navy .mat-tab-group.mat-background-primary > .mat-tab-header, .egret-navy .mat-tab-group.mat-background-primary > .mat-tab-link-container, .egret-navy .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #0081ff;
}
.egret-navy .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .egret-navy .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.egret-navy .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .egret-navy .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.egret-navy .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.egret-navy .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.egret-navy .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.egret-navy .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.egret-navy .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.egret-navy .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.egret-navy .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.egret-navy .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 220, 200, 0.3);
}
.egret-navy .mat-tab-group.mat-background-accent > .mat-tab-header, .egret-navy .mat-tab-group.mat-background-accent > .mat-tab-link-container, .egret-navy .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff8a48;
}
.egret-navy .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .egret-navy .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .egret-navy .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.egret-navy .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.egret-navy .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.egret-navy .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.egret-navy .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.egret-navy .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.egret-navy .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.egret-navy .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 197, 205, 0.3);
}
.egret-navy .mat-tab-group.mat-background-warn > .mat-tab-header, .egret-navy .mat-tab-group.mat-background-warn > .mat-tab-link-container, .egret-navy .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff3d57;
}
.egret-navy .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .egret-navy .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.egret-navy .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .egret-navy .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.egret-navy .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.egret-navy .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.egret-navy .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.egret-navy .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.egret-navy .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.egret-navy .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.egret-navy .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.egret-navy .mat-toolbar {
  background: #ededed;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-toolbar.mat-primary {
  background: #0081ff;
  color: white;
}
.egret-navy .mat-toolbar.mat-accent {
  background: #ff8a48;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy .mat-toolbar.mat-warn {
  background: #ff3d57;
  color: white;
}
.egret-navy .mat-toolbar .mat-form-field-underline,
.egret-navy .mat-toolbar .mat-form-field-ripple,
.egret-navy .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.egret-navy .mat-toolbar .mat-form-field-label,
.egret-navy .mat-toolbar .mat-focused .mat-form-field-label,
.egret-navy .mat-toolbar .mat-select-value,
.egret-navy .mat-toolbar .mat-select-arrow,
.egret-navy .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.egret-navy .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.egret-navy .mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}
.egret-navy .mat-tree {
  background: white;
}
.egret-navy .mat-tree-node,
.egret-navy .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}

.egret-navy .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy .mat-simple-snackbar-action {
  color: #ff8a48;
}
.egret-navy .text-muted, .egret-navy .mat-heading-label {
  color: rgba(0, 0, 0, 0.54);
}
.egret-navy .text-primary {
  color: #0081ff;
}
.egret-navy .text-accent {
  color: #ff8a48;
}
.egret-navy .text-warn {
  color: #ff3d57;
}
.egret-navy .mat-bg-primary {
  background-color: #0081ff;
}
.egret-navy .mat-bg-accent {
  background-color: #ff8a48;
}
.egret-navy .mat-bg-warn {
  background-color: #ff3d57;
}
.egret-navy .mat-bg-chip {
  background-color: #e1e1e1;
}
.egret-navy .mat-bg-card {
  background-color: white;
}
.egret-navy .mat-bg-default {
  background-color: #fafafa;
}
.egret-navy .cal-month-view {
  background-color: white !important;
}
.egret-navy .cal-month-view .cal-day-cell:not(:last-child),
.egret-navy .cal-month-view .cal-days .cal-cell-row,
.egret-navy .cal-month-view .cal-days {
  border-color: rgba(0, 0, 0, 0.12) !important;
}
.egret-navy .cal-month-view .cal-cell-row .cal-cell:hover,
.egret-navy .cal-month-view .cal-cell.cal-has-events.cal-open,
.egret-navy .cal-month-view .cal-cell-row:hover,
.egret-navy .cal-month-view .cal-day-cell.cal-today {
  background-color: #fafafa;
}
.egret-navy div.hopscotch-bubble {
  background-color: white !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.egret-navy div.hopscotch-bubble .hopscotch-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-number {
  background-image: none !important;
  background-color: #0081ff;
}
.egret-navy .hopscotch-next {
  background-color: #0081ff !important;
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom: 17px solid rgba(0, 0, 0, 0.12);
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom: 17px solid white;
  top: -16px;
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left: 17px solid rgba(0, 0, 0, 0.12);
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
  border-left: 17px solid white;
  left: -1px;
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top: 17px solid rgba(0, 0, 0, 0.12);
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top: 17px solid white;
  top: -18px;
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right: 17px solid rgba(0, 0, 0, 0.12);
}
.egret-navy div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
  left: 1px;
  border-right: 17px solid white;
}
.egret-navy .header-topnav, .egret-navy .topnav ul ul {
  background-color: white;
}
.egret-navy .topnav ul li ul li:hover, .egret-navy .topnav ul li ul li.open {
  background: #fafafa;
}
.egret-navy .header-topnav a {
  color: rgba(0, 0, 0, 0.87);
}

.egret-navy-dark {
  color: #ffffff;
  /* up arrow  */
  /* right arrow */
  /* bottom arrow */
  /* Left Arrow */
}
.egret-navy-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-option {
  color: white;
}
.egret-navy-dark .mat-option:hover:not(.mat-option-disabled), .egret-navy-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(250, 250, 250, 0.04);
}
.egret-navy-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(250, 250, 250, 0.04);
}
.egret-navy-dark .mat-option.mat-active {
  background: rgba(250, 250, 250, 0.04);
  color: white;
}
.egret-navy-dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0081ff;
}
.egret-navy-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff8a48;
}
.egret-navy-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff3d57;
}
.egret-navy-dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-pseudo-checkbox::after {
  color: #1a2038;
}
.egret-navy-dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.egret-navy-dark .mat-primary .mat-pseudo-checkbox-checked,
.egret-navy-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0081ff;
}
.egret-navy-dark .mat-pseudo-checkbox-checked,
.egret-navy-dark .mat-pseudo-checkbox-indeterminate,
.egret-navy-dark .mat-accent .mat-pseudo-checkbox-checked,
.egret-navy-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff8a48;
}
.egret-navy-dark .mat-warn .mat-pseudo-checkbox-checked,
.egret-navy-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff3d57;
}
.egret-navy-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.egret-navy-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.egret-navy-dark .mat-app-background, .egret-navy-dark.mat-app-background {
  background-color: #1a2038;
  color: white;
}
.egret-navy-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.egret-navy-dark .mat-autocomplete-panel {
  background: #222a49;
  color: white;
}
.egret-navy-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #222a49;
}
.egret-navy-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.egret-navy-dark .mat-badge {
  position: relative;
}
.egret-navy-dark .mat-badge.mat-badge {
  overflow: visible;
}
.egret-navy-dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.egret-navy-dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.egret-navy-dark .ng-animate-disabled .mat-badge-content,
.egret-navy-dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.egret-navy-dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.egret-navy-dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.egret-navy-dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.egret-navy-dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.egret-navy-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .egret-navy-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.egret-navy-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .egret-navy-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.egret-navy-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .egret-navy-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.egret-navy-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .egret-navy-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.egret-navy-dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.egret-navy-dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.egret-navy-dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.egret-navy-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .egret-navy-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.egret-navy-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .egret-navy-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.egret-navy-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .egret-navy-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.egret-navy-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .egret-navy-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.egret-navy-dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.egret-navy-dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.egret-navy-dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.egret-navy-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .egret-navy-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.egret-navy-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .egret-navy-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.egret-navy-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .egret-navy-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.egret-navy-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .egret-navy-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.egret-navy-dark .mat-badge-content {
  color: white;
  background: #0081ff;
}
.cdk-high-contrast-active .egret-navy-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.egret-navy-dark .mat-badge-accent .mat-badge-content {
  background: #ff8a48;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff3d57;
}
.egret-navy-dark .mat-badge-disabled .mat-badge-content {
  background: #5f6374;
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #222a49;
  color: white;
}
.egret-navy-dark .mat-button, .egret-navy-dark .mat-icon-button, .egret-navy-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.egret-navy-dark .mat-button.mat-primary, .egret-navy-dark .mat-icon-button.mat-primary, .egret-navy-dark .mat-stroked-button.mat-primary {
  color: #0081ff;
}
.egret-navy-dark .mat-button.mat-accent, .egret-navy-dark .mat-icon-button.mat-accent, .egret-navy-dark .mat-stroked-button.mat-accent {
  color: #ff8a48;
}
.egret-navy-dark .mat-button.mat-warn, .egret-navy-dark .mat-icon-button.mat-warn, .egret-navy-dark .mat-stroked-button.mat-warn {
  color: #ff3d57;
}
.egret-navy-dark .mat-button.mat-primary.mat-button-disabled, .egret-navy-dark .mat-button.mat-accent.mat-button-disabled, .egret-navy-dark .mat-button.mat-warn.mat-button-disabled, .egret-navy-dark .mat-button.mat-button-disabled.mat-button-disabled, .egret-navy-dark .mat-icon-button.mat-primary.mat-button-disabled, .egret-navy-dark .mat-icon-button.mat-accent.mat-button-disabled, .egret-navy-dark .mat-icon-button.mat-warn.mat-button-disabled, .egret-navy-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .egret-navy-dark .mat-stroked-button.mat-primary.mat-button-disabled, .egret-navy-dark .mat-stroked-button.mat-accent.mat-button-disabled, .egret-navy-dark .mat-stroked-button.mat-warn.mat-button-disabled, .egret-navy-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.egret-navy-dark .mat-button.mat-primary .mat-button-focus-overlay, .egret-navy-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .egret-navy-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #0081ff;
}
.egret-navy-dark .mat-button.mat-accent .mat-button-focus-overlay, .egret-navy-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .egret-navy-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff8a48;
}
.egret-navy-dark .mat-button.mat-warn .mat-button-focus-overlay, .egret-navy-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .egret-navy-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff3d57;
}
.egret-navy-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .egret-navy-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .egret-navy-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.egret-navy-dark .mat-button .mat-ripple-element, .egret-navy-dark .mat-icon-button .mat-ripple-element, .egret-navy-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.egret-navy-dark .mat-button-focus-overlay {
  background: #ffffff;
}
.egret-navy-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.egret-navy-dark .mat-flat-button, .egret-navy-dark .mat-raised-button, .egret-navy-dark .mat-fab, .egret-navy-dark .mat-mini-fab {
  color: white;
  background-color: #222a49;
}
.egret-navy-dark .mat-flat-button.mat-primary, .egret-navy-dark .mat-raised-button.mat-primary, .egret-navy-dark .mat-fab.mat-primary, .egret-navy-dark .mat-mini-fab.mat-primary {
  color: white;
}
.egret-navy-dark .mat-flat-button.mat-accent, .egret-navy-dark .mat-raised-button.mat-accent, .egret-navy-dark .mat-fab.mat-accent, .egret-navy-dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy-dark .mat-flat-button.mat-warn, .egret-navy-dark .mat-raised-button.mat-warn, .egret-navy-dark .mat-fab.mat-warn, .egret-navy-dark .mat-mini-fab.mat-warn {
  color: white;
}
.egret-navy-dark .mat-flat-button.mat-primary.mat-button-disabled, .egret-navy-dark .mat-flat-button.mat-accent.mat-button-disabled, .egret-navy-dark .mat-flat-button.mat-warn.mat-button-disabled, .egret-navy-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .egret-navy-dark .mat-raised-button.mat-primary.mat-button-disabled, .egret-navy-dark .mat-raised-button.mat-accent.mat-button-disabled, .egret-navy-dark .mat-raised-button.mat-warn.mat-button-disabled, .egret-navy-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .egret-navy-dark .mat-fab.mat-primary.mat-button-disabled, .egret-navy-dark .mat-fab.mat-accent.mat-button-disabled, .egret-navy-dark .mat-fab.mat-warn.mat-button-disabled, .egret-navy-dark .mat-fab.mat-button-disabled.mat-button-disabled, .egret-navy-dark .mat-mini-fab.mat-primary.mat-button-disabled, .egret-navy-dark .mat-mini-fab.mat-accent.mat-button-disabled, .egret-navy-dark .mat-mini-fab.mat-warn.mat-button-disabled, .egret-navy-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.egret-navy-dark .mat-flat-button.mat-primary, .egret-navy-dark .mat-raised-button.mat-primary, .egret-navy-dark .mat-fab.mat-primary, .egret-navy-dark .mat-mini-fab.mat-primary {
  background-color: #0081ff;
}
.egret-navy-dark .mat-flat-button.mat-accent, .egret-navy-dark .mat-raised-button.mat-accent, .egret-navy-dark .mat-fab.mat-accent, .egret-navy-dark .mat-mini-fab.mat-accent {
  background-color: #ff8a48;
}
.egret-navy-dark .mat-flat-button.mat-warn, .egret-navy-dark .mat-raised-button.mat-warn, .egret-navy-dark .mat-fab.mat-warn, .egret-navy-dark .mat-mini-fab.mat-warn {
  background-color: #ff3d57;
}
.egret-navy-dark .mat-flat-button.mat-primary.mat-button-disabled, .egret-navy-dark .mat-flat-button.mat-accent.mat-button-disabled, .egret-navy-dark .mat-flat-button.mat-warn.mat-button-disabled, .egret-navy-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .egret-navy-dark .mat-raised-button.mat-primary.mat-button-disabled, .egret-navy-dark .mat-raised-button.mat-accent.mat-button-disabled, .egret-navy-dark .mat-raised-button.mat-warn.mat-button-disabled, .egret-navy-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .egret-navy-dark .mat-fab.mat-primary.mat-button-disabled, .egret-navy-dark .mat-fab.mat-accent.mat-button-disabled, .egret-navy-dark .mat-fab.mat-warn.mat-button-disabled, .egret-navy-dark .mat-fab.mat-button-disabled.mat-button-disabled, .egret-navy-dark .mat-mini-fab.mat-primary.mat-button-disabled, .egret-navy-dark .mat-mini-fab.mat-accent.mat-button-disabled, .egret-navy-dark .mat-mini-fab.mat-warn.mat-button-disabled, .egret-navy-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(250, 250, 250, 0.12);
}
.egret-navy-dark .mat-flat-button.mat-primary .mat-ripple-element, .egret-navy-dark .mat-raised-button.mat-primary .mat-ripple-element, .egret-navy-dark .mat-fab.mat-primary .mat-ripple-element, .egret-navy-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-flat-button.mat-accent .mat-ripple-element, .egret-navy-dark .mat-raised-button.mat-accent .mat-ripple-element, .egret-navy-dark .mat-fab.mat-accent .mat-ripple-element, .egret-navy-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.egret-navy-dark .mat-flat-button.mat-warn .mat-ripple-element, .egret-navy-dark .mat-raised-button.mat-warn .mat-ripple-element, .egret-navy-dark .mat-fab.mat-warn .mat-ripple-element, .egret-navy-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-stroked-button:not([class*=mat-elevation-z]), .egret-navy-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-fab:not([class*=mat-elevation-z]), .egret-navy-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .egret-navy-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .egret-navy-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.egret-navy-dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.egret-navy-dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.egret-navy-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-navy-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #222a49;
}
.egret-navy-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.egret-navy-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #3d445f;
}
.egret-navy-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #3d445f;
}
.egret-navy-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #3d445f;
}
.egret-navy-dark .mat-button-toggle-checked {
  background-color: #3a487e;
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.egret-navy-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: #2a345b;
}
.egret-navy-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #222a49;
}
.egret-navy-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #4b5ca0;
}
.egret-navy-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.egret-navy-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #3d445f;
}
.egret-navy-dark .mat-card {
  background: #222a49;
  color: white;
}
.egret-navy-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-checkbox-checkmark {
  fill: #1a2038;
}
.egret-navy-dark .mat-checkbox-checkmark-path {
  stroke: #1a2038 !important;
}
.egret-navy-dark .mat-checkbox-mixedmark {
  background-color: #1a2038;
}
.egret-navy-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .egret-navy-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #0081ff;
}
.egret-navy-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .egret-navy-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff8a48;
}
.egret-navy-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .egret-navy-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff3d57;
}
.egret-navy-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .egret-navy-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.egret-navy-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.egret-navy-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-checkbox .mat-ripple-element {
  background-color: #ffffff;
}
.egret-navy-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.egret-navy-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #0081ff;
}
.egret-navy-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.egret-navy-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff8a48;
}
.egret-navy-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.egret-navy-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff3d57;
}
.egret-navy-dark .mat-chip.mat-standard-chip {
  background-color: #3a487e;
  color: white;
}
.egret-navy-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-navy-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.egret-navy-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.egret-navy-dark .mat-chip.mat-standard-chip::after {
  background: #ffffff;
}
.egret-navy-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #0081ff;
  color: white;
}
.egret-navy-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-navy-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff3d57;
  color: white;
}
.egret-navy-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-navy-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff8a48;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.egret-navy-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.egret-navy-dark .mat-table {
  background: #222a49;
}
.egret-navy-dark .mat-table thead, .egret-navy-dark .mat-table tbody, .egret-navy-dark .mat-table tfoot,
.egret-navy-dark mat-header-row, .egret-navy-dark mat-row, .egret-navy-dark mat-footer-row,
.egret-navy-dark [mat-header-row], .egret-navy-dark [mat-row], .egret-navy-dark [mat-footer-row],
.egret-navy-dark .mat-table-sticky {
  background: inherit;
}
.egret-navy-dark mat-row, .egret-navy-dark mat-header-row, .egret-navy-dark mat-footer-row,
.egret-navy-dark th.mat-header-cell, .egret-navy-dark td.mat-cell, .egret-navy-dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.egret-navy-dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-cell, .egret-navy-dark .mat-footer-cell {
  color: white;
}
.egret-navy-dark .mat-calendar-arrow {
  fill: white;
}
.egret-navy-dark .mat-datepicker-toggle,
.egret-navy-dark .mat-datepicker-content .mat-calendar-next-button,
.egret-navy-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.egret-navy-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.egret-navy-dark .mat-calendar-table-header,
.egret-navy-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-calendar-body-cell-content,
.egret-navy-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.egret-navy-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.egret-navy-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.egret-navy-dark .mat-calendar-body-in-range::before {
  background: rgba(0, 129, 255, 0.2);
}
.egret-navy-dark .mat-calendar-body-comparison-identical,
.egret-navy-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-navy-dark .mat-calendar-body-comparison-bridge-start::before,
.egret-navy-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 129, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-navy-dark .mat-calendar-body-comparison-bridge-end::before,
.egret-navy-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 129, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-navy-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-navy-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-navy-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-navy-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-navy-dark .mat-calendar-body-selected {
  background-color: #0081ff;
  color: white;
}
.egret-navy-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 129, 255, 0.4);
}
.egret-navy-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.egret-navy-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-navy-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 129, 255, 0.3);
}
@media (hover: hover) {
  .egret-navy-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 129, 255, 0.3);
  }
}
.egret-navy-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #222a49;
  color: white;
}
.egret-navy-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 138, 72, 0.2);
}
.egret-navy-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.egret-navy-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-navy-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.egret-navy-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 138, 72, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-navy-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.egret-navy-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 138, 72, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-navy-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-navy-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-navy-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-navy-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-navy-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff8a48;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 138, 72, 0.4);
}
.egret-navy-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.egret-navy-dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-navy-dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 138, 72, 0.3);
}
@media (hover: hover) {
  .egret-navy-dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 138, 72, 0.3);
  }
}
.egret-navy-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 61, 87, 0.2);
}
.egret-navy-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.egret-navy-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-navy-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.egret-navy-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 61, 87, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-navy-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.egret-navy-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 61, 87, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-navy-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-navy-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-navy-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-navy-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-navy-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff3d57;
  color: white;
}
.egret-navy-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 61, 87, 0.4);
}
.egret-navy-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.egret-navy-dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-navy-dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 61, 87, 0.3);
}
@media (hover: hover) {
  .egret-navy-dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 61, 87, 0.3);
  }
}
.egret-navy-dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-datepicker-toggle-active {
  color: #0081ff;
}
.egret-navy-dark .mat-datepicker-toggle-active.mat-accent {
  color: #ff8a48;
}
.egret-navy-dark .mat-datepicker-toggle-active.mat-warn {
  color: #ff3d57;
}
.egret-navy-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #222a49;
  color: white;
}
.egret-navy-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.egret-navy-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.egret-navy-dark .mat-expansion-panel {
  background: #222a49;
  color: white;
}
.egret-navy-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.egret-navy-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .egret-navy-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .egret-navy-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(250, 250, 250, 0.04);
}
@media (hover: none) {
  .egret-navy-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #222a49;
  }
}
.egret-navy-dark .mat-expansion-panel-header-title {
  color: white;
}
.egret-navy-dark .mat-expansion-panel-header-description,
.egret-navy-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.egret-navy-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.egret-navy-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.egret-navy-dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #0081ff;
}
.egret-navy-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff8a48;
}
.egret-navy-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff3d57;
}
.egret-navy-dark .mat-focused .mat-form-field-required-marker {
  color: #ff8a48;
}
.egret-navy-dark .mat-form-field-ripple {
  background-color: white;
}
.egret-navy-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0081ff;
}
.egret-navy-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff8a48;
}
.egret-navy-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff3d57;
}
.egret-navy-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #0081ff;
}
.egret-navy-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff8a48;
}
.egret-navy-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff3d57;
}
.egret-navy-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff3d57;
}
.egret-navy-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.egret-navy-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff3d57;
}
.egret-navy-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.egret-navy-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff3d57;
}
.egret-navy-dark .mat-error {
  color: #ff3d57;
}
.egret-navy-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.egret-navy-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.egret-navy-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-navy-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.egret-navy-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.egret-navy-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.egret-navy-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.egret-navy-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0081ff;
}
.egret-navy-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff8a48;
}
.egret-navy-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff3d57;
}
.egret-navy-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff3d57;
}
.egret-navy-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.egret-navy-dark .mat-icon.mat-primary {
  color: #0081ff;
}
.egret-navy-dark .mat-icon.mat-accent {
  color: #ff8a48;
}
.egret-navy-dark .mat-icon.mat-warn {
  color: #ff3d57;
}
.egret-navy-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-input-element:disabled,
.egret-navy-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-input-element {
  caret-color: #0081ff;
}
.egret-navy-dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy-dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.egret-navy-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff8a48;
}
.egret-navy-dark .mat-form-field.mat-warn .mat-input-element,
.egret-navy-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #ff3d57;
}
.egret-navy-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff3d57;
}
.egret-navy-dark .mat-list-base .mat-list-item {
  color: white;
}
.egret-navy-dark .mat-list-base .mat-list-option {
  color: white;
}
.egret-navy-dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-list-base .mat-list-item-disabled {
  background-color: #2a345b;
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-list-option:hover, .egret-navy-dark .mat-list-option:focus,
.egret-navy-dark .mat-nav-list .mat-list-item:hover,
.egret-navy-dark .mat-nav-list .mat-list-item:focus,
.egret-navy-dark .mat-action-list .mat-list-item:hover,
.egret-navy-dark .mat-action-list .mat-list-item:focus {
  background: rgba(250, 250, 250, 0.04);
}
.egret-navy-dark .mat-list-single-selected-option, .egret-navy-dark .mat-list-single-selected-option:hover, .egret-navy-dark .mat-list-single-selected-option:focus {
  background: rgba(250, 250, 250, 0.12);
}
.egret-navy-dark .mat-menu-panel {
  background: #222a49;
}
.egret-navy-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-menu-item {
  background: transparent;
  color: white;
}
.egret-navy-dark .mat-menu-item[disabled],
.egret-navy-dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.egret-navy-dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-menu-item .mat-icon-no-color,
.egret-navy-dark .mat-menu-submenu-icon {
  color: white;
}
.egret-navy-dark .mat-menu-item:hover:not([disabled]),
.egret-navy-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.egret-navy-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.egret-navy-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(250, 250, 250, 0.04);
}
.egret-navy-dark .mat-paginator {
  background: #222a49;
}
.egret-navy-dark .mat-paginator,
.egret-navy-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-paginator-decrement,
.egret-navy-dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.egret-navy-dark .mat-paginator-first,
.egret-navy-dark .mat-paginator-last {
  border-top: 2px solid white;
}
.egret-navy-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.egret-navy-dark .mat-icon-button[disabled] .mat-paginator-increment,
.egret-navy-dark .mat-icon-button[disabled] .mat-paginator-first,
.egret-navy-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-progress-bar-background {
  fill: #14386a;
}
.egret-navy-dark .mat-progress-bar-buffer {
  background-color: #14386a;
}
.egret-navy-dark .mat-progress-bar-fill::after {
  background-color: #0081ff;
}
.egret-navy-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #533b3c;
}
.egret-navy-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #533b3c;
}
.egret-navy-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff8a48;
}
.egret-navy-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #532740;
}
.egret-navy-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #532740;
}
.egret-navy-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff3d57;
}
.egret-navy-dark .mat-progress-spinner circle, .egret-navy-dark .mat-spinner circle {
  stroke: #0081ff;
}
.egret-navy-dark .mat-progress-spinner.mat-accent circle, .egret-navy-dark .mat-spinner.mat-accent circle {
  stroke: #ff8a48;
}
.egret-navy-dark .mat-progress-spinner.mat-warn circle, .egret-navy-dark .mat-spinner.mat-warn circle {
  stroke: #ff3d57;
}
.egret-navy-dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0081ff;
}
.egret-navy-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.egret-navy-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-navy-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .egret-navy-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #0081ff;
}
.egret-navy-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff8a48;
}
.egret-navy-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.egret-navy-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-navy-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .egret-navy-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff8a48;
}
.egret-navy-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff3d57;
}
.egret-navy-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.egret-navy-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-navy-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .egret-navy-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff3d57;
}
.egret-navy-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.egret-navy-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.egret-navy-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-radio-button .mat-ripple-element {
  background-color: #ffffff;
}
.egret-navy-dark .mat-select-value {
  color: white;
}
.egret-navy-dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-select-panel {
  background: #222a49;
}
.egret-navy-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(250, 250, 250, 0.12);
}
.egret-navy-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #0081ff;
}
.egret-navy-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff8a48;
}
.egret-navy-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff3d57;
}
.egret-navy-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff3d57;
}
.egret-navy-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-drawer-container {
  background-color: #1a2038;
  color: white;
}
.egret-navy-dark .mat-drawer {
  background-color: #222a49;
  color: white;
}
.egret-navy-dark .mat-drawer.mat-drawer-push {
  background-color: #222a49;
}
.egret-navy-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-navy-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.egret-navy-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.egret-navy-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-navy-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(221, 213, 182, 0.6);
}
.egret-navy-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff8a48;
}
.egret-navy-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 138, 72, 0.54);
}
.egret-navy-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff8a48;
}
.egret-navy-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0081ff;
}
.egret-navy-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 129, 255, 0.54);
}
.egret-navy-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #0081ff;
}
.egret-navy-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff3d57;
}
.egret-navy-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 61, 87, 0.54);
}
.egret-navy-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff3d57;
}
.egret-navy-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.egret-navy-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.egret-navy-dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-navy-dark .mat-slider.mat-primary .mat-slider-track-fill,
.egret-navy-dark .mat-slider.mat-primary .mat-slider-thumb,
.egret-navy-dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #0081ff;
}
.egret-navy-dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.egret-navy-dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 129, 255, 0.2);
}
.egret-navy-dark .mat-slider.mat-accent .mat-slider-track-fill,
.egret-navy-dark .mat-slider.mat-accent .mat-slider-thumb,
.egret-navy-dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff8a48;
}
.egret-navy-dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy-dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 138, 72, 0.2);
}
.egret-navy-dark .mat-slider.mat-warn .mat-slider-track-fill,
.egret-navy-dark .mat-slider.mat-warn .mat-slider-thumb,
.egret-navy-dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff3d57;
}
.egret-navy-dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.egret-navy-dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 61, 87, 0.2);
}
.egret-navy-dark .mat-slider:hover .mat-slider-track-background,
.egret-navy-dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-navy-dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.egret-navy-dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.egret-navy-dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-navy-dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-navy-dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-navy-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.egret-navy-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.egret-navy-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.egret-navy-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-navy-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.egret-navy-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .egret-navy-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.egret-navy-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .egret-navy-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.egret-navy-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.egret-navy-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.egret-navy-dark .mat-step-header.cdk-keyboard-focused, .egret-navy-dark .mat-step-header.cdk-program-focused, .egret-navy-dark .mat-step-header:hover:not([aria-disabled]), .egret-navy-dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(250, 250, 250, 0.04);
}
.egret-navy-dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .egret-navy-dark .mat-step-header:hover {
    background: none;
  }
}
.egret-navy-dark .mat-step-header .mat-step-label,
.egret-navy-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.egret-navy-dark .mat-step-header .mat-step-icon-selected,
.egret-navy-dark .mat-step-header .mat-step-icon-state-done,
.egret-navy-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #0081ff;
  color: white;
}
.egret-navy-dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy-dark .mat-step-header.mat-accent .mat-step-icon-selected,
.egret-navy-dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.egret-navy-dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff8a48;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy-dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.egret-navy-dark .mat-step-header.mat-warn .mat-step-icon-selected,
.egret-navy-dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.egret-navy-dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff3d57;
  color: white;
}
.egret-navy-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff3d57;
}
.egret-navy-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.egret-navy-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff3d57;
}
.egret-navy-dark .mat-stepper-horizontal, .egret-navy-dark .mat-stepper-vertical {
  background-color: #222a49;
}
.egret-navy-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.egret-navy-dark .mat-horizontal-stepper-header::before,
.egret-navy-dark .mat-horizontal-stepper-header::after,
.egret-navy-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.egret-navy-dark .mat-sort-header-arrow {
  color: #bdbfc8;
}
.egret-navy-dark .mat-tab-nav-bar,
.egret-navy-dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.egret-navy-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.egret-navy-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.egret-navy-dark .mat-tab-label, .egret-navy-dark .mat-tab-link {
  color: white;
}
.egret-navy-dark .mat-tab-label.mat-tab-disabled, .egret-navy-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.egret-navy-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.egret-navy-dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.egret-navy-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.egret-navy-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 217, 255, 0.3);
}
.egret-navy-dark .mat-tab-group.mat-primary .mat-ink-bar, .egret-navy-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0081ff;
}
.egret-navy-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .egret-navy-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .egret-navy-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .egret-navy-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.egret-navy-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 220, 200, 0.3);
}
.egret-navy-dark .mat-tab-group.mat-accent .mat-ink-bar, .egret-navy-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff8a48;
}
.egret-navy-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .egret-navy-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .egret-navy-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .egret-navy-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.egret-navy-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 197, 205, 0.3);
}
.egret-navy-dark .mat-tab-group.mat-warn .mat-ink-bar, .egret-navy-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff3d57;
}
.egret-navy-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .egret-navy-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .egret-navy-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .egret-navy-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.egret-navy-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 217, 255, 0.3);
}
.egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-header, .egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #0081ff;
}
.egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.egret-navy-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.egret-navy-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.egret-navy-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 220, 200, 0.3);
}
.egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-header, .egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff8a48;
}
.egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.egret-navy-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.egret-navy-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.egret-navy-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-navy-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-navy-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 197, 205, 0.3);
}
.egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-header, .egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff3d57;
}
.egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.egret-navy-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.egret-navy-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.egret-navy-dark .mat-toolbar {
  background: #222a49;
  color: white;
}
.egret-navy-dark .mat-toolbar.mat-primary {
  background: #0081ff;
  color: white;
}
.egret-navy-dark .mat-toolbar.mat-accent {
  background: #ff8a48;
  color: rgba(0, 0, 0, 0.87);
}
.egret-navy-dark .mat-toolbar.mat-warn {
  background: #ff3d57;
  color: white;
}
.egret-navy-dark .mat-toolbar .mat-form-field-underline,
.egret-navy-dark .mat-toolbar .mat-form-field-ripple,
.egret-navy-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.egret-navy-dark .mat-toolbar .mat-form-field-label,
.egret-navy-dark .mat-toolbar .mat-focused .mat-form-field-label,
.egret-navy-dark .mat-toolbar .mat-select-value,
.egret-navy-dark .mat-toolbar .mat-select-arrow,
.egret-navy-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.egret-navy-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.egret-navy-dark .mat-tooltip {
  background: rgba(58, 72, 126, 0.9);
}
.egret-navy-dark .mat-tree {
  background: #222a49;
}
.egret-navy-dark .mat-tree-node,
.egret-navy-dark .mat-nested-tree-node {
  color: white;
}
.egret-navy-dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-navy-dark .mat-simple-snackbar-action {
  color: inherit;
}
.egret-navy-dark .text-muted, .egret-navy-dark .mat-heading-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-navy-dark .text-primary {
  color: #0081ff;
}
.egret-navy-dark .text-accent {
  color: #ff8a48;
}
.egret-navy-dark .text-warn {
  color: #ff3d57;
}
.egret-navy-dark .mat-bg-primary {
  background-color: #0081ff;
}
.egret-navy-dark .mat-bg-accent {
  background-color: #ff8a48;
}
.egret-navy-dark .mat-bg-warn {
  background-color: #ff3d57;
}
.egret-navy-dark .mat-bg-chip {
  background-color: #3a487e;
}
.egret-navy-dark .mat-bg-card {
  background-color: #222a49;
}
.egret-navy-dark .mat-bg-default {
  background-color: #1a2038;
}
.egret-navy-dark .cal-month-view {
  background-color: #222a49 !important;
}
.egret-navy-dark .cal-month-view .cal-day-cell:not(:last-child),
.egret-navy-dark .cal-month-view .cal-days .cal-cell-row,
.egret-navy-dark .cal-month-view .cal-days {
  border-color: rgba(255, 255, 255, 0.12) !important;
}
.egret-navy-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.egret-navy-dark .cal-month-view .cal-cell.cal-has-events.cal-open,
.egret-navy-dark .cal-month-view .cal-cell-row:hover,
.egret-navy-dark .cal-month-view .cal-day-cell.cal-today {
  background-color: #1a2038;
}
.egret-navy-dark div.hopscotch-bubble {
  background-color: #222a49 !important;
  border-color: rgba(255, 255, 255, 0.12) !important;
  color: #ffffff !important;
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-title {
  color: #ffffff !important;
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-number {
  background-image: none !important;
  background-color: #0081ff;
}
.egret-navy-dark .hopscotch-next {
  background-color: #0081ff !important;
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom: 17px solid rgba(255, 255, 255, 0.12);
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom: 17px solid #222a49;
  top: -16px;
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left: 17px solid rgba(255, 255, 255, 0.12);
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
  border-left: 17px solid #222a49;
  left: -1px;
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top: 17px solid rgba(255, 255, 255, 0.12);
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top: 17px solid #222a49;
  top: -18px;
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right: 17px solid rgba(255, 255, 255, 0.12);
}
.egret-navy-dark div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
  left: 1px;
  border-right: 17px solid #222a49;
}
.egret-navy-dark .header-topnav, .egret-navy-dark .topnav ul ul {
  background-color: #222a49;
}
.egret-navy-dark .topnav ul li ul li:hover, .egret-navy-dark .topnav ul li ul li.open {
  background: #1a2038;
}
.egret-navy-dark .header-topnav a {
  color: #ffffff;
}

.avatar-xs {
  width: 24px;
  height: 24px;
}

.avatar-sm {
  width: 36px;
  height: 36px;
}

.avatar-md {
  width: 54px;
  height: 54px;
}

.avatar-lg {
  width: 80px;
  height: 80px;
}

.h-0 {
  height: 0px;
}

.w-0 {
  width: 0px;
}

.h-20 {
  height: 20px;
}

.w-20 {
  width: 20px;
}

.h-32 {
  height: 32px;
}

.w-32 {
  width: 32px;
}

.h-40 {
  height: 40px;
}

.w-40 {
  width: 40px;
}

.h-80 {
  height: 80px;
}

.w-80 {
  width: 80px;
}

.h-120 {
  height: 120px;
}

.w-120 {
  width: 120px;
}

.h-160 {
  height: 160px;
}

.w-160 {
  width: 160px;
}

.h-200 {
  height: 200px;
}

.w-200 {
  width: 200px;
}

.h-240 {
  height: 240px;
}

.w-240 {
  width: 240px;
}

.h-260 {
  height: 260px;
}

.w-260 {
  width: 260px;
}

.h-300 {
  height: 300px;
}

.w-300 {
  width: 300px;
}

.w-full {
  width: 100%;
}

.w-full-screen {
  width: 100vh;
}

.h-full {
  height: 100%;
}

.h-full-screen {
  height: 100vh;
}

.h-100vh-70px {
  height: calc(100vh - 70px);
}

.h-100vh-280px {
  height: calc(100vh - 280px);
}

.m-033 {
  margin: 0.333333rem !important;
}

.m-333 {
  margin: 0.333333rem !important;
}

.margin-333 {
  margin: 0.333333rem !important;
}

[dir=rtl] .pr-16 {
  padding-left: 16px;
  padding-right: 0 !important;
}
[dir=rtl] .mr-16 {
  margin-right: 0 !important;
  margin-left: 16px;
}

.rounded {
  border-radius: 8px;
}
.rounded-circle {
  border-radius: 50%;
}
.rounded.mat-progress-bar,
.rounded .mat-progress-bar-fill {
  border-radius: 10px;
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.background-size-cover {
  background-size: cover;
}

.background-size-contain {
  background-size: contain;
}

.background-size-100 {
  background-size: 100%;
}

[dir=rtl] .float-left {
  float: right;
}
[dir=rtl] .float-right {
  float: left;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.m-0 {
  margin: 0px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-28 {
  margin: 28px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-36 {
  margin: 36px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-44 {
  margin: 44px !important;
}

.m-48 {
  margin: 48px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.mx-4 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.mx-8 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.mx-12 {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.mx-16 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.mx-24 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.mx-28 {
  margin-right: 28px !important;
  margin-left: 28px !important;
}

.mx-32 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.mx-36 {
  margin-right: 36px !important;
  margin-left: 36px !important;
}

.mx-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.mx-44 {
  margin-right: 44px !important;
  margin-left: 44px !important;
}

.mx-48 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.my-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.my-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-44 {
  margin-top: 44px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mr-36 {
  margin-right: 36px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-44 {
  margin-right: 44px !important;
}

.mr-48 {
  margin-right: 48px !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-44 {
  margin-bottom: 44px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.ml-36 {
  margin-left: 36px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-44 {
  margin-left: 44px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0px !important;
}

.p-4 {
  padding: 4px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-28 {
  padding: 28px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-36 {
  padding: 36px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-44 {
  padding: 44px !important;
}

.p-48 {
  padding: 48px !important;
}

.px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.px-4 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.px-8 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.px-12 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.px-16 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.px-24 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.px-28 {
  padding-right: 28px !important;
  padding-left: 28px !important;
}

.px-32 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.px-36 {
  padding-right: 36px !important;
  padding-left: 36px !important;
}

.px-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.px-44 {
  padding-right: 44px !important;
  padding-left: 44px !important;
}

.px-48 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.py-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.py-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.py-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pt-28 {
  padding-top: 28px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pt-36 {
  padding-top: 36px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-44 {
  padding-top: 44px !important;
}

.pt-48 {
  padding-top: 48px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pr-28 {
  padding-right: 28px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.pr-36 {
  padding-right: 36px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-44 {
  padding-right: 44px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-28 {
  padding-bottom: 28px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pb-36 {
  padding-bottom: 36px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-44 {
  padding-bottom: 44px !important;
}

.pb-48 {
  padding-bottom: 48px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pl-28 {
  padding-left: 28px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.pl-36 {
  padding-left: 36px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-44 {
  padding-left: 44px !important;
}

.pl-48 {
  padding-left: 48px !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-semi {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.font-italic {
  font-style: italic !important;
}

.font-normal {
  font-style: normal !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.border-light {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.border-top-light {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.border-right-light {
  border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.border-bottom-light {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.border-left-light {
  border-left: 1px solid rgba(255, 255, 255, 0.1) !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
/*-------------- Loading buttons --------------*/
.button-loading .mat-button-wrapper {
  display: flex;
  align-items: center;
}

.btn-spinner {
  width: 1em;
  height: 1em;
  background: transparent;
  border-radius: 50%;
  margin: 0 16px 0 0;
  border: 2px solid transparent;
  animation: btn-glow 1s ease infinite;
}

@keyframes btn-glow {
  0% {
    box-shadow: 0 0 0 0.4em #5d5d5d, 0 0 0 0.1em #5d5d5d;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #777676;
  }
  100% {
    box-shadow: 0 0 0 0.4em #5d5d5d, 0 0 0 3.6em transparent;
  }
}
.mat-card {
  margin: 0.75rem;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 8px 12px rgba(47, 60, 74, 0.04), 0 2px 6px rgba(47, 60, 74, 0.08) !important;
}
.mat-card.rounded-circle {
  border-radius: 200px !important;
}

.mat-card.default {
  padding: 0;
}

.mat-card.default .mat-card-title {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
  font-weight: 400;
}

.mat-card.default > :first-child {
  border-radius: 2px 2px 0 0;
}

.mat-card.default .mat-card-subtitle:first-child {
  padding-top: 1rem;
}

.mat-card.default .mat-card-subtitle {
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
}

.mat-card.default .mat-card-content {
  padding: 1rem;
  margin-bottom: 0;
  position: relative;
}

.mat-card.default .mat-card-actions,
.mat-card.default .mat-card-actions:last-child {
  padding: 0.5rem;
  margin: 0;
}

.mat-card.default > :last-child {
  border-radius: 0 0 2px 2px;
}

.mat-card .mat-card-title .mat-divider, .mat-divider.full-width {
  margin-left: -24px;
  margin-right: -24px;
}

.mat-card.p-0 .mat-card-title .card-title-text {
  padding: 1rem 1.5rem;
}

.mat-card.p-0 .mat-card-title .card-title-text .card-control {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.mat-card.p-0 .mat-card-title .card-title-text .mat-card-subtitle {
  margin: 0;
}

.mat-card.p-0 .mat-card-title .mat-divider {
  margin-left: 0;
  margin-right: 0;
  border-top-color: rgba(0, 0, 0, 0.04);
}

.mat-card.p-0 .mat-card-image {
  margin: 0 0 1rem !important;
}

.mat-card.p-0 .mat-card-content {
  padding: 0 1.5rem 1.5rem;
}

/*-------- Hopscotch Tour ---------*/
div.hopscotch-bubble,
div.hopscotch-bubble .hopscotch-title,
div.hopscotch-bubble .hopscotch-content,
div.hopscotch-bubble .hopscotch-nav-button {
  font-family: Roboto, sans-serif !important;
}

div.hopscotch-bubble {
  border: 1px solid;
  border-radius: 2px;
}

div.hopscotch-bubble .hopscotch-bubble-number {
  border-radius: 50%;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
  height: 32px;
  width: 32px;
  line-height: 32px;
}

div.hopscotch-bubble .hopscotch-title {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.1px;
}

div.hopscotch-bubble .hopscotch-content {
  margin: -5px 0 16px;
}

div.hopscotch-bubble .hopscotch-bubble-close:active {
  outline: 0;
}

/* up arrow  */
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up {
  top: -17px;
}

/* right arrow */
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right {
  right: -34px;
}

/* bottom arrow */
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down {
  bottom: -34px;
}

/* Left Arrow */
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left {
  left: -17px;
}

div.hopscotch-bubble .hopscotch-nav-button {
  border: 0;
  border-radius: 2px;
  font-weight: normal;
  text-shadow: none !important;
  padding: 0 18px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  background-image: none !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transform: translate3d(0, 0, 0);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

div.hopscotch-bubble .hopscotch-nav-button:hover,
div.hopscotch-bubble .hopscotch-nav-button:active,
div.hopscotch-bubble .hopscotch-nav-button:focus {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  outline: none;
}

div.hopscotch-bubble .hopscotch-nav-button.prev,
div.hopscotch-bubble .hopscotch-nav-button.prev:hover {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}

egret-sidebar {
  position: absolute;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 280px;
  z-index: 1000;
  float: left;
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition-property: transform, width, min-width, max-width, -webkit-transform;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}
egret-sidebar.sidebar-locked-open {
  position: relative !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}
egret-sidebar.open {
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}
egret-sidebar.position-right {
  left: auto !important;
  right: 0 !important;
  float: right;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.egret-sidebar-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
}

.red {
  background-color: #f44336 !important;
}
.red .mat-table {
  background: transparent !important;
}
.red .ngx-datatable.material *,
.red .mat-table,
.red .mat-cell,
.red .mat-header-cell {
  color: white !important;
}
.red .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.red .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #f44336, 5;
}

.text-red {
  color: #f44336;
}

.sidebar-red .navigation-hold {
  background: #f44336 !important;
}
.sidebar-red .navigation-hold a,
.sidebar-red .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-red .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-red .navigation-hold .sidenav li.open > a > span,
.sidebar-red .navigation-hold .icon-menu .mat-raised-button,
.sidebar-red .navigation-hold .app-user .app-user-name,
.sidebar-red .navigation-hold .branding .app-logo-text {
  color: white !important;
}
.sidebar-red .navigation-hold a:hover {
  background: rgba(186, 22, 10, 0.2) !important;
}
.sidebar-red .navigation-hold .app-user .app-user-photo,
.sidebar-red .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: white !important;
}
.sidebar-red .navigation-hold .icon-menu .mat-raised-button,
.sidebar-red .navigation-hold .branding {
  background: rgba(244, 67, 54, 0.96) !important;
}
.sidebar-red .navigation-hold li.open > a {
  background: rgba(186, 22, 10, 0.2) !important;
}
.sidebar-red .navigation-hold .text-muted {
  color: white !important;
}

.footer-red .main-footer {
  background: #f44336 !important;
  color: white !important;
}

.red-50 {
  background-color: #ffebee !important;
}

.red-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-red-50 {
  color: #ffebee !important;
}

.red-100 {
  background-color: #ffcdd2 !important;
}

.red-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-red-100 {
  color: #ffcdd2 !important;
}

.red-200 {
  background-color: #ef9a9a !important;
}

.red-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-red-200 {
  color: #ef9a9a !important;
}

.red-300 {
  background-color: #e57373 !important;
}

.red-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-red-300 {
  color: #e57373 !important;
}

.red-400 {
  background-color: #ef5350 !important;
}

.red-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-red-400 {
  color: #ef5350 !important;
}

.red-500 {
  background-color: #f44336 !important;
}

.red-500-fg {
  color: white !important;
}

.text-red-500 {
  color: #f44336 !important;
}

.red-600 {
  background-color: #e53935 !important;
}

.red-600-fg {
  color: white !important;
}

.text-red-600 {
  color: #e53935 !important;
}

.red-700 {
  background-color: #d32f2f !important;
}

.red-700-fg {
  color: white !important;
}

.text-red-700 {
  color: #d32f2f !important;
}

.red-800 {
  background-color: #c62828 !important;
}

.red-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-red-800 {
  color: #c62828 !important;
}

.red-900 {
  background-color: #b71c1c !important;
}

.red-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-red-900 {
  color: #b71c1c !important;
}

.red-A100 {
  background-color: #ff8a80 !important;
}

.red-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-red-A100 {
  color: #ff8a80 !important;
}

.red-A200 {
  background-color: #ff5252 !important;
}

.red-A200-fg {
  color: white !important;
}

.text-red-A200 {
  color: #ff5252 !important;
}

.red-A400 {
  background-color: #ff1744 !important;
}

.red-A400-fg {
  color: white !important;
}

.text-red-A400 {
  color: #ff1744 !important;
}

.red-A700 {
  background-color: #d50000 !important;
}

.red-A700-fg {
  color: white !important;
}

.text-red-A700 {
  color: #d50000 !important;
}

.pink {
  background-color: #e91e63 !important;
}
.pink .mat-table {
  background: transparent !important;
}
.pink .ngx-datatable.material *,
.pink .mat-table,
.pink .mat-cell,
.pink .mat-header-cell {
  color: white !important;
}
.pink .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.pink .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #e91e63, 5;
}

.text-pink {
  color: #e91e63;
}

.sidebar-pink .navigation-hold {
  background: #e91e63 !important;
}
.sidebar-pink .navigation-hold a,
.sidebar-pink .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-pink .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-pink .navigation-hold .sidenav li.open > a > span,
.sidebar-pink .navigation-hold .icon-menu .mat-raised-button,
.sidebar-pink .navigation-hold .app-user .app-user-name,
.sidebar-pink .navigation-hold .branding .app-logo-text {
  color: white !important;
}
.sidebar-pink .navigation-hold a:hover {
  background: rgba(147, 14, 59, 0.2) !important;
}
.sidebar-pink .navigation-hold .app-user .app-user-photo,
.sidebar-pink .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: white !important;
}
.sidebar-pink .navigation-hold .icon-menu .mat-raised-button,
.sidebar-pink .navigation-hold .branding {
  background: rgba(233, 30, 99, 0.96) !important;
}
.sidebar-pink .navigation-hold li.open > a {
  background: rgba(147, 14, 59, 0.2) !important;
}
.sidebar-pink .navigation-hold .text-muted {
  color: white !important;
}

.footer-pink .main-footer {
  background: #e91e63 !important;
  color: white !important;
}

.pink-50 {
  background-color: #fce4ec !important;
}

.pink-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-pink-50 {
  color: #fce4ec !important;
}

.pink-100 {
  background-color: #f8bbd0 !important;
}

.pink-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-pink-100 {
  color: #f8bbd0 !important;
}

.pink-200 {
  background-color: #f48fb1 !important;
}

.pink-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-pink-200 {
  color: #f48fb1 !important;
}

.pink-300 {
  background-color: #f06292 !important;
}

.pink-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-pink-300 {
  color: #f06292 !important;
}

.pink-400 {
  background-color: #ec407a !important;
}

.pink-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-pink-400 {
  color: #ec407a !important;
}

.pink-500 {
  background-color: #e91e63 !important;
}

.pink-500-fg {
  color: white !important;
}

.text-pink-500 {
  color: #e91e63 !important;
}

.pink-600 {
  background-color: #d81b60 !important;
}

.pink-600-fg {
  color: white !important;
}

.text-pink-600 {
  color: #d81b60 !important;
}

.pink-700 {
  background-color: #c2185b !important;
}

.pink-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-pink-700 {
  color: #c2185b !important;
}

.pink-800 {
  background-color: #ad1457 !important;
}

.pink-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-pink-800 {
  color: #ad1457 !important;
}

.pink-900 {
  background-color: #880e4f !important;
}

.pink-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-pink-900 {
  color: #880e4f !important;
}

.pink-A100 {
  background-color: #ff80ab !important;
}

.pink-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-pink-A100 {
  color: #ff80ab !important;
}

.pink-A200 {
  background-color: #ff4081 !important;
}

.pink-A200-fg {
  color: white !important;
}

.text-pink-A200 {
  color: #ff4081 !important;
}

.pink-A400 {
  background-color: #f50057 !important;
}

.pink-A400-fg {
  color: white !important;
}

.text-pink-A400 {
  color: #f50057 !important;
}

.pink-A700 {
  background-color: #c51162 !important;
}

.pink-A700-fg {
  color: white !important;
}

.text-pink-A700 {
  color: #c51162 !important;
}

.light-purple {
  background-color: #7367f0 !important;
}
.light-purple .mat-table {
  background: transparent !important;
}
.light-purple .ngx-datatable.material *,
.light-purple .mat-table,
.light-purple .mat-cell,
.light-purple .mat-header-cell {
  color: #ffffff !important;
}
.light-purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.light-purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #7367f0, 5;
}

.text-light-purple {
  color: #7367f0;
}

.sidebar-light-purple .navigation-hold {
  background: #7367f0 !important;
}
.sidebar-light-purple .navigation-hold a,
.sidebar-light-purple .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-light-purple .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-light-purple .navigation-hold .sidenav li.open > a > span,
.sidebar-light-purple .navigation-hold .icon-menu .mat-raised-button,
.sidebar-light-purple .navigation-hold .app-user .app-user-name,
.sidebar-light-purple .navigation-hold .branding .app-logo-text {
  color: #ffffff !important;
}
.sidebar-light-purple .navigation-hold a:hover {
  background: rgba(39, 22, 219, 0.2) !important;
}
.sidebar-light-purple .navigation-hold .app-user .app-user-photo,
.sidebar-light-purple .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #ffffff !important;
}
.sidebar-light-purple .navigation-hold .icon-menu .mat-raised-button,
.sidebar-light-purple .navigation-hold .branding {
  background: rgba(115, 103, 240, 0.96) !important;
}
.sidebar-light-purple .navigation-hold li.open > a {
  background: rgba(39, 22, 219, 0.2) !important;
}
.sidebar-light-purple .navigation-hold .text-muted {
  color: white !important;
}

.footer-light-purple .main-footer {
  background: #7367f0 !important;
  color: #ffffff !important;
}

.light-purple-50 {
  background-color: #eeedfd !important;
}

.light-purple-50-fg {
  color: #000000 !important;
}

.text-light-purple-50 {
  color: #eeedfd !important;
}

.light-purple-100 {
  background-color: #d5d1fb !important;
}

.light-purple-100-fg {
  color: #000000 !important;
}

.text-light-purple-100 {
  color: #d5d1fb !important;
}

.light-purple-200 {
  background-color: #b9b3f8 !important;
}

.light-purple-200-fg {
  color: #000000 !important;
}

.text-light-purple-200 {
  color: #b9b3f8 !important;
}

.light-purple-300 {
  background-color: #9d95f5 !important;
}

.light-purple-300-fg {
  color: #000000 !important;
}

.text-light-purple-300 {
  color: #9d95f5 !important;
}

.light-purple-400 {
  background-color: #887ef2 !important;
}

.light-purple-400-fg {
  color: #000000 !important;
}

.text-light-purple-400 {
  color: #887ef2 !important;
}

.light-purple-500 {
  background-color: #7367f0 !important;
}

.light-purple-500-fg {
  color: #ffffff !important;
}

.text-light-purple-500 {
  color: #7367f0 !important;
}

.light-purple-600 {
  background-color: #6b5fee !important;
}

.light-purple-600-fg {
  color: #ffffff !important;
}

.text-light-purple-600 {
  color: #6b5fee !important;
}

.light-purple-700 {
  background-color: #6054ec !important;
}

.light-purple-700-fg {
  color: #ffffff !important;
}

.text-light-purple-700 {
  color: #6054ec !important;
}

.light-purple-800 {
  background-color: #564ae9 !important;
}

.light-purple-800-fg {
  color: #ffffff !important;
}

.text-light-purple-800 {
  color: #564ae9 !important;
}

.light-purple-900 {
  background-color: #4339e5 !important;
}

.light-purple-900-fg {
  color: #ffffff !important;
}

.text-light-purple-900 {
  color: #4339e5 !important;
}

.light-purple-A100 {
  background-color: #ffffff !important;
}

.light-purple-A100-fg {
  color: #000000 !important;
}

.text-light-purple-A100 {
  color: #ffffff !important;
}

.light-purple-A200 {
  background-color: #fcfcff !important;
}

.light-purple-A200-fg {
  color: #000000 !important;
}

.text-light-purple-A200 {
  color: #fcfcff !important;
}

.light-purple-A400 {
  background-color: #ccc9ff !important;
}

.light-purple-A400-fg {
  color: #000000 !important;
}

.text-light-purple-A400 {
  color: #ccc9ff !important;
}

.light-purple-A700 {
  background-color: #b3afff !important;
}

.light-purple-A700-fg {
  color: #000000 !important;
}

.text-light-purple-A700 {
  color: #b3afff !important;
}

.purple {
  background-color: #9c27b0 !important;
}
.purple .mat-table {
  background: transparent !important;
}
.purple .ngx-datatable.material *,
.purple .mat-table,
.purple .mat-cell,
.purple .mat-header-cell {
  color: rgba(255, 255, 255, 0.87) !important;
}
.purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #9c27b0, 5;
}

.text-purple {
  color: #9c27b0;
}

.sidebar-purple .navigation-hold {
  background: #9c27b0 !important;
}
.sidebar-purple .navigation-hold a,
.sidebar-purple .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-purple .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-purple .navigation-hold .sidenav li.open > a > span,
.sidebar-purple .navigation-hold .icon-menu .mat-raised-button,
.sidebar-purple .navigation-hold .app-user .app-user-name,
.sidebar-purple .navigation-hold .branding .app-logo-text {
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-purple .navigation-hold a:hover {
  background: rgba(82, 20, 93, 0.2) !important;
}
.sidebar-purple .navigation-hold .app-user .app-user-photo,
.sidebar-purple .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-purple .navigation-hold .icon-menu .mat-raised-button,
.sidebar-purple .navigation-hold .branding {
  background: rgba(156, 39, 176, 0.96) !important;
}
.sidebar-purple .navigation-hold li.open > a {
  background: rgba(82, 20, 93, 0.2) !important;
}
.sidebar-purple .navigation-hold .text-muted {
  color: rgba(255, 255, 255, 0.87) !important;
}

.footer-purple .main-footer {
  background: #9c27b0 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.purple-50 {
  background-color: #f3e5f5 !important;
}

.purple-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-purple-50 {
  color: #f3e5f5 !important;
}

.purple-100 {
  background-color: #e1bee7 !important;
}

.purple-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-purple-100 {
  color: #e1bee7 !important;
}

.purple-200 {
  background-color: #ce93d8 !important;
}

.purple-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-purple-200 {
  color: #ce93d8 !important;
}

.purple-300 {
  background-color: #ba68c8 !important;
}

.purple-300-fg {
  color: white !important;
}

.text-purple-300 {
  color: #ba68c8 !important;
}

.purple-400 {
  background-color: #ab47bc !important;
}

.purple-400-fg {
  color: white !important;
}

.text-purple-400 {
  color: #ab47bc !important;
}

.purple-500 {
  background-color: #9c27b0 !important;
}

.purple-500-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-purple-500 {
  color: #9c27b0 !important;
}

.purple-600 {
  background-color: #8e24aa !important;
}

.purple-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-purple-600 {
  color: #8e24aa !important;
}

.purple-700 {
  background-color: #7b1fa2 !important;
}

.purple-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-purple-700 {
  color: #7b1fa2 !important;
}

.purple-800 {
  background-color: #6a1b9a !important;
}

.purple-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-purple-800 {
  color: #6a1b9a !important;
}

.purple-900 {
  background-color: #4a148c !important;
}

.purple-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-purple-900 {
  color: #4a148c !important;
}

.purple-A100 {
  background-color: #ea80fc !important;
}

.purple-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-purple-A100 {
  color: #ea80fc !important;
}

.purple-A200 {
  background-color: #e040fb !important;
}

.purple-A200-fg {
  color: white !important;
}

.text-purple-A200 {
  color: #e040fb !important;
}

.purple-A400 {
  background-color: #d500f9 !important;
}

.purple-A400-fg {
  color: white !important;
}

.text-purple-A400 {
  color: #d500f9 !important;
}

.purple-A700 {
  background-color: #aa00ff !important;
}

.purple-A700-fg {
  color: white !important;
}

.text-purple-A700 {
  color: #aa00ff !important;
}

.deep-purple {
  background-color: #673ab7 !important;
}
.deep-purple .mat-table {
  background: transparent !important;
}
.deep-purple .ngx-datatable.material *,
.deep-purple .mat-table,
.deep-purple .mat-cell,
.deep-purple .mat-header-cell {
  color: rgba(255, 255, 255, 0.87) !important;
}
.deep-purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.deep-purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #673ab7, 5;
}

.text-deep-purple {
  color: #673ab7;
}

.sidebar-deep-purple .navigation-hold {
  background: #673ab7 !important;
}
.sidebar-deep-purple .navigation-hold a,
.sidebar-deep-purple .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-deep-purple .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-deep-purple .navigation-hold .sidenav li.open > a > span,
.sidebar-deep-purple .navigation-hold .icon-menu .mat-raised-button,
.sidebar-deep-purple .navigation-hold .app-user .app-user-name,
.sidebar-deep-purple .navigation-hold .branding .app-logo-text {
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-deep-purple .navigation-hold a:hover {
  background: rgba(59, 33, 106, 0.2) !important;
}
.sidebar-deep-purple .navigation-hold .app-user .app-user-photo,
.sidebar-deep-purple .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-deep-purple .navigation-hold .icon-menu .mat-raised-button,
.sidebar-deep-purple .navigation-hold .branding {
  background: rgba(103, 58, 183, 0.96) !important;
}
.sidebar-deep-purple .navigation-hold li.open > a {
  background: rgba(59, 33, 106, 0.2) !important;
}
.sidebar-deep-purple .navigation-hold .text-muted {
  color: rgba(255, 255, 255, 0.87) !important;
}

.footer-deep-purple .main-footer {
  background: #673ab7 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.deep-purple-50 {
  background-color: #ede7f6 !important;
}

.deep-purple-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-purple-50 {
  color: #ede7f6 !important;
}

.deep-purple-100 {
  background-color: #d1c4e9 !important;
}

.deep-purple-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-purple-100 {
  color: #d1c4e9 !important;
}

.deep-purple-200 {
  background-color: #b39ddb !important;
}

.deep-purple-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-purple-200 {
  color: #b39ddb !important;
}

.deep-purple-300 {
  background-color: #9575cd !important;
}

.deep-purple-300-fg {
  color: white !important;
}

.text-deep-purple-300 {
  color: #9575cd !important;
}

.deep-purple-400 {
  background-color: #7e57c2 !important;
}

.deep-purple-400-fg {
  color: white !important;
}

.text-deep-purple-400 {
  color: #7e57c2 !important;
}

.deep-purple-500 {
  background-color: #673ab7 !important;
}

.deep-purple-500-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-500 {
  color: #673ab7 !important;
}

.deep-purple-600 {
  background-color: #5e35b1 !important;
}

.deep-purple-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-600 {
  color: #5e35b1 !important;
}

.deep-purple-700 {
  background-color: #512da8 !important;
}

.deep-purple-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-700 {
  color: #512da8 !important;
}

.deep-purple-800 {
  background-color: #4527a0 !important;
}

.deep-purple-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-800 {
  color: #4527a0 !important;
}

.deep-purple-900 {
  background-color: #311b92 !important;
}

.deep-purple-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-900 {
  color: #311b92 !important;
}

.deep-purple-A100 {
  background-color: #b388ff !important;
}

.deep-purple-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-purple-A100 {
  color: #b388ff !important;
}

.deep-purple-A200 {
  background-color: #7c4dff !important;
}

.deep-purple-A200-fg {
  color: white !important;
}

.text-deep-purple-A200 {
  color: #7c4dff !important;
}

.deep-purple-A400 {
  background-color: #651fff !important;
}

.deep-purple-A400-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-A400 {
  color: #651fff !important;
}

.deep-purple-A700 {
  background-color: #6200ea !important;
}

.deep-purple-A700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-deep-purple-A700 {
  color: #6200ea !important;
}

.indigo {
  background-color: #3f51b5 !important;
}
.indigo .mat-table {
  background: transparent !important;
}
.indigo .ngx-datatable.material *,
.indigo .mat-table,
.indigo .mat-cell,
.indigo .mat-header-cell {
  color: rgba(255, 255, 255, 0.87) !important;
}
.indigo .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.indigo .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #3f51b5, 5;
}

.text-indigo {
  color: #3f51b5;
}

.sidebar-indigo .navigation-hold {
  background: #3f51b5 !important;
}
.sidebar-indigo .navigation-hold a,
.sidebar-indigo .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-indigo .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-indigo .navigation-hold .sidenav li.open > a > span,
.sidebar-indigo .navigation-hold .icon-menu .mat-raised-button,
.sidebar-indigo .navigation-hold .app-user .app-user-name,
.sidebar-indigo .navigation-hold .branding .app-logo-text {
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-indigo .navigation-hold a:hover {
  background: rgba(37, 47, 105, 0.2) !important;
}
.sidebar-indigo .navigation-hold .app-user .app-user-photo,
.sidebar-indigo .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-indigo .navigation-hold .icon-menu .mat-raised-button,
.sidebar-indigo .navigation-hold .branding {
  background: rgba(63, 81, 181, 0.96) !important;
}
.sidebar-indigo .navigation-hold li.open > a {
  background: rgba(37, 47, 105, 0.2) !important;
}
.sidebar-indigo .navigation-hold .text-muted {
  color: rgba(255, 255, 255, 0.87) !important;
}

.footer-indigo .main-footer {
  background: #3f51b5 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.indigo-50 {
  background-color: #e8eaf6 !important;
}

.indigo-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-indigo-50 {
  color: #e8eaf6 !important;
}

.indigo-100 {
  background-color: #c5cae9 !important;
}

.indigo-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-indigo-100 {
  color: #c5cae9 !important;
}

.indigo-200 {
  background-color: #9fa8da !important;
}

.indigo-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-indigo-200 {
  color: #9fa8da !important;
}

.indigo-300 {
  background-color: #7986cb !important;
}

.indigo-300-fg {
  color: white !important;
}

.text-indigo-300 {
  color: #7986cb !important;
}

.indigo-400 {
  background-color: #5c6bc0 !important;
}

.indigo-400-fg {
  color: white !important;
}

.text-indigo-400 {
  color: #5c6bc0 !important;
}

.indigo-500 {
  background-color: #3f51b5 !important;
}

.indigo-500-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-indigo-500 {
  color: #3f51b5 !important;
}

.indigo-600 {
  background-color: #3949ab !important;
}

.indigo-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-indigo-600 {
  color: #3949ab !important;
}

.indigo-700 {
  background-color: #303f9f !important;
}

.indigo-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-indigo-700 {
  color: #303f9f !important;
}

.indigo-800 {
  background-color: #283593 !important;
}

.indigo-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-indigo-800 {
  color: #283593 !important;
}

.indigo-900 {
  background-color: #1a237e !important;
}

.indigo-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-indigo-900 {
  color: #1a237e !important;
}

.indigo-A100 {
  background-color: #8c9eff !important;
}

.indigo-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-indigo-A100 {
  color: #8c9eff !important;
}

.indigo-A200 {
  background-color: #536dfe !important;
}

.indigo-A200-fg {
  color: white !important;
}

.text-indigo-A200 {
  color: #536dfe !important;
}

.indigo-A400 {
  background-color: #3d5afe !important;
}

.indigo-A400-fg {
  color: white !important;
}

.text-indigo-A400 {
  color: #3d5afe !important;
}

.indigo-A700 {
  background-color: #304ffe !important;
}

.indigo-A700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-indigo-A700 {
  color: #304ffe !important;
}

.blue {
  background-color: #2196f3 !important;
}
.blue .mat-table {
  background: transparent !important;
}
.blue .ngx-datatable.material *,
.blue .mat-table,
.blue .mat-cell,
.blue .mat-header-cell {
  color: white !important;
}
.blue .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.blue .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #2196f3, 5;
}

.text-blue {
  color: #2196f3;
}

.sidebar-blue .navigation-hold {
  background: #2196f3 !important;
}
.sidebar-blue .navigation-hold a,
.sidebar-blue .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-blue .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-blue .navigation-hold .sidenav li.open > a > span,
.sidebar-blue .navigation-hold .icon-menu .mat-raised-button,
.sidebar-blue .navigation-hold .app-user .app-user-name,
.sidebar-blue .navigation-hold .branding .app-logo-text {
  color: white !important;
}
.sidebar-blue .navigation-hold a:hover {
  background: rgba(9, 96, 165, 0.2) !important;
}
.sidebar-blue .navigation-hold .app-user .app-user-photo,
.sidebar-blue .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: white !important;
}
.sidebar-blue .navigation-hold .icon-menu .mat-raised-button,
.sidebar-blue .navigation-hold .branding {
  background: rgba(33, 150, 243, 0.96) !important;
}
.sidebar-blue .navigation-hold li.open > a {
  background: rgba(9, 96, 165, 0.2) !important;
}
.sidebar-blue .navigation-hold .text-muted {
  color: white !important;
}

.footer-blue .main-footer {
  background: #2196f3 !important;
  color: white !important;
}

.blue-50 {
  background-color: #e3f2fd !important;
}

.blue-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-50 {
  color: #e3f2fd !important;
}

.blue-100 {
  background-color: #bbdefb !important;
}

.blue-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-100 {
  color: #bbdefb !important;
}

.blue-200 {
  background-color: #90caf9 !important;
}

.blue-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-200 {
  color: #90caf9 !important;
}

.blue-300 {
  background-color: #64b5f6 !important;
}

.blue-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-300 {
  color: #64b5f6 !important;
}

.blue-400 {
  background-color: #42a5f5 !important;
}

.blue-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-400 {
  color: #42a5f5 !important;
}

.blue-500 {
  background-color: #2196f3 !important;
}

.blue-500-fg {
  color: white !important;
}

.text-blue-500 {
  color: #2196f3 !important;
}

.blue-600 {
  background-color: #1e88e5 !important;
}

.blue-600-fg {
  color: white !important;
}

.text-blue-600 {
  color: #1e88e5 !important;
}

.blue-700 {
  background-color: #1976d2 !important;
}

.blue-700-fg {
  color: white !important;
}

.text-blue-700 {
  color: #1976d2 !important;
}

.blue-800 {
  background-color: #1565c0 !important;
}

.blue-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-800 {
  color: #1565c0 !important;
}

.blue-900 {
  background-color: #0d47a1 !important;
}

.blue-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-900 {
  color: #0d47a1 !important;
}

.blue-A100 {
  background-color: #82b1ff !important;
}

.blue-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-A100 {
  color: #82b1ff !important;
}

.blue-A200 {
  background-color: #448aff !important;
}

.blue-A200-fg {
  color: white !important;
}

.text-blue-A200 {
  color: #448aff !important;
}

.blue-A400 {
  background-color: #2979ff !important;
}

.blue-A400-fg {
  color: white !important;
}

.text-blue-A400 {
  color: #2979ff !important;
}

.blue-A700 {
  background-color: #2962ff !important;
}

.blue-A700-fg {
  color: white !important;
}

.text-blue-A700 {
  color: #2962ff !important;
}

.light-blue {
  background-color: #03a9f4 !important;
}
.light-blue .mat-table {
  background: transparent !important;
}
.light-blue .ngx-datatable.material *,
.light-blue .mat-table,
.light-blue .mat-cell,
.light-blue .mat-header-cell {
  color: white !important;
}
.light-blue .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.light-blue .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #03a9f4, 5;
}

.text-light-blue {
  color: #03a9f4;
}

.sidebar-light-blue .navigation-hold {
  background: #03a9f4 !important;
}
.sidebar-light-blue .navigation-hold a,
.sidebar-light-blue .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-light-blue .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-light-blue .navigation-hold .sidenav li.open > a > span,
.sidebar-light-blue .navigation-hold .icon-menu .mat-raised-button,
.sidebar-light-blue .navigation-hold .app-user .app-user-name,
.sidebar-light-blue .navigation-hold .branding .app-logo-text {
  color: white !important;
}
.sidebar-light-blue .navigation-hold a:hover {
  background: rgba(2, 99, 143, 0.2) !important;
}
.sidebar-light-blue .navigation-hold .app-user .app-user-photo,
.sidebar-light-blue .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: white !important;
}
.sidebar-light-blue .navigation-hold .icon-menu .mat-raised-button,
.sidebar-light-blue .navigation-hold .branding {
  background: rgba(3, 169, 244, 0.96) !important;
}
.sidebar-light-blue .navigation-hold li.open > a {
  background: rgba(2, 99, 143, 0.2) !important;
}
.sidebar-light-blue .navigation-hold .text-muted {
  color: white !important;
}

.footer-light-blue .main-footer {
  background: #03a9f4 !important;
  color: white !important;
}

.light-blue-50 {
  background-color: #e1f5fe !important;
}

.light-blue-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-50 {
  color: #e1f5fe !important;
}

.light-blue-100 {
  background-color: #b3e5fc !important;
}

.light-blue-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-100 {
  color: #b3e5fc !important;
}

.light-blue-200 {
  background-color: #81d4fa !important;
}

.light-blue-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-200 {
  color: #81d4fa !important;
}

.light-blue-300 {
  background-color: #4fc3f7 !important;
}

.light-blue-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-300 {
  color: #4fc3f7 !important;
}

.light-blue-400 {
  background-color: #29b6f6 !important;
}

.light-blue-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-400 {
  color: #29b6f6 !important;
}

.light-blue-500 {
  background-color: #03a9f4 !important;
}

.light-blue-500-fg {
  color: white !important;
}

.text-light-blue-500 {
  color: #03a9f4 !important;
}

.light-blue-600 {
  background-color: #039be5 !important;
}

.light-blue-600-fg {
  color: white !important;
}

.text-light-blue-600 {
  color: #039be5 !important;
}

.light-blue-700 {
  background-color: #0288d1 !important;
}

.light-blue-700-fg {
  color: white !important;
}

.text-light-blue-700 {
  color: #0288d1 !important;
}

.light-blue-800 {
  background-color: #0277bd !important;
}

.light-blue-800-fg {
  color: white !important;
}

.text-light-blue-800 {
  color: #0277bd !important;
}

.light-blue-900 {
  background-color: #01579b !important;
}

.light-blue-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-light-blue-900 {
  color: #01579b !important;
}

.light-blue-A100 {
  background-color: #80d8ff !important;
}

.light-blue-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-A100 {
  color: #80d8ff !important;
}

.light-blue-A200 {
  background-color: #40c4ff !important;
}

.light-blue-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-A200 {
  color: #40c4ff !important;
}

.light-blue-A400 {
  background-color: #00b0ff !important;
}

.light-blue-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-blue-A400 {
  color: #00b0ff !important;
}

.light-blue-A700 {
  background-color: #0091ea !important;
}

.light-blue-A700-fg {
  color: white !important;
}

.text-light-blue-A700 {
  color: #0091ea !important;
}

.dark-blue {
  background-color: #10174c !important;
}
.dark-blue .mat-table {
  background: transparent !important;
}
.dark-blue .ngx-datatable.material *,
.dark-blue .mat-table,
.dark-blue .mat-cell,
.dark-blue .mat-header-cell {
  color: rgba(255, 255, 255, 0.87) !important;
}
.dark-blue .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.dark-blue .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #10174c, 5;
}

.text-dark-blue {
  color: #10174c;
}

.sidebar-dark-blue .navigation-hold {
  background: #10174c !important;
}
.sidebar-dark-blue .navigation-hold a,
.sidebar-dark-blue .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-dark-blue .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-dark-blue .navigation-hold .sidenav li.open > a > span,
.sidebar-dark-blue .navigation-hold .icon-menu .mat-raised-button,
.sidebar-dark-blue .navigation-hold .app-user .app-user-name,
.sidebar-dark-blue .navigation-hold .branding .app-logo-text {
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-dark-blue .navigation-hold a:hover {
  background: rgba(0, 0, 0, 0.2) !important;
}
.sidebar-dark-blue .navigation-hold .app-user .app-user-photo,
.sidebar-dark-blue .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-dark-blue .navigation-hold .icon-menu .mat-raised-button,
.sidebar-dark-blue .navigation-hold .branding {
  background: rgba(16, 23, 76, 0.96) !important;
}
.sidebar-dark-blue .navigation-hold li.open > a {
  background: rgba(0, 0, 0, 0.2) !important;
}
.sidebar-dark-blue .navigation-hold .text-muted {
  color: rgba(255, 255, 255, 0.87) !important;
}

.footer-dark-blue .main-footer {
  background: #10174c !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.dark-blue-50 {
  background-color: #e2e3ea !important;
}

.dark-blue-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-dark-blue-50 {
  color: #e2e3ea !important;
}

.dark-blue-100 {
  background-color: #b7b9c9 !important;
}

.dark-blue-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-dark-blue-100 {
  color: #b7b9c9 !important;
}

.dark-blue-200 {
  background-color: #888ba6 !important;
}

.dark-blue-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-dark-blue-200 {
  color: #888ba6 !important;
}

.dark-blue-300 {
  background-color: #585d82 !important;
}

.dark-blue-300-fg {
  color: white !important;
}

.text-dark-blue-300 {
  color: #585d82 !important;
}

.dark-blue-400 {
  background-color: #343a67 !important;
}

.dark-blue-400-fg {
  color: white !important;
}

.text-dark-blue-400 {
  color: #343a67 !important;
}

.dark-blue-500 {
  background-color: #10174c !important;
}

.dark-blue-500-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark-blue-500 {
  color: #10174c !important;
}

.dark-blue-600 {
  background-color: #0e1445 !important;
}

.dark-blue-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark-blue-600 {
  color: #0e1445 !important;
}

.dark-blue-700 {
  background-color: #0c113c !important;
}

.dark-blue-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark-blue-700 {
  color: #0c113c !important;
}

.dark-blue-800 {
  background-color: #090d33 !important;
}

.dark-blue-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark-blue-800 {
  color: #090d33 !important;
}

.dark-blue-900 {
  background-color: #050724 !important;
}

.dark-blue-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark-blue-900 {
  color: #050724 !important;
}

.dark-blue-A100 {
  background-color: #6068ff !important;
}

.dark-blue-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-dark-blue-A100 {
  color: #6068ff !important;
}

.dark-blue-A200 {
  background-color: #2d38ff !important;
}

.dark-blue-A200-fg {
  color: white !important;
}

.text-dark-blue-A200 {
  color: #2d38ff !important;
}

.dark-blue-A400 {
  background-color: #000df9 !important;
}

.dark-blue-A400-fg {
  color: white !important;
}

.text-dark-blue-A400 {
  color: #000df9 !important;
}

.dark-blue-A700 {
  background-color: #000be0 !important;
}

.dark-blue-A700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-dark-blue-A700 {
  color: #000be0 !important;
}

.cyan {
  background-color: #00bcd4 !important;
}
.cyan .mat-table {
  background: transparent !important;
}
.cyan .ngx-datatable.material *,
.cyan .mat-table,
.cyan .mat-cell,
.cyan .mat-header-cell {
  color: white !important;
}
.cyan .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.cyan .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #00bcd4, 5;
}

.text-cyan {
  color: #00bcd4;
}

.sidebar-cyan .navigation-hold {
  background: #00bcd4 !important;
}
.sidebar-cyan .navigation-hold a,
.sidebar-cyan .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-cyan .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-cyan .navigation-hold .sidenav li.open > a > span,
.sidebar-cyan .navigation-hold .icon-menu .mat-raised-button,
.sidebar-cyan .navigation-hold .app-user .app-user-name,
.sidebar-cyan .navigation-hold .branding .app-logo-text {
  color: white !important;
}
.sidebar-cyan .navigation-hold a:hover {
  background: rgba(0, 98, 110, 0.2) !important;
}
.sidebar-cyan .navigation-hold .app-user .app-user-photo,
.sidebar-cyan .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: white !important;
}
.sidebar-cyan .navigation-hold .icon-menu .mat-raised-button,
.sidebar-cyan .navigation-hold .branding {
  background: rgba(0, 188, 212, 0.96) !important;
}
.sidebar-cyan .navigation-hold li.open > a {
  background: rgba(0, 98, 110, 0.2) !important;
}
.sidebar-cyan .navigation-hold .text-muted {
  color: white !important;
}

.footer-cyan .main-footer {
  background: #00bcd4 !important;
  color: white !important;
}

.cyan-50 {
  background-color: #e0f7fa !important;
}

.cyan-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-50 {
  color: #e0f7fa !important;
}

.cyan-100 {
  background-color: #b2ebf2 !important;
}

.cyan-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-100 {
  color: #b2ebf2 !important;
}

.cyan-200 {
  background-color: #80deea !important;
}

.cyan-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-200 {
  color: #80deea !important;
}

.cyan-300 {
  background-color: #4dd0e1 !important;
}

.cyan-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-300 {
  color: #4dd0e1 !important;
}

.cyan-400 {
  background-color: #26c6da !important;
}

.cyan-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-400 {
  color: #26c6da !important;
}

.cyan-500 {
  background-color: #00bcd4 !important;
}

.cyan-500-fg {
  color: white !important;
}

.text-cyan-500 {
  color: #00bcd4 !important;
}

.cyan-600 {
  background-color: #00acc1 !important;
}

.cyan-600-fg {
  color: white !important;
}

.text-cyan-600 {
  color: #00acc1 !important;
}

.cyan-700 {
  background-color: #0097a7 !important;
}

.cyan-700-fg {
  color: white !important;
}

.text-cyan-700 {
  color: #0097a7 !important;
}

.cyan-800 {
  background-color: #00838f !important;
}

.cyan-800-fg {
  color: white !important;
}

.text-cyan-800 {
  color: #00838f !important;
}

.cyan-900 {
  background-color: #006064 !important;
}

.cyan-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-cyan-900 {
  color: #006064 !important;
}

.cyan-A100 {
  background-color: #84ffff !important;
}

.cyan-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-A100 {
  color: #84ffff !important;
}

.cyan-A200 {
  background-color: #18ffff !important;
}

.cyan-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-A200 {
  color: #18ffff !important;
}

.cyan-A400 {
  background-color: #00e5ff !important;
}

.cyan-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-A400 {
  color: #00e5ff !important;
}

.cyan-A700 {
  background-color: #00b8d4 !important;
}

.cyan-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-cyan-A700 {
  color: #00b8d4 !important;
}

.teal {
  background-color: #009688 !important;
}
.teal .mat-table {
  background: transparent !important;
}
.teal .ngx-datatable.material *,
.teal .mat-table,
.teal .mat-cell,
.teal .mat-header-cell {
  color: white !important;
}
.teal .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.teal .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #009688, 5;
}

.text-teal {
  color: #009688;
}

.sidebar-teal .navigation-hold {
  background: #009688 !important;
}
.sidebar-teal .navigation-hold a,
.sidebar-teal .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-teal .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-teal .navigation-hold .sidenav li.open > a > span,
.sidebar-teal .navigation-hold .icon-menu .mat-raised-button,
.sidebar-teal .navigation-hold .app-user .app-user-name,
.sidebar-teal .navigation-hold .branding .app-logo-text {
  color: white !important;
}
.sidebar-teal .navigation-hold a:hover {
  background: rgba(0, 48, 44, 0.2) !important;
}
.sidebar-teal .navigation-hold .app-user .app-user-photo,
.sidebar-teal .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: white !important;
}
.sidebar-teal .navigation-hold .icon-menu .mat-raised-button,
.sidebar-teal .navigation-hold .branding {
  background: rgba(0, 150, 136, 0.96) !important;
}
.sidebar-teal .navigation-hold li.open > a {
  background: rgba(0, 48, 44, 0.2) !important;
}
.sidebar-teal .navigation-hold .text-muted {
  color: white !important;
}

.footer-teal .main-footer {
  background: #009688 !important;
  color: white !important;
}

.teal-50 {
  background-color: #e0f2f1 !important;
}

.teal-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-50 {
  color: #e0f2f1 !important;
}

.teal-100 {
  background-color: #b2dfdb !important;
}

.teal-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-100 {
  color: #b2dfdb !important;
}

.teal-200 {
  background-color: #80cbc4 !important;
}

.teal-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-200 {
  color: #80cbc4 !important;
}

.teal-300 {
  background-color: #4db6ac !important;
}

.teal-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-300 {
  color: #4db6ac !important;
}

.teal-400 {
  background-color: #26a69a !important;
}

.teal-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-400 {
  color: #26a69a !important;
}

.teal-500 {
  background-color: #009688 !important;
}

.teal-500-fg {
  color: white !important;
}

.text-teal-500 {
  color: #009688 !important;
}

.teal-600 {
  background-color: #00897b !important;
}

.teal-600-fg {
  color: white !important;
}

.text-teal-600 {
  color: #00897b !important;
}

.teal-700 {
  background-color: #00796b !important;
}

.teal-700-fg {
  color: white !important;
}

.text-teal-700 {
  color: #00796b !important;
}

.teal-800 {
  background-color: #00695c !important;
}

.teal-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-teal-800 {
  color: #00695c !important;
}

.teal-900 {
  background-color: #004d40 !important;
}

.teal-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-teal-900 {
  color: #004d40 !important;
}

.teal-A100 {
  background-color: #a7ffeb !important;
}

.teal-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-A100 {
  color: #a7ffeb !important;
}

.teal-A200 {
  background-color: #64ffda !important;
}

.teal-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-A200 {
  color: #64ffda !important;
}

.teal-A400 {
  background-color: #1de9b6 !important;
}

.teal-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-A400 {
  color: #1de9b6 !important;
}

.teal-A700 {
  background-color: #00bfa5 !important;
}

.teal-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-teal-A700 {
  color: #00bfa5 !important;
}

.green {
  background-color: #4caf50 !important;
}
.green .mat-table {
  background: transparent !important;
}
.green .ngx-datatable.material *,
.green .mat-table,
.green .mat-cell,
.green .mat-header-cell {
  color: white !important;
}
.green .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.green .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #4caf50, 5;
}

.text-green {
  color: #4caf50;
}

.sidebar-green .navigation-hold {
  background: #4caf50 !important;
}
.sidebar-green .navigation-hold a,
.sidebar-green .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-green .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-green .navigation-hold .sidenav li.open > a > span,
.sidebar-green .navigation-hold .icon-menu .mat-raised-button,
.sidebar-green .navigation-hold .app-user .app-user-name,
.sidebar-green .navigation-hold .branding .app-logo-text {
  color: white !important;
}
.sidebar-green .navigation-hold a:hover {
  background: rgba(45, 104, 47, 0.2) !important;
}
.sidebar-green .navigation-hold .app-user .app-user-photo,
.sidebar-green .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: white !important;
}
.sidebar-green .navigation-hold .icon-menu .mat-raised-button,
.sidebar-green .navigation-hold .branding {
  background: rgba(76, 175, 80, 0.96) !important;
}
.sidebar-green .navigation-hold li.open > a {
  background: rgba(45, 104, 47, 0.2) !important;
}
.sidebar-green .navigation-hold .text-muted {
  color: white !important;
}

.footer-green .main-footer {
  background: #4caf50 !important;
  color: white !important;
}

.green-50 {
  background-color: #e8f5e9 !important;
}

.green-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-50 {
  color: #e8f5e9 !important;
}

.green-100 {
  background-color: #c8e6c9 !important;
}

.green-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-100 {
  color: #c8e6c9 !important;
}

.green-200 {
  background-color: #a5d6a7 !important;
}

.green-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-200 {
  color: #a5d6a7 !important;
}

.green-300 {
  background-color: #81c784 !important;
}

.green-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-300 {
  color: #81c784 !important;
}

.green-400 {
  background-color: #66bb6a !important;
}

.green-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-400 {
  color: #66bb6a !important;
}

.green-500 {
  background-color: #4caf50 !important;
}

.green-500-fg {
  color: white !important;
}

.text-green-500 {
  color: #4caf50 !important;
}

.green-600 {
  background-color: #43a047 !important;
}

.green-600-fg {
  color: white !important;
}

.text-green-600 {
  color: #43a047 !important;
}

.green-700 {
  background-color: #388e3c !important;
}

.green-700-fg {
  color: white !important;
}

.text-green-700 {
  color: #388e3c !important;
}

.green-800 {
  background-color: #2e7d32 !important;
}

.green-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-green-800 {
  color: #2e7d32 !important;
}

.green-900 {
  background-color: #1b5e20 !important;
}

.green-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-green-900 {
  color: #1b5e20 !important;
}

.green-A100 {
  background-color: #b9f6ca !important;
}

.green-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-A100 {
  color: #b9f6ca !important;
}

.green-A200 {
  background-color: #69f0ae !important;
}

.green-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-A200 {
  color: #69f0ae !important;
}

.green-A400 {
  background-color: #00e676 !important;
}

.green-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-A400 {
  color: #00e676 !important;
}

.green-A700 {
  background-color: #00c853 !important;
}

.green-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-green-A700 {
  color: #00c853 !important;
}

.light-green {
  background-color: #8bc34a !important;
}
.light-green .mat-table {
  background: transparent !important;
}
.light-green .ngx-datatable.material *,
.light-green .mat-table,
.light-green .mat-cell,
.light-green .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.light-green .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #8bc34a, 5;
}

.text-light-green {
  color: #8bc34a;
}

.sidebar-light-green .navigation-hold {
  background: #8bc34a !important;
}
.sidebar-light-green .navigation-hold a,
.sidebar-light-green .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-light-green .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-light-green .navigation-hold .sidenav li.open > a > span,
.sidebar-light-green .navigation-hold .icon-menu .mat-raised-button,
.sidebar-light-green .navigation-hold .app-user .app-user-name,
.sidebar-light-green .navigation-hold .branding .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-light-green .navigation-hold a:hover {
  background: rgba(87, 125, 42, 0.2) !important;
}
.sidebar-light-green .navigation-hold .app-user .app-user-photo,
.sidebar-light-green .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-light-green .navigation-hold .icon-menu .mat-raised-button,
.sidebar-light-green .navigation-hold .branding {
  background: rgba(139, 195, 74, 0.96) !important;
}
.sidebar-light-green .navigation-hold li.open > a {
  background: rgba(87, 125, 42, 0.2) !important;
}
.sidebar-light-green .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-light-green .main-footer {
  background: #8bc34a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.light-green-50 {
  background-color: #f1f8e9 !important;
}

.light-green-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-50 {
  color: #f1f8e9 !important;
}

.light-green-100 {
  background-color: #dcedc8 !important;
}

.light-green-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-100 {
  color: #dcedc8 !important;
}

.light-green-200 {
  background-color: #c5e1a5 !important;
}

.light-green-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-200 {
  color: #c5e1a5 !important;
}

.light-green-300 {
  background-color: #aed581 !important;
}

.light-green-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-300 {
  color: #aed581 !important;
}

.light-green-400 {
  background-color: #9ccc65 !important;
}

.light-green-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-400 {
  color: #9ccc65 !important;
}

.light-green-500 {
  background-color: #8bc34a !important;
}

.light-green-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-500 {
  color: #8bc34a !important;
}

.light-green-600 {
  background-color: #7cb342 !important;
}

.light-green-600-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-600 {
  color: #7cb342 !important;
}

.light-green-700 {
  background-color: #689f38 !important;
}

.light-green-700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-700 {
  color: #689f38 !important;
}

.light-green-800 {
  background-color: #558b2f !important;
}

.light-green-800-fg {
  color: white !important;
}

.text-light-green-800 {
  color: #558b2f !important;
}

.light-green-900 {
  background-color: #33691e !important;
}

.light-green-900-fg {
  color: white !important;
}

.text-light-green-900 {
  color: #33691e !important;
}

.light-green-A100 {
  background-color: #ccff90 !important;
}

.light-green-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-A100 {
  color: #ccff90 !important;
}

.light-green-A200 {
  background-color: #b2ff59 !important;
}

.light-green-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-A200 {
  color: #b2ff59 !important;
}

.light-green-A400 {
  background-color: #76ff03 !important;
}

.light-green-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-A400 {
  color: #76ff03 !important;
}

.light-green-A700 {
  background-color: #64dd17 !important;
}

.light-green-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-light-green-A700 {
  color: #64dd17 !important;
}

.lime {
  background-color: #cddc39 !important;
}
.lime .mat-table {
  background: transparent !important;
}
.lime .ngx-datatable.material *,
.lime .mat-table,
.lime .mat-cell,
.lime .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.lime .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #cddc39, 5;
}

.text-lime {
  color: #cddc39;
}

.sidebar-lime .navigation-hold {
  background: #cddc39 !important;
}
.sidebar-lime .navigation-hold a,
.sidebar-lime .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-lime .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-lime .navigation-hold .sidenav li.open > a > span,
.sidebar-lime .navigation-hold .icon-menu .mat-raised-button,
.sidebar-lime .navigation-hold .app-user .app-user-name,
.sidebar-lime .navigation-hold .branding .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-lime .navigation-hold a:hover {
  background: rgba(137, 149, 26, 0.2) !important;
}
.sidebar-lime .navigation-hold .app-user .app-user-photo,
.sidebar-lime .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-lime .navigation-hold .icon-menu .mat-raised-button,
.sidebar-lime .navigation-hold .branding {
  background: rgba(205, 220, 57, 0.96) !important;
}
.sidebar-lime .navigation-hold li.open > a {
  background: rgba(137, 149, 26, 0.2) !important;
}
.sidebar-lime .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-lime .main-footer {
  background: #cddc39 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.lime-50 {
  background-color: #f9fbe7 !important;
}

.lime-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-50 {
  color: #f9fbe7 !important;
}

.lime-100 {
  background-color: #f0f4c3 !important;
}

.lime-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-100 {
  color: #f0f4c3 !important;
}

.lime-200 {
  background-color: #e6ee9c !important;
}

.lime-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-200 {
  color: #e6ee9c !important;
}

.lime-300 {
  background-color: #dce775 !important;
}

.lime-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-300 {
  color: #dce775 !important;
}

.lime-400 {
  background-color: #d4e157 !important;
}

.lime-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-400 {
  color: #d4e157 !important;
}

.lime-500 {
  background-color: #cddc39 !important;
}

.lime-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-500 {
  color: #cddc39 !important;
}

.lime-600 {
  background-color: #c0ca33 !important;
}

.lime-600-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-600 {
  color: #c0ca33 !important;
}

.lime-700 {
  background-color: #afb42b !important;
}

.lime-700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-700 {
  color: #afb42b !important;
}

.lime-800 {
  background-color: #9e9d24 !important;
}

.lime-800-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-800 {
  color: #9e9d24 !important;
}

.lime-900 {
  background-color: #827717 !important;
}

.lime-900-fg {
  color: white !important;
}

.text-lime-900 {
  color: #827717 !important;
}

.lime-A100 {
  background-color: #f4ff81 !important;
}

.lime-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-A100 {
  color: #f4ff81 !important;
}

.lime-A200 {
  background-color: #eeff41 !important;
}

.lime-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-A200 {
  color: #eeff41 !important;
}

.lime-A400 {
  background-color: #c6ff00 !important;
}

.lime-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-A400 {
  color: #c6ff00 !important;
}

.lime-A700 {
  background-color: #aeea00 !important;
}

.lime-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-lime-A700 {
  color: #aeea00 !important;
}

.yellow {
  background-color: #ffeb3b !important;
}
.yellow .mat-table {
  background: transparent !important;
}
.yellow .ngx-datatable.material *,
.yellow .mat-table,
.yellow .mat-cell,
.yellow .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.yellow .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #ffeb3b, 5;
}

.text-yellow {
  color: #ffeb3b;
}

.sidebar-yellow .navigation-hold {
  background: #ffeb3b !important;
}
.sidebar-yellow .navigation-hold a,
.sidebar-yellow .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-yellow .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-yellow .navigation-hold .sidenav li.open > a > span,
.sidebar-yellow .navigation-hold .icon-menu .mat-raised-button,
.sidebar-yellow .navigation-hold .app-user .app-user-name,
.sidebar-yellow .navigation-hold .branding .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-yellow .navigation-hold a:hover {
  background: rgba(212, 190, 0, 0.2) !important;
}
.sidebar-yellow .navigation-hold .app-user .app-user-photo,
.sidebar-yellow .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-yellow .navigation-hold .icon-menu .mat-raised-button,
.sidebar-yellow .navigation-hold .branding {
  background: rgba(255, 235, 59, 0.96) !important;
}
.sidebar-yellow .navigation-hold li.open > a {
  background: rgba(212, 190, 0, 0.2) !important;
}
.sidebar-yellow .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-yellow .main-footer {
  background: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.yellow-50 {
  background-color: #fffde7 !important;
}

.yellow-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-50 {
  color: #fffde7 !important;
}

.yellow-100 {
  background-color: #fff9c4 !important;
}

.yellow-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-100 {
  color: #fff9c4 !important;
}

.yellow-200 {
  background-color: #fff59d !important;
}

.yellow-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-200 {
  color: #fff59d !important;
}

.yellow-300 {
  background-color: #fff176 !important;
}

.yellow-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-300 {
  color: #fff176 !important;
}

.yellow-400 {
  background-color: #ffee58 !important;
}

.yellow-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-400 {
  color: #ffee58 !important;
}

.yellow-500 {
  background-color: #ffeb3b !important;
}

.yellow-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-500 {
  color: #ffeb3b !important;
}

.yellow-600 {
  background-color: #fdd835 !important;
}

.yellow-600-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-600 {
  color: #fdd835 !important;
}

.yellow-700 {
  background-color: #fbc02d !important;
}

.yellow-700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-700 {
  color: #fbc02d !important;
}

.yellow-800 {
  background-color: #f9a825 !important;
}

.yellow-800-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-800 {
  color: #f9a825 !important;
}

.yellow-900 {
  background-color: #f57f17 !important;
}

.yellow-900-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-900 {
  color: #f57f17 !important;
}

.yellow-A100 {
  background-color: #ffff8d !important;
}

.yellow-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-A100 {
  color: #ffff8d !important;
}

.yellow-A200 {
  background-color: #ffff00 !important;
}

.yellow-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-A200 {
  color: #ffff00 !important;
}

.yellow-A400 {
  background-color: #ffea00 !important;
}

.yellow-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-A400 {
  color: #ffea00 !important;
}

.yellow-A700 {
  background-color: #ffd600 !important;
}

.yellow-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-yellow-A700 {
  color: #ffd600 !important;
}

.amber {
  background-color: #ffc107 !important;
}
.amber .mat-table {
  background: transparent !important;
}
.amber .ngx-datatable.material *,
.amber .mat-table,
.amber .mat-cell,
.amber .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.amber .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #ffc107, 5;
}

.text-amber {
  color: #ffc107;
}

.sidebar-amber .navigation-hold {
  background: #ffc107 !important;
}
.sidebar-amber .navigation-hold a,
.sidebar-amber .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-amber .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-amber .navigation-hold .sidenav li.open > a > span,
.sidebar-amber .navigation-hold .icon-menu .mat-raised-button,
.sidebar-amber .navigation-hold .app-user .app-user-name,
.sidebar-amber .navigation-hold .branding .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-amber .navigation-hold a:hover {
  background: rgba(160, 120, 0, 0.2) !important;
}
.sidebar-amber .navigation-hold .app-user .app-user-photo,
.sidebar-amber .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-amber .navigation-hold .icon-menu .mat-raised-button,
.sidebar-amber .navigation-hold .branding {
  background: rgba(255, 193, 7, 0.96) !important;
}
.sidebar-amber .navigation-hold li.open > a {
  background: rgba(160, 120, 0, 0.2) !important;
}
.sidebar-amber .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-amber .main-footer {
  background: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.amber-50 {
  background-color: #fff8e1 !important;
}

.amber-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-50 {
  color: #fff8e1 !important;
}

.amber-100 {
  background-color: #ffecb3 !important;
}

.amber-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-100 {
  color: #ffecb3 !important;
}

.amber-200 {
  background-color: #ffe082 !important;
}

.amber-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-200 {
  color: #ffe082 !important;
}

.amber-300 {
  background-color: #ffd54f !important;
}

.amber-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-300 {
  color: #ffd54f !important;
}

.amber-400 {
  background-color: #ffca28 !important;
}

.amber-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-400 {
  color: #ffca28 !important;
}

.amber-500 {
  background-color: #ffc107 !important;
}

.amber-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-500 {
  color: #ffc107 !important;
}

.amber-600 {
  background-color: #ffb300 !important;
}

.amber-600-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-600 {
  color: #ffb300 !important;
}

.amber-700 {
  background-color: #ffa000 !important;
}

.amber-700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-700 {
  color: #ffa000 !important;
}

.amber-800 {
  background-color: #ff8f00 !important;
}

.amber-800-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-800 {
  color: #ff8f00 !important;
}

.amber-900 {
  background-color: #ff6f00 !important;
}

.amber-900-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-900 {
  color: #ff6f00 !important;
}

.amber-A100 {
  background-color: #ffe57f !important;
}

.amber-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-A100 {
  color: #ffe57f !important;
}

.amber-A200 {
  background-color: #ffd740 !important;
}

.amber-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-A200 {
  color: #ffd740 !important;
}

.amber-A400 {
  background-color: #ffc400 !important;
}

.amber-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-A400 {
  color: #ffc400 !important;
}

.amber-A700 {
  background-color: #ffab00 !important;
}

.amber-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-amber-A700 {
  color: #ffab00 !important;
}

.orange {
  background-color: #ff9800 !important;
}
.orange .mat-table {
  background: transparent !important;
}
.orange .ngx-datatable.material *,
.orange .mat-table,
.orange .mat-cell,
.orange .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.orange .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #ff9800, 5;
}

.text-orange {
  color: #ff9800;
}

.sidebar-orange .navigation-hold {
  background: #ff9800 !important;
}
.sidebar-orange .navigation-hold a,
.sidebar-orange .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-orange .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-orange .navigation-hold .sidenav li.open > a > span,
.sidebar-orange .navigation-hold .icon-menu .mat-raised-button,
.sidebar-orange .navigation-hold .app-user .app-user-name,
.sidebar-orange .navigation-hold .branding .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-orange .navigation-hold a:hover {
  background: rgba(153, 91, 0, 0.2) !important;
}
.sidebar-orange .navigation-hold .app-user .app-user-photo,
.sidebar-orange .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-orange .navigation-hold .icon-menu .mat-raised-button,
.sidebar-orange .navigation-hold .branding {
  background: rgba(255, 152, 0, 0.96) !important;
}
.sidebar-orange .navigation-hold li.open > a {
  background: rgba(153, 91, 0, 0.2) !important;
}
.sidebar-orange .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-orange .main-footer {
  background: #ff9800 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.orange-50 {
  background-color: #fff3e0 !important;
}

.orange-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-50 {
  color: #fff3e0 !important;
}

.orange-100 {
  background-color: #ffe0b2 !important;
}

.orange-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-100 {
  color: #ffe0b2 !important;
}

.orange-200 {
  background-color: #ffcc80 !important;
}

.orange-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-200 {
  color: #ffcc80 !important;
}

.orange-300 {
  background-color: #ffb74d !important;
}

.orange-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-300 {
  color: #ffb74d !important;
}

.orange-400 {
  background-color: #ffa726 !important;
}

.orange-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-400 {
  color: #ffa726 !important;
}

.orange-500 {
  background-color: #ff9800 !important;
}

.orange-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-500 {
  color: #ff9800 !important;
}

.orange-600 {
  background-color: #fb8c00 !important;
}

.orange-600-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-600 {
  color: #fb8c00 !important;
}

.orange-700 {
  background-color: #f57c00 !important;
}

.orange-700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-700 {
  color: #f57c00 !important;
}

.orange-800 {
  background-color: #ef6c00 !important;
}

.orange-800-fg {
  color: white !important;
}

.text-orange-800 {
  color: #ef6c00 !important;
}

.orange-900 {
  background-color: #e65100 !important;
}

.orange-900-fg {
  color: white !important;
}

.text-orange-900 {
  color: #e65100 !important;
}

.orange-A100 {
  background-color: #ffd180 !important;
}

.orange-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-A100 {
  color: #ffd180 !important;
}

.orange-A200 {
  background-color: #ffab40 !important;
}

.orange-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-A200 {
  color: #ffab40 !important;
}

.orange-A400 {
  background-color: #ff9100 !important;
}

.orange-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-orange-A400 {
  color: #ff9100 !important;
}

.orange-A700 {
  background-color: #ff6d00 !important;
}

.orange-A700-fg {
  color: black !important;
}

.text-orange-A700 {
  color: #ff6d00 !important;
}

.deep-orange {
  background-color: #ff5722 !important;
}
.deep-orange .mat-table {
  background: transparent !important;
}
.deep-orange .ngx-datatable.material *,
.deep-orange .mat-table,
.deep-orange .mat-cell,
.deep-orange .mat-header-cell {
  color: white !important;
}
.deep-orange .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.deep-orange .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #ff5722, 5;
}

.text-deep-orange {
  color: #ff5722;
}

.sidebar-deep-orange .navigation-hold {
  background: #ff5722 !important;
}
.sidebar-deep-orange .navigation-hold a,
.sidebar-deep-orange .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-deep-orange .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-deep-orange .navigation-hold .sidenav li.open > a > span,
.sidebar-deep-orange .navigation-hold .icon-menu .mat-raised-button,
.sidebar-deep-orange .navigation-hold .app-user .app-user-name,
.sidebar-deep-orange .navigation-hold .branding .app-logo-text {
  color: white !important;
}
.sidebar-deep-orange .navigation-hold a:hover {
  background: rgba(187, 45, 0, 0.2) !important;
}
.sidebar-deep-orange .navigation-hold .app-user .app-user-photo,
.sidebar-deep-orange .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: white !important;
}
.sidebar-deep-orange .navigation-hold .icon-menu .mat-raised-button,
.sidebar-deep-orange .navigation-hold .branding {
  background: rgba(255, 87, 34, 0.96) !important;
}
.sidebar-deep-orange .navigation-hold li.open > a {
  background: rgba(187, 45, 0, 0.2) !important;
}
.sidebar-deep-orange .navigation-hold .text-muted {
  color: white !important;
}

.footer-deep-orange .main-footer {
  background: #ff5722 !important;
  color: white !important;
}

.deep-orange-50 {
  background-color: #fbe9e7 !important;
}

.deep-orange-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-50 {
  color: #fbe9e7 !important;
}

.deep-orange-100 {
  background-color: #ffccbc !important;
}

.deep-orange-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-100 {
  color: #ffccbc !important;
}

.deep-orange-200 {
  background-color: #ffab91 !important;
}

.deep-orange-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-200 {
  color: #ffab91 !important;
}

.deep-orange-300 {
  background-color: #ff8a65 !important;
}

.deep-orange-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-300 {
  color: #ff8a65 !important;
}

.deep-orange-400 {
  background-color: #ff7043 !important;
}

.deep-orange-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-400 {
  color: #ff7043 !important;
}

.deep-orange-500 {
  background-color: #ff5722 !important;
}

.deep-orange-500-fg {
  color: white !important;
}

.text-deep-orange-500 {
  color: #ff5722 !important;
}

.deep-orange-600 {
  background-color: #f4511e !important;
}

.deep-orange-600-fg {
  color: white !important;
}

.text-deep-orange-600 {
  color: #f4511e !important;
}

.deep-orange-700 {
  background-color: #e64a19 !important;
}

.deep-orange-700-fg {
  color: white !important;
}

.text-deep-orange-700 {
  color: #e64a19 !important;
}

.deep-orange-800 {
  background-color: #d84315 !important;
}

.deep-orange-800-fg {
  color: white !important;
}

.text-deep-orange-800 {
  color: #d84315 !important;
}

.deep-orange-900 {
  background-color: #bf360c !important;
}

.deep-orange-900-fg {
  color: white !important;
}

.text-deep-orange-900 {
  color: #bf360c !important;
}

.deep-orange-A100 {
  background-color: #ff9e80 !important;
}

.deep-orange-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-A100 {
  color: #ff9e80 !important;
}

.deep-orange-A200 {
  background-color: #ff6e40 !important;
}

.deep-orange-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-deep-orange-A200 {
  color: #ff6e40 !important;
}

.deep-orange-A400 {
  background-color: #ff3d00 !important;
}

.deep-orange-A400-fg {
  color: white !important;
}

.text-deep-orange-A400 {
  color: #ff3d00 !important;
}

.deep-orange-A700 {
  background-color: #dd2c00 !important;
}

.deep-orange-A700-fg {
  color: white !important;
}

.text-deep-orange-A700 {
  color: #dd2c00 !important;
}

.brown {
  background-color: #795548 !important;
}
.brown .mat-table {
  background: transparent !important;
}
.brown .ngx-datatable.material *,
.brown .mat-table,
.brown .mat-cell,
.brown .mat-header-cell {
  color: rgba(255, 255, 255, 0.87) !important;
}
.brown .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.brown .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #795548, 5;
}

.text-brown {
  color: #795548;
}

.sidebar-brown .navigation-hold {
  background: #795548 !important;
}
.sidebar-brown .navigation-hold a,
.sidebar-brown .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-brown .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-brown .navigation-hold .sidenav li.open > a > span,
.sidebar-brown .navigation-hold .icon-menu .mat-raised-button,
.sidebar-brown .navigation-hold .app-user .app-user-name,
.sidebar-brown .navigation-hold .branding .app-logo-text {
  color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-brown .navigation-hold a:hover {
  background: rgba(57, 40, 34, 0.2) !important;
}
.sidebar-brown .navigation-hold .app-user .app-user-photo,
.sidebar-brown .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: rgba(255, 255, 255, 0.87) !important;
}
.sidebar-brown .navigation-hold .icon-menu .mat-raised-button,
.sidebar-brown .navigation-hold .branding {
  background: rgba(121, 85, 72, 0.96) !important;
}
.sidebar-brown .navigation-hold li.open > a {
  background: rgba(57, 40, 34, 0.2) !important;
}
.sidebar-brown .navigation-hold .text-muted {
  color: rgba(255, 255, 255, 0.87) !important;
}

.footer-brown .main-footer {
  background: #795548 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.brown-50 {
  background-color: #efebe9 !important;
}

.brown-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-brown-50 {
  color: #efebe9 !important;
}

.brown-100 {
  background-color: #d7ccc8 !important;
}

.brown-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-brown-100 {
  color: #d7ccc8 !important;
}

.brown-200 {
  background-color: #bcaaa4 !important;
}

.brown-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-brown-200 {
  color: #bcaaa4 !important;
}

.brown-300 {
  background-color: #a1887f !important;
}

.brown-300-fg {
  color: white !important;
}

.text-brown-300 {
  color: #a1887f !important;
}

.brown-400 {
  background-color: #8d6e63 !important;
}

.brown-400-fg {
  color: white !important;
}

.text-brown-400 {
  color: #8d6e63 !important;
}

.brown-500 {
  background-color: #795548 !important;
}

.brown-500-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-brown-500 {
  color: #795548 !important;
}

.brown-600 {
  background-color: #6d4c41 !important;
}

.brown-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-brown-600 {
  color: #6d4c41 !important;
}

.brown-700 {
  background-color: #5d4037 !important;
}

.brown-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-brown-700 {
  color: #5d4037 !important;
}

.brown-800 {
  background-color: #4e342e !important;
}

.brown-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-brown-800 {
  color: #4e342e !important;
}

.brown-900 {
  background-color: #3e2723 !important;
}

.brown-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-brown-900 {
  color: #3e2723 !important;
}

.brown-A100 {
  background-color: #d7ccc8 !important;
}

.brown-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-brown-A100 {
  color: #d7ccc8 !important;
}

.brown-A200 {
  background-color: #bcaaa4 !important;
}

.brown-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-brown-A200 {
  color: #bcaaa4 !important;
}

.brown-A400 {
  background-color: #8d6e63 !important;
}

.brown-A400-fg {
  color: white !important;
}

.text-brown-A400 {
  color: #8d6e63 !important;
}

.brown-A700 {
  background-color: #5d4037 !important;
}

.brown-A700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-brown-A700 {
  color: #5d4037 !important;
}

.grey {
  background-color: #9e9e9e !important;
}
.grey .mat-table {
  background: transparent !important;
}
.grey .ngx-datatable.material *,
.grey .mat-table,
.grey .mat-cell,
.grey .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.grey .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.grey .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #9e9e9e, 5;
}

.text-grey {
  color: #9e9e9e;
}

.sidebar-grey .navigation-hold {
  background: #9e9e9e !important;
}
.sidebar-grey .navigation-hold a,
.sidebar-grey .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-grey .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-grey .navigation-hold .sidenav li.open > a > span,
.sidebar-grey .navigation-hold .icon-menu .mat-raised-button,
.sidebar-grey .navigation-hold .app-user .app-user-name,
.sidebar-grey .navigation-hold .branding .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-grey .navigation-hold a:hover {
  background: rgba(107, 107, 107, 0.2) !important;
}
.sidebar-grey .navigation-hold .app-user .app-user-photo,
.sidebar-grey .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-grey .navigation-hold .icon-menu .mat-raised-button,
.sidebar-grey .navigation-hold .branding {
  background: rgba(158, 158, 158, 0.96) !important;
}
.sidebar-grey .navigation-hold li.open > a {
  background: rgba(107, 107, 107, 0.2) !important;
}
.sidebar-grey .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-grey .main-footer {
  background: #9e9e9e !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.grey-0 {
  background-color: #ffffff !important;
}

.grey-0-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-0 {
  color: #ffffff !important;
}

.grey-50 {
  background-color: #fafafa !important;
}

.grey-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-50 {
  color: #fafafa !important;
}

.grey-100 {
  background-color: #f5f5f5 !important;
}

.grey-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-100 {
  color: #f5f5f5 !important;
}

.grey-200 {
  background-color: #eeeeee !important;
}

.grey-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-200 {
  color: #eeeeee !important;
}

.grey-300 {
  background-color: #e0e0e0 !important;
}

.grey-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-300 {
  color: #e0e0e0 !important;
}

.grey-400 {
  background-color: #bdbdbd !important;
}

.grey-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-400 {
  color: #bdbdbd !important;
}

.grey-500 {
  background-color: #9e9e9e !important;
}

.grey-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-500 {
  color: #9e9e9e !important;
}

.grey-600 {
  background-color: #757575 !important;
}

.grey-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-grey-600 {
  color: #757575 !important;
}

.grey-700 {
  background-color: #616161 !important;
}

.grey-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-grey-700 {
  color: #616161 !important;
}

.grey-800 {
  background-color: #424242 !important;
}

.grey-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-grey-800 {
  color: #424242 !important;
}

.grey-900 {
  background-color: #212121 !important;
}

.grey-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-grey-900 {
  color: #212121 !important;
}

.grey-1000 {
  background-color: #000000 !important;
}

.grey-1000-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-grey-1000 {
  color: #000000 !important;
}

.grey-A100 {
  background-color: #ffffff !important;
}

.grey-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-A100 {
  color: #ffffff !important;
}

.grey-A200 {
  background-color: #eeeeee !important;
}

.grey-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-A200 {
  color: #eeeeee !important;
}

.grey-A400 {
  background-color: #bdbdbd !important;
}

.grey-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-grey-A400 {
  color: #bdbdbd !important;
}

.grey-A700 {
  background-color: #616161 !important;
}

.grey-A700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-grey-A700 {
  color: #616161 !important;
}

.black {
  background-color: #1e2129 !important;
}
.black .mat-table {
  background: transparent !important;
}
.black .ngx-datatable.material *,
.black .mat-table,
.black .mat-cell,
.black .mat-header-cell {
  color: #ffffff !important;
}
.black .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.black .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #1e2129, 5;
}

.text-black {
  color: #1e2129;
}

.sidebar-black .navigation-hold {
  background: #1e2129 !important;
}
.sidebar-black .navigation-hold a,
.sidebar-black .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-black .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-black .navigation-hold .sidenav li.open > a > span,
.sidebar-black .navigation-hold .icon-menu .mat-raised-button,
.sidebar-black .navigation-hold .app-user .app-user-name,
.sidebar-black .navigation-hold .branding .app-logo-text {
  color: #ffffff !important;
}
.sidebar-black .navigation-hold a:hover {
  background: rgba(0, 0, 0, 0.2) !important;
}
.sidebar-black .navigation-hold .app-user .app-user-photo,
.sidebar-black .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #ffffff !important;
}
.sidebar-black .navigation-hold .icon-menu .mat-raised-button,
.sidebar-black .navigation-hold .branding {
  background: rgba(30, 33, 41, 0.96) !important;
}
.sidebar-black .navigation-hold li.open > a {
  background: rgba(0, 0, 0, 0.2) !important;
}
.sidebar-black .navigation-hold .text-muted {
  color: white !important;
}

.footer-black .main-footer {
  background: #1e2129 !important;
  color: #ffffff !important;
}

.black-50 {
  background-color: #e4e4e5 !important;
}

.black-50-fg {
  color: #000000 !important;
}

.text-black-50 {
  color: #e4e4e5 !important;
}

.black-100 {
  background-color: #bcbcbf !important;
}

.black-100-fg {
  color: #000000 !important;
}

.text-black-100 {
  color: #bcbcbf !important;
}

.black-200 {
  background-color: #8f9094 !important;
}

.black-200-fg {
  color: #000000 !important;
}

.text-black-200 {
  color: #8f9094 !important;
}

.black-300 {
  background-color: #626469 !important;
}

.black-300-fg {
  color: #ffffff !important;
}

.text-black-300 {
  color: #626469 !important;
}

.black-400 {
  background-color: #404249 !important;
}

.black-400-fg {
  color: #ffffff !important;
}

.text-black-400 {
  color: #404249 !important;
}

.black-500 {
  background-color: #1e2129 !important;
}

.black-500-fg {
  color: #ffffff !important;
}

.text-black-500 {
  color: #1e2129 !important;
}

.black-600 {
  background-color: #1a1d24 !important;
}

.black-600-fg {
  color: #ffffff !important;
}

.text-black-600 {
  color: #1a1d24 !important;
}

.black-700 {
  background-color: #16181f !important;
}

.black-700-fg {
  color: #ffffff !important;
}

.text-black-700 {
  color: #16181f !important;
}

.black-800 {
  background-color: #121419 !important;
}

.black-800-fg {
  color: #ffffff !important;
}

.text-black-800 {
  color: #121419 !important;
}

.black-900 {
  background-color: #0a0b0f !important;
}

.black-900-fg {
  color: #ffffff !important;
}

.text-black-900 {
  color: #0a0b0f !important;
}

.black-A100 {
  background-color: #548dff !important;
}

.black-A100-fg {
  color: #000000 !important;
}

.text-black-A100 {
  color: #548dff !important;
}

.black-A200 {
  background-color: #216bff !important;
}

.black-A200-fg {
  color: #ffffff !important;
}

.text-black-A200 {
  color: #216bff !important;
}

.black-A400 {
  background-color: #004fed !important;
}

.black-A400-fg {
  color: #ffffff !important;
}

.text-black-A400 {
  color: #004fed !important;
}

.black-A700 {
  background-color: #0047d4 !important;
}

.black-A700-fg {
  color: #ffffff !important;
}

.text-black-A700 {
  color: #0047d4 !important;
}

.blue-grey {
  background-color: #607d8b !important;
}
.blue-grey .mat-table {
  background: transparent !important;
}
.blue-grey .ngx-datatable.material *,
.blue-grey .mat-table,
.blue-grey .mat-cell,
.blue-grey .mat-header-cell {
  color: white !important;
}
.blue-grey .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.blue-grey .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #607d8b, 5;
}

.text-blue-grey {
  color: #607d8b;
}

.sidebar-blue-grey .navigation-hold {
  background: #607d8b !important;
}
.sidebar-blue-grey .navigation-hold a,
.sidebar-blue-grey .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-blue-grey .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-blue-grey .navigation-hold .sidenav li.open > a > span,
.sidebar-blue-grey .navigation-hold .icon-menu .mat-raised-button,
.sidebar-blue-grey .navigation-hold .app-user .app-user-name,
.sidebar-blue-grey .navigation-hold .branding .app-logo-text {
  color: white !important;
}
.sidebar-blue-grey .navigation-hold a:hover {
  background: rgba(54, 71, 79, 0.2) !important;
}
.sidebar-blue-grey .navigation-hold .app-user .app-user-photo,
.sidebar-blue-grey .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: white !important;
}
.sidebar-blue-grey .navigation-hold .icon-menu .mat-raised-button,
.sidebar-blue-grey .navigation-hold .branding {
  background: rgba(96, 125, 139, 0.96) !important;
}
.sidebar-blue-grey .navigation-hold li.open > a {
  background: rgba(54, 71, 79, 0.2) !important;
}
.sidebar-blue-grey .navigation-hold .text-muted {
  color: white !important;
}

.footer-blue-grey .main-footer {
  background: #607d8b !important;
  color: white !important;
}

.blue-grey-50 {
  background-color: #eceff1 !important;
}

.blue-grey-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-grey-50 {
  color: #eceff1 !important;
}

.blue-grey-100 {
  background-color: #cfd8dc !important;
}

.blue-grey-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-grey-100 {
  color: #cfd8dc !important;
}

.blue-grey-200 {
  background-color: #b0bec5 !important;
}

.blue-grey-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-grey-200 {
  color: #b0bec5 !important;
}

.blue-grey-300 {
  background-color: #90a4ae !important;
}

.blue-grey-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-grey-300 {
  color: #90a4ae !important;
}

.blue-grey-400 {
  background-color: #78909c !important;
}

.blue-grey-400-fg {
  color: white !important;
}

.text-blue-grey-400 {
  color: #78909c !important;
}

.blue-grey-500 {
  background-color: #607d8b !important;
}

.blue-grey-500-fg {
  color: white !important;
}

.text-blue-grey-500 {
  color: #607d8b !important;
}

.blue-grey-600 {
  background-color: #546e7a !important;
}

.blue-grey-600-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-grey-600 {
  color: #546e7a !important;
}

.blue-grey-700 {
  background-color: #455a64 !important;
}

.blue-grey-700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-grey-700 {
  color: #455a64 !important;
}

.blue-grey-800 {
  background-color: #37474f !important;
}

.blue-grey-800-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-grey-800 {
  color: #37474f !important;
}

.blue-grey-900 {
  background-color: #263238 !important;
}

.blue-grey-900-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-grey-900 {
  color: #263238 !important;
}

.blue-grey-A100 {
  background-color: #cfd8dc !important;
}

.blue-grey-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-grey-A100 {
  color: #cfd8dc !important;
}

.blue-grey-A200 {
  background-color: #b0bec5 !important;
}

.blue-grey-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-blue-grey-A200 {
  color: #b0bec5 !important;
}

.blue-grey-A400 {
  background-color: #78909c !important;
}

.blue-grey-A400-fg {
  color: white !important;
}

.text-blue-grey-A400 {
  color: #78909c !important;
}

.blue-grey-A700 {
  background-color: #455a64 !important;
}

.blue-grey-A700-fg {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-blue-grey-A700 {
  color: #455a64 !important;
}

.white {
  background-color: #ffffff !important;
}
.white .mat-table {
  background: transparent !important;
}
.white .ngx-datatable.material *,
.white .mat-table,
.white .mat-cell,
.white .mat-header-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}
.white .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.white .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #ffffff, 5;
}

.text-white {
  color: #ffffff;
}

.sidebar-white .navigation-hold {
  background: #ffffff !important;
}
.sidebar-white .navigation-hold a,
.sidebar-white .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-white .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-white .navigation-hold .sidenav li.open > a > span,
.sidebar-white .navigation-hold .icon-menu .mat-raised-button,
.sidebar-white .navigation-hold .app-user .app-user-name,
.sidebar-white .navigation-hold .branding .app-logo-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-white .navigation-hold a:hover {
  background: rgba(204, 204, 204, 0.2) !important;
}
.sidebar-white .navigation-hold .app-user .app-user-photo,
.sidebar-white .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar-white .navigation-hold .icon-menu .mat-raised-button,
.sidebar-white .navigation-hold .branding {
  background: rgba(255, 255, 255, 0.96) !important;
}
.sidebar-white .navigation-hold li.open > a {
  background: rgba(204, 204, 204, 0.2) !important;
}
.sidebar-white .navigation-hold .text-muted {
  color: rgba(0, 0, 0, 0.87) !important;
}

.footer-white .main-footer {
  background: #ffffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.white-50 {
  background-color: #ffffff !important;
}

.white-50-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-50 {
  color: #ffffff !important;
}

.white-100 {
  background-color: #ffffff !important;
}

.white-100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-100 {
  color: #ffffff !important;
}

.white-200 {
  background-color: #ffffff !important;
}

.white-200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-200 {
  color: #ffffff !important;
}

.white-300 {
  background-color: #ffffff !important;
}

.white-300-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-300 {
  color: #ffffff !important;
}

.white-400 {
  background-color: #ffffff !important;
}

.white-400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-400 {
  color: #ffffff !important;
}

.white-500 {
  background-color: #ffffff !important;
}

.white-500-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-500 {
  color: #ffffff !important;
}

.white-600 {
  background-color: #ffffff !important;
}

.white-600-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-600 {
  color: #ffffff !important;
}

.white-700 {
  background-color: #ffffff !important;
}

.white-700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-700 {
  color: #ffffff !important;
}

.white-800 {
  background-color: #ffffff !important;
}

.white-800-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-800 {
  color: #ffffff !important;
}

.white-900 {
  background-color: #ffffff !important;
}

.white-900-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-900 {
  color: #ffffff !important;
}

.white-A100 {
  background-color: #ffffff !important;
}

.white-A100-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-A100 {
  color: #ffffff !important;
}

.white-A200 {
  background-color: #ffffff !important;
}

.white-A200-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-A200 {
  color: #ffffff !important;
}

.white-A400 {
  background-color: #ffffff !important;
}

.white-A400-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-A400 {
  color: #ffffff !important;
}

.white-A700 {
  background-color: #ffffff !important;
}

.white-A700-fg {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-white-A700 {
  color: #ffffff !important;
}

.slate {
  background-color: #222a45 !important;
}
.slate .mat-table {
  background: transparent !important;
}
.slate .ngx-datatable.material *,
.slate .mat-table,
.slate .mat-cell,
.slate .mat-header-cell {
  color: #ffffff !important;
}
.slate .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.slate .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: #222a45, 5;
}

.text-slate {
  color: #222a45;
}

.sidebar-slate .navigation-hold {
  background: #222a45 !important;
}
.sidebar-slate .navigation-hold a,
.sidebar-slate .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.sidebar-slate .navigation-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.sidebar-slate .navigation-hold .sidenav li.open > a > span,
.sidebar-slate .navigation-hold .icon-menu .mat-raised-button,
.sidebar-slate .navigation-hold .app-user .app-user-name,
.sidebar-slate .navigation-hold .branding .app-logo-text {
  color: #ffffff !important;
}
.sidebar-slate .navigation-hold a:hover {
  background: rgba(0, 0, 1, 0.2) !important;
}
.sidebar-slate .navigation-hold .app-user .app-user-photo,
.sidebar-slate .navigation-hold .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
  border-color: #ffffff !important;
}
.sidebar-slate .navigation-hold .icon-menu .mat-raised-button,
.sidebar-slate .navigation-hold .branding {
  background: rgba(34, 42, 69, 0.96) !important;
}
.sidebar-slate .navigation-hold li.open > a {
  background: rgba(0, 0, 1, 0.2) !important;
}
.sidebar-slate .navigation-hold .text-muted {
  color: white !important;
}

.footer-slate .main-footer {
  background: #222a45 !important;
  color: #ffffff !important;
}

.slate-50 {
  background-color: #222a45 !important;
}

.slate-50-fg {
  color: #ffffff !important;
}

.text-slate-50 {
  color: #222a45 !important;
}

.slate-100 {
  background-color: #222a45 !important;
}

.slate-100-fg {
  color: #ffffff !important;
}

.text-slate-100 {
  color: #222a45 !important;
}

.slate-200 {
  background-color: #222a45 !important;
}

.slate-200-fg {
  color: #ffffff !important;
}

.text-slate-200 {
  color: #222a45 !important;
}

.slate-300 {
  background-color: #222a45 !important;
}

.slate-300-fg {
  color: #ffffff !important;
}

.text-slate-300 {
  color: #222a45 !important;
}

.slate-400 {
  background-color: #222a45 !important;
}

.slate-400-fg {
  color: #ffffff !important;
}

.text-slate-400 {
  color: #222a45 !important;
}

.slate-500 {
  background-color: #222a45 !important;
}

.slate-500-fg {
  color: #ffffff !important;
}

.text-slate-500 {
  color: #222a45 !important;
}

.slate-600 {
  background-color: #222a45 !important;
}

.slate-600-fg {
  color: #ffffff !important;
}

.text-slate-600 {
  color: #222a45 !important;
}

.slate-700 {
  background-color: #222a45 !important;
}

.slate-700-fg {
  color: #ffffff !important;
}

.text-slate-700 {
  color: #222a45 !important;
}

.slate-800 {
  background-color: #222a45 !important;
}

.slate-800-fg {
  color: #ffffff !important;
}

.text-slate-800 {
  color: #222a45 !important;
}

.slate-900 {
  background-color: #222a45 !important;
}

.slate-900-fg {
  color: #ffffff !important;
}

.text-slate-900 {
  color: #222a45 !important;
}

.slate-A100 {
  background-color: #222a45 !important;
}

.slate-A100-fg {
  color: #ffffff !important;
}

.text-slate-A100 {
  color: #222a45 !important;
}

.slate-A200 {
  background-color: #222a45 !important;
}

.slate-A200-fg {
  color: #ffffff !important;
}

.text-slate-A200 {
  color: #222a45 !important;
}

.slate-A400 {
  background-color: #222a45 !important;
}

.slate-A400-fg {
  color: #ffffff !important;
}

.text-slate-A400 {
  color: #222a45 !important;
}

.slate-A700 {
  background-color: #222a45 !important;
}

.slate-A700-fg {
  color: #ffffff !important;
}

.text-slate-A700 {
  color: #222a45 !important;
}

.page-layout {
  position: relative;
  padding: 0 1.5rem;
  margin: -0.333rem -0.333rem 0;
}
.page-layout .header-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  z-index: -1;
}
.page-layout .content {
  display: flex;
  flex-direction: column;
}
.page-layout .content-header {
  height: 136px;
}
.page-layout .content-card {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.page-layout .content-card > .mat-tab-group .mat-tab-header .mat-tab-label, .page-layout .content-card > .card-header {
  height: 64px;
}
.page-layout .content-card .card-header {
  display: flex;
  align-items: center;
}
.page-layout .sidebar-header {
  height: 200px;
}
.page-layout.plain {
  padding-left: 0;
  padding-right: 0;
}
.page-layout.plain .sidebar-header {
  height: auto;
}
.page-layout.plain .content-card {
  box-shadow: none;
  border-radius: 0;
}

.seciton-left {
  color: white;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  min-width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../../../images/backgrounds/card-coffee-cup.jpg");
  background-size: cover;
}
.seciton-left .section-left-content {
  max-width: 380px;
}
.seciton-left::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.65);
  z-index: -1;
}

.form-holder {
  padding: 16px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: 40%;
}
@media only screen and (max-width: 600px) {
  .form-holder {
    width: 100%;
  }
}

.signup-form {
  padding: 20px 20px 70px 20px;
}

.signup3-wrap {
  width: 100%;
  height: 100vh;
  background: url("../../../../images/backgrounds/trianglify-1.png");
  z-index: -1;
}

.signup3-form-holder {
  position: absolute;
  width: 380px;
  height: 100vh;
  left: 0;
  right: 0;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .signup3-form-holder {
    width: 100%;
  }
}

.signup3-form {
  padding: 20px 20px 70px 20px;
}

.signup3-header {
  text-align: center;
  padding-bottom: 30px;
}

.signup4-wrap {
  background: url("../../../../images/backgrounds/waterfall.jpg");
}

.signup4-container {
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.signup4-header {
  position: relative;
  padding: 35px;
}
.signup4-header > div {
  z-index: 2;
}

.signup4-header::before {
  content: "";
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

.signup4-small-image {
  height: 24px;
}

.signup4-form {
  padding: 35px;
}

.signup4-button {
  text-align: center;
}
.signup4-button .mat-raised-button {
  border-radius: 18px;
}

/*---- Session (Sign in, sign up, forgot, lockscreen) -----*/
.page-wrap {
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
}

.session-progress {
  position: relative;
  bottom: -4px;
  z-index: 9999;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.session-form-hold > .mat-card {
  margin: 0;
}

.session-lockscreen {
  max-width: 320px;
}

.lockscreen-user {
  text-align: center;
  margin-bottom: 1rem;
  display: flow-root;
  overflow: hidden;
}

.lockscreen-face {
  max-width: 72px;
  max-height: 72px;
  border-radius: 50%;
  margin-bottom: 0.5rem;
}

/*---- Users -----*/
.user-card .card-title-text {
  padding: 0.5rem 1.5rem !important;
  align-items: center;
}

.user-card .user-details > p:last-child {
  margin-bottom: 0;
}

.user-card .user-details .mat-icon {
  vertical-align: top;
  margin-right: 0.5rem;
}

/*------- Profile ------*/
.profile-sidebar {
  box-sizing: border-box;
}

.profile-sidebar .propic {
  width: 100%;
  margin-bottom: 6px;
}

.profile-sidebar .propic img {
  width: 50%;
  height: auto;
  border-radius: 50%;
}

.profile-sidebar .profile-title .main-title {
  font-size: 1.5rem;
}

.profile-sidebar .profile-nav {
  margin-left: -24px;
  margin-right: -24px;
}

.profile-sidebar .profile-nav .mat-list-item .mat-icon {
  margin-right: 8px;
}

.timeline {
  position: relative;
  overflow: hidden;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 24px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.12);
}

.timeline .timeline-item {
  width: 100%;
  overflow: hidden;
  margin-bottom: 24px;
}

.timeline .timeline-badge {
  float: left;
  position: relative;
  margin-right: 30px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.timeline .timeline-badge img {
  max-width: 100%;
}

.timeline .timeline-badge .icon-badge {
  text-align: center;
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.timeline .timeline-body-top {
  margin-bottom: 8px;
}

.timeline .timeline-body {
  float: right;
  width: calc(100% - 78px);
}

.timeline .timeline-body-content img {
  border-radius: 4px;
  max-width: 100%;
}

.timeline .timeline-body-content > :last-child {
  margin-bottom: 8px;
}

[dir=rtl] .profile-sidebar .profile-nav .mat-list-item .mat-icon {
  margin-right: 0;
  margin-left: 8px;
}

.home-section {
  padding: 40px 0 40px;
}

.scrollable {
  position: relative;
  width: 100%;
  height: 100vh;
}

.section-intro {
  padding: 100px 0 30px;
  background: #222a45;
  color: #ffffff;
}
.section-intro .egret {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.48);
}
.section-intro h1 {
  font-size: 32px;
  margin: 2rem 0 1rem;
  font-weight: 500;
}
.section-intro p {
  font-size: 16px;
  max-width: 450px;
  margin: 0 auto 1.4rem;
}
.section-intro img {
  display: block;
  margin: 0 auto;
}

.section-demos {
  position: relative;
  background: #ffffff;
}
.section-demos .demo-box-wrap {
  padding: 1.5rem;
}
.section-demos .demo-box {
  position: relative;
  border-radius: 8px;
  transition: 0.2s all ease-in-out;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.section-demos .demo-box:hover {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
}
.section-demos .demo-box:hover .caption {
  opacity: 1;
  transform: translateY(-10px);
}
.section-demos .demo-box .caption {
  opacity: 0;
  background: #ffffff;
  padding: 1rem;
  position: absolute;
  z-index: 99;
  top: -20px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 8px;
  width: 220px;
  transition: 0.2s all ease-in-out;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.section-demos .demo-box h3 {
  margin: 0 0 1rem;
  font-size: 22px;
  font-weight: 300;
}
.section-demos .demo-box img {
  width: 100%;
  height: auto;
  float: left;
}
.section-demos .demo-box .screenshot {
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}

/* 
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/